import React, { useEffect, useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import TradeSocketLow from './TradeSocketLow';
import TradeSocketHigh from './TradeSocketHigh';
import { useSelector } from 'react-redux';
import configuration from "react-global-configuration";

const TradeSocket = () => {
    const [buyTrade, setBuyTrade] = useState([]);
    const [sellTrade, setSellTrade] = useState([]);
    const currencyPair = useSelector((state) => state.buy.currencyPair);

    useEffect(() => {
        const cryptoExchangeUrl = process.env.REACT_APP_SOCKET_URL  != ""
            ? process.env.REACT_APP_SOCKET_URL 
            : "wss://cms-proexchange.rare-able.com:3091";

        const socket = new WebSocket(cryptoExchangeUrl);
        socket.onopen = () => {
            const subscribeMessage = JSON.stringify({
                type: "order-book",
                pair: currencyPair?.unique_id,
            });

            socket.send(subscribeMessage);
        };

        socket.onmessage = (event) => {
            try {
                const parsedData = JSON.parse(event.data);
                //in parsed data first one is price ,second one is size
                // setCryptoCurrencyList(
                //   parsedData.map(([asset, value]) => ({
                //     asset,
                //     value,
                //   }))
                // );
                setBuyTrade(
                    parsedData.buy.map(([price, value]) => ({
                        price: price,
                        value: value,
                    }))
                );

                setSellTrade(
                    parsedData.sell.map(([price, value]) => ({
                        price: price,
                        value: value,
                    }))
                );
            } catch (error) { }
        };
        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [currencyPair]);

    return (
        <>
            <div className="trade-socket-wrapped">
                <div className="trade-socket-tabs">
                    <Tab.Container id="socket-trade" defaultActiveKey="bouble-stack">
                        <Nav variant="pills">
                            <Nav.Item>
                                <Nav.Link eventKey="bouble-stack">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        fill="none"
                                        viewBox="0 0 22 19"
                                    >
                                        <path fill="#F23645" d="M0 .5h7v8H0z"></path>
                                        <path fill="#19CC5B" d="M0 9.5h7v9H0z"></path>
                                        <path fill="#fff" d="M8 .5h14v18H8z"></path>
                                    </svg>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="hight-stack">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        fill="none"
                                        viewBox="0 0 22 19"
                                    >
                                        <path fill="#19CC5B" d="M0 .5h7v18H0z"></path>
                                        <path fill="#fff" d="M8 .5h14v18H8z"></path>
                                    </svg>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="low-stack">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        fill="none"
                                        viewBox="0 0 22 19"
                                    >
                                        <path fill="#F23645" d="M0 .5h7v18H0z"></path>
                                        <path fill="#fff" d="M8 .5h14v18H8z"></path>
                                    </svg>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="bouble-stack">
                                <div className="trade-stack-body">
                                    <div className="trade-stack-head">
                                        <div className="stack-price-label">Price({currencyPair?.currency2})</div>
                                        <div className="stack-amt-label">Amount({currencyPair?.currency1})</div>
                                    </div>
                                    <div className="trade-stack-frame">
                                        <div className="trade-stack-low-frame">
                                           
                                                <TradeSocketLow
                                                    buyTrade={buyTrade}
                                                    sellTrade={sellTrade}
                                                    currencyPair={currencyPair}
                                                />
                                        
                                        </div>
                                        <div className="trade-batch"></div>
                                        <div className="trade-stack-hight-frame">

                                           
                                                <TradeSocketHigh
                                                    buyTrade={buyTrade}
                                                    currencyPair={currencyPair}
                                                />
                                            
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="hight-stack">
                                <div className="trade-stack-body">
                                    <div className="trade-stack-head">
                                        <div className="stack-price-label">Price({currencyPair?.currency2})</div>
                                        <div className="stack-amt-label">Amount({currencyPair?.currency1})</div>
                                    </div>
                                    <div className="trade-stack-frame overflow-auto">
                                        
                                            <TradeSocketHigh
                                                sellTrade={sellTrade}
                                                currencyPair={currencyPair}
                                            />
                                    
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="low-stack">
                                <div className="trade-stack-body">
                                    <div className="trade-stack-head">
                                        <div className="stack-price-label">Price({currencyPair?.currency2})</div>
                                        <div className="stack-amt-label">Amount({currencyPair?.currency1})</div>
                                    </div>
                                    <div className="trade-stack-frame overflow-auto">
                                        
                                            <TradeSocketLow
                                                sellTrade={sellTrade}
                                                currencyPair={currencyPair}
                                            />
                                        

                                    </div>
                                </div>

                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        </>
    )
}

export default TradeSocket;