import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import NewTradePair from '../TradeBody/TradePair';
import NewTradeSocket from '../TradeBody/TradeSocket';

const MobileStockModal = (props) => {
  return (
    <>
    <Offcanvas  {...props} className="system-pair-modal">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Currency  Price</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <NewTradeSocket />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default MobileStockModal;