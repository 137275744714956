import React from 'react';
import { Container, Image, Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-multi-lang';
import { Link } from "react-router-dom";
import CustomLazyLoad from '../helper/CustomLazyLoad';

const ExchangePlatform = () => {
    const t = useTranslation("demoof_product");

    return (
        <div className="exchange-platform-frame sm-padding">
            <Container fluid>
                {/* <Row>
          <Col md={6} lg={6} xl={5}>
              <div className="best-crypto-step-title ">
              <h2><span></span></h2>
              </div>
            </Col>
          </Row> */}
                <Row className="justify-content-md-center">
                    <Col md={8} lg={9} className="text-center">
                        <div className="section-title">
                            <h2>{t("why_choose_heraldEX")}?</h2>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={5}>
                        <div className="exchange-platfrom-info-card grdaient-blur-one h-100" >
                            <div className="exchange-platfrom-primary-card">
                                <div className="exchange-platfrom-info">
                                    <h3>{t("currencies_supported")}</h3>
                                    <p>{t("content")}</p>
                                </div>
                                <div className="exchange-platfrom-info-batch-frame mt-3">
                                    <div className="info-batch-card">
                                        <div className="info-batch-card-icons">
                                            <Image
                                                className="batch-card-icons"
                                                src={window.location.origin + "/img/phase_1/icons/platfrom/usd.svg"}
                                                alt="usd"
                                            />
                                        </div>
                                        <div className="info-batch-card-info">
                                            {t("usd")}
                                        </div>
                                    </div>
                                    <div className="info-batch-card">
                                        <div className="info-batch-card-icons">
                                            <Image
                                                className="batch-card-icons"
                                                src={window.location.origin + "/img/phase_1/icons/platfrom/jpy.svg"}
                                                alt="usd"
                                            />
                                        </div>
                                        <div className="info-batch-card-info">
                                            {t("jpy")}
                                        </div>
                                    </div>
                                    <div className="info-batch-card">
                                        <div className="info-batch-card-icons">
                                            <Image
                                                className="batch-card-icons"
                                                src={window.location.origin + "/img/phase_1/icons/platfrom/hkd.svg"}
                                                alt="usd"
                                            />
                                        </div>
                                        <div className="info-batch-card-info">
                                            {t("hkd")}
                                        </div>
                                    </div>
                                    <div className="info-batch-card">
                                        <div className="info-batch-card-icons">
                                            <Image
                                                className="batch-card-icons"
                                                src={window.location.origin + "/img/phase_1/icons/platfrom/myr.svg"}
                                                alt="usd"
                                            />
                                        </div>
                                        <div className="info-batch-card-info">
                                            {t("myr")}
                                        </div>
                                    </div>
                                    <div className="info-batch-card">
                                        <div className="info-batch-card-icons">
                                            <Image
                                                className="batch-card-icons"
                                                src={window.location.origin + "/img/phase_1/icons/platfrom/inr.svg"}
                                                alt="usd"
                                            />
                                        </div>
                                        <div className="info-batch-card-info">
                                            {t("inr")}
                                        </div>
                                    </div>
                                    <div className="info-batch-card">
                                        <div className="info-batch-card-icons">
                                            <Image
                                                className="batch-card-icons"
                                                src={window.location.origin + "/img/phase_1/icons/platfrom/idr.svg"}
                                                alt="usd"
                                            />
                                        </div>
                                        <div className="info-batch-card-info">
                                            {t("idr")}
                                        </div>
                                    </div>
                                    <div className="info-batch-card">
                                        <div className="info-batch-card-icons">
                                            <Image
                                                className="batch-card-icons"
                                                src={window.location.origin + "/img/phase_1/icons/platfrom/gbp.svg"}
                                                alt="usd"
                                            />
                                        </div>
                                        <div className="info-batch-card-info">
                                            {t("gbp")}
                                        </div>
                                    </div>
                                    <div className="info-batch-card">
                                        <div className="info-batch-card-icons">
                                            <Image
                                                className="batch-card-icons"
                                                src={window.location.origin + "/img/phase_1/icons/platfrom/aud.svg"}
                                                alt="usd"
                                            />
                                        </div>
                                        <div className="info-batch-card-info">
                                            {t("aud")}
                                        </div>
                                    </div>
                                    <div className="info-batch-card">
                                        <div className="info-batch-card-icons">
                                            <Image
                                                className="batch-card-icons"
                                                src={window.location.origin + "/img/phase_1/icons/platfrom/eur.png"}
                                                alt="usd"
                                            />
                                        </div>
                                        <div className="info-batch-card-info">
                                            {t("eur")}
                                        </div>
                                    </div>
                                    <div className="info-batch-card">
                                        <div className="info-batch-card-icons">
                                            <Image
                                                className="batch-card-icons"
                                                src={window.location.origin + "/img/phase_1/icons/platfrom/cny.png"}
                                                alt="usd"
                                            />
                                        </div>
                                        <div className="info-batch-card-info">
                                            {t("cny")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={7}>
                        {/* <div className="exchange-platfrom-info-card grdaient-blur-two" >
                            <div className="exchange-platfrom-info-frame">
                                <div className="exchange-platfrom-info-frame-info">
                                    <h3>Transparent Pricing</h3>
                                    <p>Get clear and upfront pricing on every transaction—no hidden fees. Trade confidently, knowing exactly what you’ll pay.</p>
                                </div>
                                <div className="exchange-platfrom-info-frame-avater">
                                    <Image
                                        className="platfrom-card-icons"
                                        src={window.location.origin + "/img/phase_1/icons/platfrom/avater/platfrom-avater.svg"}
                                        alt="usd"
                                    />
                                </div>
                            </div>
                        </div> */}
                        <div className="item-grid-layout_2 " >
                            <div className="exchange-platfrom-info-card grdaient-blur-color">
                                <div className="echange-platfrom-vertical-frame">
                                    <div className="echange-platfrom-vertical-avater">
                                        <CustomLazyLoad
                                            className="vertical-card-icons"
                                            src={window.location.origin + "/img/phase_1/icons/platfrom/avater/back-development.svg"}
                                            alt="usd"
                                        />
                                    </div>
                                    <div className="echange-platfrom-vertical-info">
                                        <h3>{t('cutting_edge_technology')}</h3>
                                        <p>{t("pioneering_seamless_crypto")}</p>
                                    </div>

                                </div>
                            </div>
                            <div className="exchange-platfrom-info-card grdaient-blur-color">
                                <div className="echange-platfrom-vertical-frame">
                                    <div className="echange-platfrom-vertical-avater">
                                        <CustomLazyLoad
                                            className="vertical-card-icons-two"
                                            src={window.location.origin + "/img/phase_1/icons/platfrom/avater/bit-coins-book.svg"}
                                            alt="usd"
                                        />
                                    </div>
                                    <div className="echange-platfrom-vertical-info">
                                        <h3>{t("customer_support")}</h3>
                                        <p>{t("content_2")}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="item-grid-layout_2 mt-3" >
                            <div className="exchange-platfrom-info-card grdaient-blur-color">
                                <div className="echange-platfrom-vertical-frame">
                                    <div className="echange-platfrom-vertical-avater">
                                        <CustomLazyLoad
                                            className="vertical-card-icons"
                                            src={window.location.origin + "/img/phase_1/icons/platfrom/avater/mobile.svg"}
                                            alt="usd"
                                        />
                                    </div>
                                    <div className="echange-platfrom-vertical-info">
                                        <h3>{t("mobile_accessibility")}</h3>
                                        <p>{t("content_3")}</p>
                                    </div>

                                </div>
                            </div>
                            <div className="exchange-platfrom-info-card grdaient-blur-color">
                                <div className="echange-platfrom-vertical-frame">
                                    <div className="echange-platfrom-vertical-avater">
                                        <CustomLazyLoad
                                            className="vertical-card-icons-three"
                                            src={window.location.origin + "/img/phase_1/icons/platfrom/avater/platfrom-avater.svg"}
                                            alt="usd"
                                        />
                                    </div>
                                    <div className="echange-platfrom-vertical-info">
                                        <h3>{t("transparent_pricing")}</h3>
                                        <p>{t("content_4")}</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ExchangePlatform;
