import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const TradeNav = (props) => {
    const currencyPair = useSelector((state) => state.buy.currencyPair);
  return (
    <>
      <div className="trade-nav-wrapped">
        <div className="trade-nav-list">
          {/* {[...Array(15)].map(()=>(
            <p>trade List card</p> 
            ))} */}
          <iframe
            allowtransparency="true"
            src="https://www.tradingview-widget.com/embed-widget/ticker-tape/#%7B%22colorTheme%22%3A%22dark%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A46%2C%22utm_source%22%3A%22crypo.netlify.app%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22ticker-tape%22%2C%22page-uri%22%3A%22crypo.netlify.app%2Fexchange-dark-live-price%22%7D"
            title="ticker tape TradingView widget"
            lang="en"
            aria-disabled
            style={{
              userSelect: 'none',
              boxSizing: 'border-box',
              display: 'block',
              height: '46px',
              width: '100%',
              pointerEvents: 'none',
            }} ></iframe>
        </div>
        <div className="trade-nav-buy-sell-action">
          <Button className="trade-btn buy" disabled={!currencyPair} onClick={() => props.setModal("buy")}>Buy</Button>
          <Button className="trade-btn sell" disabled={!currencyPair} onClick={() => props.setModal("sell")}>Sell</Button>
        </div>
      </div >
    </>
  )
}

export default TradeNav;