import { React, useState, useEffect } from 'react';
import { Button, Offcanvas, Tab, Nav } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import { useSelector, useDispatch } from "react-redux";
import { getSuccessNotificationMessage } from '../../helper/ToastNotification';
import NoDataFound from '../../helper/NoDataFound';
import { swapTokensViewStart } from '../../../store/actions/BuyAction';

const SwapTransactionView = (props) => {
     const { transactionId } = props;
     const dispatch = useDispatch();
     const [step, setStep] = useState(1);
     const t = useTranslation("transactions");
     const swapTokensView = useSelector((state) => state.buy.swapTokensView);
   
     useEffect(() => {
       dispatch(
         swapTokensViewStart({
           swap_transaction_id: transactionId,
         })
       );
     }, []);

    return (
        <>
            <Offcanvas {...props} className="view-canvas-modal"  >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Swap Transaction Details</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {swapTokensView && swapTokensView.loading ? (<div className="view-modal-content">
                        <div className="view-transaction-amt-card">
                            <Skeleton height={20} />
                            <Skeleton height={20} />
                        </div>
                        <div className="view-status-card">
                            <Skeleton height={15} width={40} />
                            <Skeleton height={20} width={100} />
                        </div>
                        <div className="view-date-card">
                            <Skeleton height={15} width={30} />
                            <Skeleton height={20} width={200} />
                        </div>

                        <Skeleton height={350} />

                    </div>) : Object.keys(swapTokensView.data).length > 0 ? (
                        <div className="view-modal-content">
                            <div className="view-transaction-amt-card">
                                <div className="view-label">Tokens Transferred</div>
                                <div className="view-value">- {swapTokensView.data.swap_transaction.to_amount_formatted}</div>
                            </div>
                            <div className="view-status-card">
                                <div className="view-status-label">Status</div>
                                <div className="view-status-badges">
                                {swapTokensView.data.swap_transaction.status == 1 ?
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlSpace="preserve"
                                            width="32"
                                            height="32"
                                            viewBox="0 0 24 24"
                                        >
                                            <switch>
                                                <g>
                                                    <path
                                                        fill="#00b06f"
                                                        d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2m4.3 7.6-4.6 6c-.2.2-.5.4-.8.4s-.6-.1-.8-.4l-2.4-3.1c-.3-.4-.3-1.1.2-1.4s1.1-.3 1.4.2l1.6 2.1 3.8-5c.3-.4 1-.5 1.4-.2.5.3.5.9.2 1.4"
                                                        data-original="#02bc7d"
                                                    ></path>
                                                </g>
                                            </switch>
                                        </svg> : swapTokensView.data.swap_transaction.status == 0 ? <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="27" height="27" rx="13.5" fill="#FFC879" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.955 8.80498C8.31733 9.43342 7.82997 10.1979 7.52936 11.0412C7.22874 11.8845 7.12264 12.7849 7.219 13.675C7.24685 13.9389 7.16873 14.203 7.00182 14.4093C6.83491 14.6156 6.59289 14.7471 6.329 14.775C6.06511 14.8028 5.80097 14.7247 5.59468 14.5578C5.38839 14.3909 5.25685 14.1489 5.229 13.885C5.10128 12.696 5.24377 11.4935 5.64584 10.3673C6.04791 9.2411 6.69917 8.22025 7.551 7.38098C10.687 4.28898 15.741 4.34098 18.84 7.48298C21.939 10.625 21.921 15.681 18.786 18.773C17.3348 20.2073 15.3861 21.0262 13.346 21.059C12.575 21.0728 11.806 20.9752 11.063 20.769C10.9335 20.7367 10.8118 20.6788 10.705 20.5987C10.5983 20.5186 10.5086 20.418 10.4413 20.3027C10.3741 20.1875 10.3305 20.0599 10.3133 19.9275C10.2962 19.7952 10.3056 19.6608 10.3412 19.5321C10.3768 19.4035 10.4377 19.2833 10.5205 19.1786C10.6033 19.0739 10.7062 18.9868 10.8231 18.9225C10.94 18.8582 11.0687 18.8179 11.2014 18.8041C11.3341 18.7903 11.4683 18.8031 11.596 18.842C12.1539 18.9966 12.7312 19.0697 13.31 19.059C14.8367 19.0353 16.2953 18.4227 17.381 17.349C19.724 15.039 19.746 11.25 17.416 8.88798C15.086 6.52598 11.297 6.49498 8.955 8.80498Z" fill="#AC6500" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.967 14.695C6.87968 14.7931 6.77389 14.8731 6.65567 14.9303C6.53744 14.9875 6.40911 15.0209 6.27799 15.0285C6.14687 15.0361 6.01553 15.0178 5.89148 14.9747C5.76743 14.9315 5.65309 14.8643 5.555 14.777L3.835 13.247C3.63688 13.0706 3.51694 12.8228 3.50157 12.558C3.48619 12.2932 3.57663 12.0331 3.753 11.835C3.92937 11.6369 4.17722 11.5169 4.44202 11.5016C4.70682 11.4862 4.96688 11.5766 5.165 11.753L6.885 13.283C6.98311 13.3703 7.06306 13.4761 7.12028 13.5943C7.1775 13.7126 7.21087 13.8409 7.21848 13.972C7.2261 14.1031 7.20781 14.2345 7.16466 14.3585C7.12151 14.4826 7.05434 14.5969 6.967 14.695Z" fill="#AC6500" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.42 14.6C5.49879 14.7051 5.59751 14.7936 5.71051 14.8605C5.82351 14.9274 5.94858 14.9714 6.07858 14.9899C6.20858 15.0085 6.34097 15.0013 6.46818 14.9687C6.5954 14.9361 6.71494 14.8788 6.82 14.8L8.82 13.3C9.03217 13.1409 9.17244 12.904 9.20995 12.6414C9.24746 12.3789 9.17913 12.1122 9.02 11.9C8.86087 11.6878 8.62397 11.5476 8.36142 11.5101C8.09887 11.4725 7.83217 11.5409 7.62 11.7L5.62 13.2C5.51494 13.2788 5.42643 13.3775 5.35953 13.4905C5.29262 13.6035 5.24862 13.7286 5.23005 13.8586C5.21148 13.9886 5.2187 14.121 5.25129 14.2482C5.28388 14.3754 5.34121 14.4949 5.42 14.6ZM13 9C13.2652 9 13.5196 9.10536 13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10V13.5C14 13.7652 13.8946 14.0196 13.7071 14.2071C13.5196 14.3946 13.2652 14.5 13 14.5C12.7348 14.5 12.4804 14.3946 12.2929 14.2071C12.1054 14.0196 12 13.7652 12 13.5V10C12 9.73478 12.1054 9.48043 12.2929 9.29289C12.4804 9.10536 12.7348 9 13 9Z" fill="#AC6500" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17 13.5C17 13.7652 16.8946 14.0196 16.7071 14.2071C16.5196 14.3946 16.2652 14.5 16 14.5H13C12.7348 14.5 12.4804 14.3946 12.2929 14.2071C12.1054 14.0196 12 13.7652 12 13.5C12 13.2348 12.1054 12.9804 12.2929 12.7929C12.4804 12.6054 12.7348 12.5 13 12.5H16C16.2652 12.5 16.5196 12.6054 16.7071 12.7929C16.8946 12.9804 17 13.2348 17 13.5Z" fill="#AC6500" />
                                        </svg> : <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="27" height="27" rx="13.5" fill="#FFC6C6" />
                                            <path d="M8.4 21L7 19.6L12.6 14L7 8.4L8.4 7L14 12.6L19.6 7L21 8.4L15.4 14L21 19.6L19.6 21L14 15.4L8.4 21Z" fill="#EF6A6A" />
                                        </svg>}
                                    {swapTokensView.data.swap_transaction.status_formatted}
                                </div>
                            </div>
                            <div className="view-date-card">
                                <div className="view-date-label">Date</div>
                                <div className="view-date-badges">
                                    {swapTokensView.data.swap_transaction.created_at_formatted}
                                </div>
                            </div>
                            <div className="view-other-details-card">
                                <div className="view-other-info">
                                    <div className="view-other-label">Exchange Rate</div>
                                    <div className="view-other-value">{swapTokensView.data.swap_transaction.adjusted_exchange_rate_formatted}</div>
                                </div>
                                <div className="view-other-info">
                                    <div className="view-other-label">Amount Paid</div>
                                    <div className="view-other-value">{swapTokensView.data.swap_transaction.from_amount_formatted}</div>
                                </div>
                                <div className="view-other-info">
                                    <div className="view-other-label">Transaction  Date</div>
                                    <div className="view-other-value">{swapTokensView.data.swap_transaction.updated_at_formatted}</div>
                                </div>
                                
                                <div className="view-other-info">
                                    <div className="view-other-label">Type</div>
                                    <div className="view-other-value">Swap</div>
                                </div>
                                        {swapTokensView.data.swap_transaction.payment_type &&
                                                    <div className="view-other-info">
                                                        <div className="view-other-label">Payment Type</div>
                                                        <div className="view-other-value">{swapTokensView.data.swap_transaction.payment_type.toUpperCase()}</div>
                                                    </div>}
                                                {swapTokensView.data.swap_transaction
                                                    .unique_id && (
                                                        <div className="view-other-info">
                                                            <div className="view-other-label">Payment ID</div>
                                                            <div className="view-other-pay">{swapTokensView.data.swap_transaction.unique_id.length > 10
                                                                ? `${swapTokensView.data.swap_transaction.unique_id.slice(
                                                                    0,
                                                                    5
                                                                )}...${swapTokensView.data.swap_transaction.unique_id.slice(-5)}`
                                                                : swapTokensView.data.swap_transaction.unique_id}      <CopyToClipboard
                                                                    text={swapTokensView.data.swap_transaction.unique_id}
                                                                >
                                                                    <Button
                                                                        className="copy-view-btn"
                                                                        onClick={() => getSuccessNotificationMessage("Payment ID Copied")}
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            xmlSpace="preserve"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                        >
                                                                            <path
                                                                                fill="#298bff"
                                                                                d="M18.375 6.25h-8.75A3.044 3.044 0 0 0 6.25 9.625v8.75a3.044 3.044 0 0 0 3.375 3.375h8.75a3.044 3.044 0 0 0 3.375-3.375v-8.75a3.044 3.044 0 0 0-3.375-3.375m1.875 12.125c0 1.332-.543 1.875-1.875 1.875h-8.75c-1.332 0-1.875-.543-1.875-1.875v-8.75c0-1.332.543-1.875 1.875-1.875h8.75c1.332 0 1.875.543 1.875 1.875zM3.75 5.62v8.76c0 1.2.483 1.493.642 1.59a.751.751 0 0 1-.784 1.28 3.1 3.1 0 0 1-1.358-2.87V5.62a3.068 3.068 0 0 1 3.37-3.37h8.76a3.1 3.1 0 0 1 2.87 1.358.75.75 0 1 1-1.279.784c-.1-.159-.393-.642-1.591-.642H5.62c-1.328 0-1.87.542-1.87 1.87"
                                                                                data-original="#000000"
                                                                            ></path>
                                                                        </svg>
                                                                    </Button>
                                                                </CopyToClipboard></div>
                                                        </div>)}
                                                {swapTokensView.data.swap_transaction?.admin_crypto_account && (
                                                    <div className="view-other-info">
                                                        <div className="view-other-label">Wallet Address</div>
                                                        <div className="view-other-pay">{swapTokensView.data.swap_transaction.admin_crypto_account.wallet_address.length > 10
                                                            ? `${swapTokensView.data.swap_transaction.admin_crypto_account.wallet_address.slice(
                                                                0,
                                                                5
                                                            )}...${swapTokensView.data.swap_transaction.admin_crypto_account.wallet_address.slice(-5)}`
                                                            : swapTokensView.data.swap_transaction.admin_crypto_account.wallet_address}      <CopyToClipboard
                                                                text={swapTokensView.data.swap_transaction.admin_crypto_account.wallet_address}
                                                            >
                                                                <Button
                                                                    className="copy-view-btn"
                                                                    onClick={() => getSuccessNotificationMessage("Payment ID Copied")}
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        xmlSpace="preserve"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="#298bff"
                                                                            d="M18.375 6.25h-8.75A3.044 3.044 0 0 0 6.25 9.625v8.75a3.044 3.044 0 0 0 3.375 3.375h8.75a3.044 3.044 0 0 0 3.375-3.375v-8.75a3.044 3.044 0 0 0-3.375-3.375m1.875 12.125c0 1.332-.543 1.875-1.875 1.875h-8.75c-1.332 0-1.875-.543-1.875-1.875v-8.75c0-1.332.543-1.875 1.875-1.875h8.75c1.332 0 1.875.543 1.875 1.875zM3.75 5.62v8.76c0 1.2.483 1.493.642 1.59a.751.751 0 0 1-.784 1.28 3.1 3.1 0 0 1-1.358-2.87V5.62a3.068 3.068 0 0 1 3.37-3.37h8.76a3.1 3.1 0 0 1 2.87 1.358.75.75 0 1 1-1.279.784c-.1-.159-.393-.642-1.591-.642H5.62c-1.328 0-1.87.542-1.87 1.87"
                                                                            data-original="#000000"
                                                                        ></path>
                                                                    </svg>
                                                                </Button>
                                                            </CopyToClipboard></div>
                                                    </div>)}
                                                {swapTokensView.data.swap_transaction.cancel_reason && <div className="view-other-info">
                                                    <div className="view-other-label">Cancel Reason</div>
                                                    <div className="view-other-value">{swapTokensView.data.swap_transaction.cancel_reason} </div>
                                                </div>}
                                                {swapTokensView.data.swap_transaction
                                                    .commission &&
                                                    <div className="view-other-info">
                                                        <div className="view-other-label">Service fee(%)</div>
                                                        <div className="view-other-value">{swapTokensView.data.swap_transaction
                                                            .commission}</div>
                                                    </div>}
                                                    {swapTokensView.data.swap_transaction
                                                    .commission_rate &&
                                                    <div className="view-other-info">
                                                        <div className="view-other-label">Service fee</div>
                                                        <div className="view-other-value">{swapTokensView.data.swap_transaction
                                                            .commission_rate}{swapTokensView.data.swap_transaction
                                                                .from_currency}</div>
                                                    </div>}
                            </div>
                        </div>) : <NoDataFound />}
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default SwapTransactionView;