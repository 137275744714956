import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ContactUsPageIndex from '../../LandingPage/ContactUsPageIndex';
import ContactUs from '../../LandingPage/ContactUs';
import { Helmet } from 'react-helmet';
import '../Static.css'

const StaticContactUs = () => {
    return (
        <>
        <Helmet>
                <script
                    src='//in.fw-cdn.com/32218506/1186004.js'
                    chat='true'>
                </script>
            </Helmet>
        <div className="static-pag-sec">
            <div className="static-page-header">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col md="9">
                            <div className="static-section-title">
                                <h1>Contact Us</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ContactUs/>
            <Container fluid>
                <div className="static-box">
                    <Row>
                        <Col md={12}>
                            <div className="static-card">
                                <p>We’re here to help! Whether you have a question about our services, need assistance with your account, or want to provide feedback, the team at HeraldEX is ready to assist you.</p>
                                <ul>
                                    <li><strong>📧 Email:</strong> <a href="mailto:support@herald.exchange">support@herald.exchange</a></li>
                                    {/* <li><strong>📞 Phone:</strong> —</li> */}
                                </ul>
                                {/* <h4>Frequently Asked Questions (FAQs)</h4>
                                <p>Before reaching out, take a look at our FAQ page for quick answers to common questions about our services.</p> */}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
        </>
    );
};

export default StaticContactUs;
