import React, { useState } from "react";
import {
  Nav,
  Tab,
} from "react-bootstrap";
import "./HomeBanner.css";
import BuyCryptoForm from "../Buy/BuyCryptoForm";
import SellCryptoForm from "../Sell/SellCryptoForm";
import { useTranslation } from "react-multi-lang";

const SwapField = () => {

  const t = useTranslation("buy_index")
  const [activeTab, setActiveTab] = useState("buy_crypto");

  return (
    <div className="efi-exchange-swap-wrapped">
      <Tab.Container id="left-tabs-example" defaultActiveKey="but-crypto">
        <Nav variant="pills" className="d-flex">
          <Nav.Item>
            <Nav.Link
              eventKey="but-crypto"
              className="efi-swap-items"
              onClick={() => setActiveTab("buy_crypto")}
            >
              {t("buy_crypto")}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="sell-crypto"
              className="efi-swap-items"
              onClick={() => setActiveTab("sell_crypto")}
            >
              {t("sell_crypto")}
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="but-crypto">
            {activeTab == "buy_crypto" &&
              <BuyCryptoForm redirect={true} />
            }
          </Tab.Pane>
          <Tab.Pane eventKey="sell-crypto">
            {activeTab == "sell_crypto" &&
              <SellCryptoForm redirect={true} />
            }
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default SwapField;
