import React, { useEffect, useState } from "react";
import {
  Row,
  Container,
  Image,
  Col,
  Form,
  Button,
  Tab,
  Nav,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  createKycApplicantStart,
  logoutStart,
  profileStart,
  profileUpdateStart,
} from "../../store/actions/UserAction";
import { connect, useDispatch, useSelector } from "react-redux";
import ProfileLoader from "../helper/ProfileLoader";
import KYCUpdateModal from "../KYC/KYCUpdateModal";
import { useTranslation } from "react-multi-lang";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import EditProfileModal from "../Modals/EditProfileModal";
import { Checkbox, Switch } from "pretty-checkbox-react";
import "@djthoms/pretty-checkbox";
import ChangePasswordModal from "../Modals/ChangePasswordModal";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import EditAddressModal from "../Modals/EditAddressModal";
import ChangeMobileNumberModal from "../Modals/ChangeMobileNumberModal";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import LogoutModal from "../Modals/LogoutModal";
import DeleteAccountModal from "../Modals/DeleteAccountModal";
import ChangeProfileImageModal from "../Modals/ChangeProfileImageModal";
import useLogout from "../layouts/Header/useLogout";
import EnableDisableTwoStepAuth from "../Auth/EnableDisableTwoStepAuth";
import { countryData } from "../helper/CountryList";
import "../Profile/Profile.css"

const ProfileIndex = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [editModal, setEditShow] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [changeMobileNumber, setchangeMobileNumber] = useState(false);
  const [twoStepAuthModal, setTwoStepAuthModal] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState();
  const [changeProfileImage, setChangeProfileImage] = useState(false);
  const [logoutShow, setLogoutShow] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState(false);
  const logout = useSelector((state) => state.user.logout);
  const profile = useSelector((state) => state.user.profile);
  const [activeKey, setActiveKey] = useState("first")

  const uploader = Uploader({
    apiKey: "free",
  });

  const options = {
    multi: false,
    mimeTypes: ["image/jpeg", "image/png", "image/jpg"],
  };

  const t = useTranslation("profile");
  const [kyc, setKyc] = useState(false);
  const [skipRender, setSkipRender] = useState(true);

  const closeKycModal = () => {
    setKyc(false);
  };
  useEffect(() => {
    if (
      !skipRender &&
      !props.kycStatusUpdate.loading &&
      Object.keys(props.kycStatusUpdate.data).length > 0
    ) {
      closeKycModal();
    }
    setSkipRender(false);
  }, [props.kycStatusUpdate]);

  const verifyKyc = () => {
    props.dispatch(createKycApplicantStart());
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.kycApplicant.loading &&
      Object.keys(props.kycApplicant.data).length > 0
    ) {
      setKyc(props.kycApplicant.data)
    }
    setSkipRender(false);
  }, [props.kycApplicant]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.updateProfile.loading &&
      Object.keys(props.updateProfile.data).length > 0
    ) {
      setEditShow(false);
      setAddressModal(false);
    }
    setIsSwitchOn(props.profile.data.is_2fa_enabled == 1 ? true : false);
  }, [props.updateProfile]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.changePassword.loading &&
      Object.keys(props.changePassword.data).length > 0
    ) {
      props.dispatch(logoutStart());
    }
    setSkipRender(false);
  }, [props.changePassword]);

  useEffect(() => {
    if (!skipRender && !logout.loading && Object.keys(logout.data).length > 0) {
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("lang", "en");
      navigate("/login");
    }
    setSkipRender(false);
  }, [logout]);

  return (
    <>
      {props.profile.loading ? (
        <ProfileLoader />
      ) : (
        <div className="feature-profile-wrapped">
            <Row>
              <Col sm={12} lg={5} xl={5} className="resp-marg-btm-xs">
                <div className="feature-primary-profile-card">
                  <div className="feature-primary-profile-input">
                    <div className="feature-primary-profile-dp">
                      {props.updateProfile.buttonDisable ? (
                        <Skeleton circle={true} height={100} width={100} />
                      ) : (
                        <CustomLazyLoad
                          className="user profile-image"
                          src={
                            props.profile.data.picture ||
                            window.location.origin + "/img/icon/user.png"
                          }
                          type="image/png"
                        />
                      )}
                    </div>
                    {/* <UploadButton
                      uploader={uploader}
                      options={options}
                      onComplete={(files) => {
                        files.length > 0 &&
                          files[0].editedFile.file &&
                          props.dispatch(
                            profileUpdateStart({
                              picture: files[0].editedFile,
                              name: props.profile.data.name,
                              email: props.profile.data.email,
                              website_url: props.profile.data.website_url,
                            })
                          );
                      }}
                    > */}

                    <Button
                      className="upload-profile-btn"
                      onClick={() => setChangeProfileImage(true)}
                    >
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="none"
                        viewBox="0 0 14 14"
                      >
                        <path
                          fill="#000"
                          d="M9.829.5l-.03.028.029.085c.328.938.865 1.79 1.57 2.49A6.563 6.563 0 0013.97 4.7l-6.42 6.42c-.433.433-.65.65-.889.836-.281.219-.586.407-.908.56-.273.131-.563.228-1.144.421l-3.063 1.021A.795.795 0 01.54 12.952L1.561 9.89c.194-.58.29-.87.42-1.143.155-.324.342-.627.562-.91.186-.238.403-.454.836-.887L9.829.5z"
                        ></path>
                      </svg> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 18 19"
                      >
                        <path
                          fill="#000"
                          d="M15.637 7.035a2.949 2.949 0 10-4.171-4.171l-.665.665.028.083c.328.939.865 1.79 1.57 2.49A6.563 6.563 0 0014.972 7.7l.665-.665z"
                          opacity="0.5"
                        ></path>
                        <path
                          fill="#000"
                          d="M10.829 3.5l-.03.029.029.083c.328.939.865 1.79 1.57 2.49A6.563 6.563 0 0014.97 7.7l-6.42 6.42c-.433.433-.65.65-.889.836-.281.219-.586.407-.908.56-.273.131-.563.228-1.144.421l-3.063 1.021a.795.795 0 01-1.006-1.006l1.021-3.063c.194-.58.29-.87.42-1.143.155-.324.342-.627.562-.91.186-.238.403-.454.836-.887l6.45-6.449z"
                        ></path>
                      </svg>
                    </Button>

                    {/* </UploadButton> */}
                  </div>
                  <div className="feature-primary-profile-info">
                    <h3>
                      {props.profile.data.first_name}{" "}
                      {props.profile.data.middle_name}{" "}
                      {props.profile.data.last_name}
                    </h3>
                    <p>
                      {t(
                        "manage_your_personal_information_email_password_more"
                      )}
                    </p>
                  </div>
                  <div className="feature-primary-profile-details">
                    <h4>{t("email.label")}</h4>
                    <p>{props.profile.data.email}</p>
                  </div>
                  <div className="feature-primary-profile-details">
                    <h4>{t("mobile_number")}</h4>
                    <p>
                      +{props.profile.data.country_code}
                      {"  "}
                      {props.profile.data.mobile || "N/A"}
                    </p>
                  </div>
                  {props.profile.data?.onboarding_completed == 1 && (
                    <div className="feature-primary-profile-details">
                      <h4>{t("gender")}</h4>
                      <p>
                        {" "}
                        {props.profile.data.gender == "M"
                          ? "Male"
                          : props.profile.data.gender == "F"
                            ? "Female"
                            : "Others"}
                      </p>
                    </div>
                  )}
                  {props.profile.data?.onboarding_completed == 1 && (
                    <div className="feature-primary-profile-details">
                      <h4>{t("date_of_birth")}</h4>
                      <p>
                        {props.profile.data.dob.slice(-2)}
                        {props.profile.data.dob.slice(4, 8)}
                        {props.profile.data.dob.slice(0, 4)}
                      </p>
                    </div>
                  )}
                  <div className="feature-profile-action">
                    <div className="edit-profile-note-info-sec">
                      <Button
                        className="action-btn primary"
                        onClick={() => setchangeMobileNumber(true)}
                        disabled={props.profile.data?.user_type == 1 ? props.profile.data?.kyc_verified == 1 : props.profile.data?.kyb_status == 3}
                      >
                        <span>{t("edit")}</span>
                      </Button>
                    </div>
                    {props.profile.data?.user_type == 1 ? props.profile.data.kyc_verified ? (
                      <Button className="finger-print-btn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          fill="none"
                          viewBox="0 0 35 35"
                        >
                          <path
                            fill="#51C8AC"
                            d="M21.328 15.953v-.51c0-3.336-2.453-6.183-5.585-6.482a6.187 6.187 0 00-5.572 2.45 1.092 1.092 0 11-1.749-1.313c1.77-2.356 4.573-3.595 7.528-3.314 4.197.4 7.483 4.13 7.553 8.52-.757.139-1.486.354-2.175.65zM3.464 21.431c.603 0 1.093-.49 1.093-1.094V15.53c0-5.782 4.334-10.592 9.867-10.95a10.61 10.61 0 015.992 1.39 1.091 1.091 0 001.494-.398 1.092 1.092 0 00-.399-1.494 12.752 12.752 0 00-7.228-1.681C7.603 2.83 2.37 8.598 2.37 15.53v4.807c0 .604.49 1.094 1.093 1.094zm4.374-7.395c-.603 0-1.093.49-1.093 1.094v8.75a9.882 9.882 0 004.92 8.527 1.094 1.094 0 101.095-1.894 7.685 7.685 0 01-3.828-6.633v-8.75c0-.604-.49-1.094-1.094-1.094zm11.303 3.21v-1.96c0-2.035-1.439-3.802-3.346-4.112a4.034 4.034 0 00-3.262.9 4.006 4.006 0 00-1.413 3.056v5.833c0 4.822 2.689 9.025 6.644 11.195a10.165 10.165 0 01-2.47-5.048 10.502 10.502 0 01-1.987-6.146V15.13c0-.535.234-1.042.643-1.39a1.812 1.812 0 011.495-.407c.86.14 1.508.979 1.508 1.953v4.246a10.28 10.28 0 012.188-2.286zm5.148-7.402a10.57 10.57 0 011.414 5.286v.018c.753.027 1.484.136 2.188.318v-.336c0-2.243-.59-4.449-1.708-6.38a1.095 1.095 0 00-1.894 1.094z"
                          ></path>
                          <path
                            fill="#1AA080"
                            d="M25.338 18.047a7.292 7.292 0 100 14.583 7.292 7.292 0 000-14.583zm3.433 5.862l-3.889 3.89a.729.729 0 01-1.031 0l-1.945-1.945a.729.729 0 111.031-1.031l1.43 1.429 3.373-3.374a.729.729 0 111.03 1.031z"
                          ></path>
                        </svg>
                        {t("kyc_verified")}
                      </Button>
                    ) : (
                      <>
                        {props.profile.data?.onboarding_completed == 0 && (
                          <Link className="action-btn" to="/onboarding">
                            Initiate Onboarding
                          </Link>
                        )}
                        {props.profile.data?.kyc_verified == 0 &&
                          props.profile.data?.onboarding_completed == 1 && (
                            <Button
                              onClick={verifyKyc}
                              className="action-btn "
                              disabled={props.kycApplicant.buttonDisable}
                            >
                              {props.kycApplicant.buttonDisable
                                ? t("loading")
                                : "Verify KYC"}
                            </Button>
                          )}
                      </>
                    ) : props.profile.data.kyb_status == 3 ? (
                      <Button className="finger-print-btn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          fill="none"
                          viewBox="0 0 35 35"
                        >
                          <path
                            fill="#51C8AC"
                            d="M21.328 15.953v-.51c0-3.336-2.453-6.183-5.585-6.482a6.187 6.187 0 00-5.572 2.45 1.092 1.092 0 11-1.749-1.313c1.77-2.356 4.573-3.595 7.528-3.314 4.197.4 7.483 4.13 7.553 8.52-.757.139-1.486.354-2.175.65zM3.464 21.431c.603 0 1.093-.49 1.093-1.094V15.53c0-5.782 4.334-10.592 9.867-10.95a10.61 10.61 0 015.992 1.39 1.091 1.091 0 001.494-.398 1.092 1.092 0 00-.399-1.494 12.752 12.752 0 00-7.228-1.681C7.603 2.83 2.37 8.598 2.37 15.53v4.807c0 .604.49 1.094 1.093 1.094zm4.374-7.395c-.603 0-1.093.49-1.093 1.094v8.75a9.882 9.882 0 004.92 8.527 1.094 1.094 0 101.095-1.894 7.685 7.685 0 01-3.828-6.633v-8.75c0-.604-.49-1.094-1.094-1.094zm11.303 3.21v-1.96c0-2.035-1.439-3.802-3.346-4.112a4.034 4.034 0 00-3.262.9 4.006 4.006 0 00-1.413 3.056v5.833c0 4.822 2.689 9.025 6.644 11.195a10.165 10.165 0 01-2.47-5.048 10.502 10.502 0 01-1.987-6.146V15.13c0-.535.234-1.042.643-1.39a1.812 1.812 0 011.495-.407c.86.14 1.508.979 1.508 1.953v4.246a10.28 10.28 0 012.188-2.286zm5.148-7.402a10.57 10.57 0 011.414 5.286v.018c.753.027 1.484.136 2.188.318v-.336c0-2.243-.59-4.449-1.708-6.38a1.095 1.095 0 00-1.894 1.094z"
                          ></path>
                          <path
                            fill="#1AA080"
                            d="M25.338 18.047a7.292 7.292 0 100 14.583 7.292 7.292 0 000-14.583zm3.433 5.862l-3.889 3.89a.729.729 0 01-1.031 0l-1.945-1.945a.729.729 0 111.031-1.031l1.43 1.429 3.373-3.374a.729.729 0 111.03 1.031z"
                          ></path>
                        </svg>
                        {t("kyb_verified")}
                      </Button>
                    ) : (
                      <>
                        {props.profile.data?.onboarding_completed == 0 && (
                          <Link className="action-btn" to="/onboarding">
                            Initiate Onboarding
                          </Link>
                        )}
                        {props.profile.data?.kyb_status != 3 &&
                          props.profile.data?.onboarding_completed == 1 && (
                            <Button
                              onClick={() => dispatch(createKycApplicantStart())}
                              className="action-btn "
                            // disabled={props.kycApplicant.buttonDisable}
                            >
                              {
                                props.kycApplicant.buttonDisable
                                  ? t("loading")
                                  : "Verify KYB"}
                            </Button>
                          )}
                      </>
                    )}
                  </div>
                  {props.profile.data?.user_type == 1 ? props.profile.data?.kyc_verified == 0 ? (
                    <p className="note-info">
                      <span className="text-danger text bold">Note:</span>
                      After KYC verified, editing is prohibited.
                    </p>
                  ) : (
                    <p className="note-info">
                      <span className="text-danger text bold">Note:</span>
                      KYC verified, editing is prohibited.
                    </p>
                  ) : props.profile.data?.kyb_status != 3 ? (
                    <p className="note-info">
                      <span className="text-danger text bold">Note:</span>
                      After KYB verified, editing is prohibited.
                    </p>
                  ) : (
                    <p className="note-info">
                      <span className="text-danger text bold">Note:</span>
                      KYB verified, editing is prohibited.
                    </p>
                  )}
                </div>
              </Col>
              <Col sm={12} lg={7} xl={7}>
                <div className="feature-profile-content-frame">
                  {/* <div className="feature-second-profile-card">
                <div className="feature-second-profile-right">
                  <div className="feature-second-profile-right-icons">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="28"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                      <path d="M3.6 9h16.8" />
                      <path d="M3.6 15h16.8" />
                      <path d="M11.5 3a17 17 0 0 0 0 18" />
                      <path d="M12.5 3a17 17 0 0 1 0 18" />
                    </svg>
                  </div>
                  <div className="feature-second-profile-info">
                    <h3>{t("website_urls")}</h3>
                    <p>{props.profile.data.website_url}</p>
                  </div>
                </div>
                <div className="feature-second-profile-left">
                  <div className="feature-profile-action">
                    <Button
                      className="new-action-btn"
                      onClick={() => setEditShow(true)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#333"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                        <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                        <path d="M16 5l3 3" />
                      </svg>
                      <span>{t("edit")}</span>
                    </Button>
                  </div>
                </div>
              </div> */}
                  {/* {props.profile.data?.onboarding_completed == 1 && (
                 
                  )} */}
                  {props.profile.data?.onboarding_completed == 1 && (
                    <div className="feature-second-profile-card">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="first"
                      >
                        <Row>
                          <Col sm={12} className="resp-marg-btm-xs" >
                            <div className="profile-nav-frame">
                              <Nav variant="pills">
                                <Nav.Item>
                                  <Nav.Link eventKey="first" onClick={() => setActiveKey("first")}>
                                    {t("billing_address")}
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="second" onClick={() => setActiveKey("second")}>
                                    {t("delivery_address")}
                                  </Nav.Link>
                                </Nav.Item>
                                {profile.data.user_type == 2 ? (
                                  <Nav.Item>
                                    <Nav.Link eventKey="third" onClick={() => setActiveKey("third")}>
                                      {t("business_details")}
                                    </Nav.Link>
                                  </Nav.Item>
                                ) : null}
                              </Nav>
                              {activeKey != "third" ? <div className="edit-note-info-sec">
                                <Button
                                  className="action-btn overlay item_flex_x_5 "
                                  onClick={() =>
                                    setAddressModal(props.profile.data)
                                  }
                                  disabled={props.profile.data?.user_type == 1 ? props.profile.data?.kyc_verified == 1 : props.profile.data?.kyb_status == 3}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#333"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    />
                                    <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                    <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                    <path d="M16 5l3 3" />
                                  </svg>
                                  <span>{t("edit")}</span>
                                </Button>
                                {props.profile.data?.user_type == 1 ? props.profile.data?.kyc_verified == 0 ? (
                                  <p className="note-info">
                                    <span className="text-danger text bold">{t("note")}:</span>
                                    {t("after_kyc_verified_editing_is_prohibited")}
                                  </p>
                                ) : (
                                  <p className="note-info">
                                    <span className="text-danger text bold">{t("note")}:</span>
                                    {t("kyc_verified_editing_is_prohibited")}
                                  </p>
                                ) : props.profile.data?.kyb_status != 3 ? (
                                  <p className="note-info">
                                    <span className="text-danger text bold">{t("note")}:</span>
                                    {t("after_kyb_verified_editing_is_prohibited")}
                                  </p>
                                ) : (
                                  <p className="note-info">
                                    <span className="text-danger text bold">{t("note")}:</span>
                                    {t("kyb_verified_editing_is_prohibited")}
                                  </p>
                                )}
                              </div> : null}
                            </div>
                          </Col>
                          <Col sm={12}>
                            <Tab.Content>
                              <Tab.Pane eventKey="first">
                                <div className="feature-profile-address-info">
                                  <h3>{t("address")}</h3>
                                  <p>
                                    {!props.profile.data.billing_address_1 &&
                                      !props.profile.data.billing_address_2
                                      ? t("n_a")
                                      : `${props.profile.data.billing_address_1},
                                      ${props.profile.data.billing_address_2}`}
                                  </p>
                                </div>
                                <div className="feature-profile-address-grid">
                                  <div className="feature-profile-address-info">
                                    <h3>{t("city.label")}</h3>
                                    <p>
                                      {props.profile.data.billing_city ||
                                        t("n_a")}
                                    </p>
                                  </div>
                                  <div className="feature-profile-address-info">
                                    <h3>{t("state.label")}</h3>
                                    <p>
                                      {props.profile.data.billing_state ||
                                        t("n_a")}
                                    </p>
                                  </div>
                                  <div className="feature-profile-address-info">
                                    <h3>{t("country.label")}</h3>
                                    <p>
                                      {/* {Object.entries(countryData).filter(
                                        ([code, name]) =>
                                          code ==
                                          props.profile.data.billing_country
                                      )} */}
                                      {countryData[
                                        props.profile.data.billing_country
                                      ] || t("n_a")}
                                    </p>
                                  </div>
                                  <div className="feature-profile-address-info">
                                    <h3>{t("zipcode.label")}</h3>
                                    <p>
                                      {props.profile.data.billing_postal_code ||
                                        t("n_a")}
                                    </p>
                                  </div>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="second">
                                <div className="feature-profile-address-info">
                                  <h3>{t("address")}</h3>
                                  <p>
                                    {!props.profile.data.delivery_address_1 &&
                                      !props.profile.data.delivery_address_2
                                      ? t("n_a")
                                      : `${props.profile.data.delivery_address_1},
                                      ${props.profile.data.delivery_address_2}`}
                                  </p>
                                </div>
                                <div className="feature-profile-address-grid">
                                  <div className="feature-profile-address-info">
                                    <h3>{t("city.label")}</h3>
                                    <p>
                                      {props.profile.data.delivery_city ||
                                        t("n_a")}
                                    </p>
                                  </div>
                                  <div className="feature-profile-address-info">
                                    <h3>{t("state.label")}</h3>
                                    <p>
                                      {props.profile.data.delivery_state ||
                                        t("n_a")}
                                    </p>
                                  </div>
                                  <div className="feature-profile-address-info">
                                    <h3>{t("country.label")}</h3>
                                    <p>
                                      {countryData[
                                        props.profile.data.delivery_country
                                      ] || t("n_a")}
                                    </p>
                                  </div>
                                  <div className="feature-profile-address-info">
                                    <h3>{t("zipcode.label")}</h3>
                                    <p>
                                      {props.profile.data
                                        .delivery_postal_code || t("n_a")}
                                    </p>
                                  </div>
                                </div>
                              </Tab.Pane>
                              {profile.data.user_type == 2 ? (
                                <Tab.Pane eventKey="third">
                                  {/*<div className="feature-profile-address-info">
                                    </div> */}
                                  <div className="feature-profile-address-grid">
                                    <div className="feature-profile-address-info">
                                      <h3>{t("company_name")}</h3>
                                      <p>
                                        {profile.data.user_business
                                          .company_name || t("n_a")}
                                      </p>
                                    </div>
                                    <div className="feature-profile-address-info">
                                      <h3>{t("preferred_name")}</h3>
                                      <p>
                                        {profile.data.user_business
                                          .company_name || t("n_a")}
                                      </p>
                                    </div>
                                    <div className="feature-profile-address-info">
                                      <h3>{t("tax_number")}</h3>
                                      <p>
                                        {profile.data.user_business
                                          .tax_id_number || t("n_a")}
                                      </p>
                                    </div>
                                    <div className="feature-profile-address-info">
                                      <h3>{t("tax_type")}</h3>
                                      <p>
                                        {profile.data.user_business.tax_id_type.toUpperCase() ||
                                          t("n_a")}
                                      </p>
                                    </div>
                                    <div className="feature-profile-address-info">
                                      <h3>{t("tax_country")}</h3>
                                      <p>
                                        {countryData[
                                          profile.data.user_business
                                            .tax_id_issuance_country
                                        ] || t("n_a")}
                                      </p>
                                    </div>
                                    <div className="feature-profile-address-info">
                                      <h3>{t("foundation_date")}</h3>
                                      <p>
                                        {profile.data.user_business.foundation_date.split("-").reverse().join("-") ||
                                          t("n_a")}
                                      </p>
                                    </div>
                                    <div className="feature-profile-address-info">
                                      <h3>{t("tax_issue_date")}</h3>
                                      <p>
                                        {profile.data.user_business.tax_id_date_issued.split("-").reverse().join("-") ||
                                          t("n_a")}
                                      </p>
                                    </div>
                                    <div className="feature-profile-address-info">
                                      <h3>{t("tax_expiry_date")}</h3>
                                      <p>
                                        {profile.data.user_business.tax_id_date_expiry.split("-").reverse().join("-") ||
                                          t("n_a")}
                                      </p>
                                    </div>
                                  </div>
                                </Tab.Pane>
                              ) : null}
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </div>
                  )}
                  <div className="feature-second-profile-card">
                    <div className="feature-second-profile-info">
                      <h3>{t("change_password")}</h3>
                      <p className="address-para">***** **** *** **</p>
                    </div>
                    <div className="feature-profile-action">
                      <Button
                        className="action-btn overlay"
                        onClick={() => setModalShow(true)}
                      >
                        <span>{t("change_password")}</span>
                      </Button>
                    </div>
                  </div>
                  <div className="feature-second-profile-card">
                    <div className="feature-second-profile-info">
                      <h3>{t("logout")}</h3>
                      <p className="address-para">
                        {t("if_you_notice_any_para")}
                      </p>
                    </div>
                    <div className="feature-profile-action">
                      <Button
                        className="action-btn overlay"
                        onClick={() => setLogoutShow(true)}
                      >
                        <span>{t("logout")}</span>
                      </Button>
                    </div>
                  </div>
                  <div className="feature-second-profile-card">
                    <div className="feature-second-profile-info">
                      <h3>{t("two_step_authentication")}</h3>
                      <p className="address-para">
                        {t("multi_factor_authentication")}
                      </p>
                    </div>
                    <div className="feature-profile-action">
                      <Switch
                        checked={isSwitchOn}
                        onChange={() => {
                          setIsSwitchOn(!isSwitchOn);
                          setTwoStepAuthModal(true);
                        }}
                      ></Switch>
                    </div>
                  </div>
                  <div className="feature-second-profile-card">
                    <div className="feature-second-profile-info">
                      <h3>{t("close_your_account")}</h3>
                      <p>{t("if_you_want_to_stop_para")}</p>
                    </div>
                    <div className="feature-profile-action">
                      <Button
                        className="action-btn overlay"
                        onClick={() => setDeleteAccount(true)}
                      >
                        <span>{t("delete_account")}</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
        </div>
      )}
      {kyc && <KYCUpdateModal kyc={kyc} closeKyc={closeKycModal} />}

      {/* Edit PopUp  */}
      {editModal ? (
        <EditProfileModal show={editModal} onHide={() => setEditShow(false)} />
      ) : null}
      {modalShow ? (
        <ChangePasswordModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      ) : null}
      {addressModal ? (
        <EditAddressModal
          show={addressModal}
          onHide={() => setAddressModal(false)}
        />
      ) : null}

      {changeMobileNumber ? (
        <ChangeMobileNumberModal
          profile={props.profile}
          show={changeMobileNumber}
          onHide={() => setchangeMobileNumber(false)}
          setchangeMobileNumber={setchangeMobileNumber}
        />
      ) : null}
      {changeProfileImage ? (
        <ChangeProfileImageModal
          profile={props.profile}
          show={changeProfileImage}
          onHide={() => setChangeProfileImage(false)}
        />
      ) : null}
      {logoutShow ? (
        <LogoutModal show={logoutShow} onHide={() => setLogoutShow(false)} />
      ) : null}
      {deleteAccount ? (
        <DeleteAccountModal
          show={deleteAccount}
          onHide={() => setDeleteAccount(false)}
        />
      ) : null}
      {twoStepAuthModal && (
        <EnableDisableTwoStepAuth
          show={twoStepAuthModal}
          onHide={() => {
            setIsSwitchOn(
              props.profile.data.is_2fa_enabled == 1 ? true : false
            );
            setTwoStepAuthModal(false);
          }}
        />
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.user.profile,
  kycStatusUpdate: state.user.updateKYC,
  kycApplicant: state.user.kycApplicant,
  updateProfile: state.user.updateProfile,
  changePassword: state.user.changePassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(ProfileIndex);
