import React, { useEffect, useRef, useState } from 'react';
import { Tab, Nav, Image, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-multi-lang';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleWalletViewStart } from '../../store/actions/WalletAction';
import { ErrorMessage, Field, Formik, Form as FORM, } from 'formik';
import { getErrorNotificationMessage } from '../helper/ToastNotification';
import { tokenTradeTransactionStart } from '../../store/actions/BuyAction';
import * as Yup from "yup";
import { ButtonLoader } from "../helper/Loader";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';

const TradeModal = (props) => {
  const [rangeValue, setRangeValue] = useState(0);
  const [eventKey, setEventKey] = useState();
  const t = useTranslation("trade");
  const navigate = useNavigate()

  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet.walletData);
  const [skipRender, setSkipRender] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const currencyPair = useSelector((state) => state.buy.currencyPair);
  const currencies = useSelector((state) => state.buy.currencies);
  const profile = useSelector((state) => state.user.profile);
  const singleWallet = useSelector((state) => state.wallet.singleWallet);
  const cryptoCurrency = useSelector((state) => state.buy.cryptoCurrency);
  const tokenTradeTransaction = useSelector(
    (state) => state.buy.tokenTradeTransaction
  );

  const formRef = useRef();
  const sliderRef = useRef(null);

  useEffect(() => {
    setEventKey(props.modal)
  }, [props.modal])

  useEffect(() => {
    if (
      !skipRender &&
      !wallet.loading &&
      eventKey == "sell" &&
      Object.keys(wallet.data).length > 0 &&
      currencyPair !== null &&
      (sessionStorage.getItem("token") || localStorage.getItem("token"))
    ) {
      const walletID = wallet.data["crypto_wallets"].find(
        (data) => data.currency_code === currencyPair.currency1
      );
      dispatch(
        fetchSingleWalletViewStart({
          user_wallet_id: walletID?.id,
        })
      );
    }
  }, [currencyPair, eventKey, refresh, wallet]);

  useEffect(() => {
    if (
      !skipRender &&
      !wallet.loading &&
      eventKey == "buy" &&
      Object.keys(wallet.data).length > 0 &&
      currencyPair !== null &&
      (sessionStorage.getItem("token") || localStorage.getItem("token"))
    ) {
      const walletID = wallet.data[
        ["fiat", "fait"].includes(currencyPair.currency_type2)
          ? "user_wallets"
          : "crypto_wallets"
      ].find((data) => data.currency_code === currencyPair.currency2);
      dispatch(
        fetchSingleWalletViewStart({
          user_wallet_id: walletID?.id,
        })
      );
    }
    setSkipRender(false);
  }, [currencyPair, eventKey, refresh, wallet]);

  const handleSubmit = (values) => {
    currencyPair !== null
      ? profile.data?.kyc_verified == 0
        ? getErrorNotificationMessage("Kyc is not verified")
        : dispatch(
          tokenTradeTransactionStart({ ...values, pair_id: currencyPair?.id })
        )
      : getErrorNotificationMessage("Select currency pair from left sidebar");
  };

  const buySchema = Yup.object().shape({
    value: Yup.string()
      .required("Required")
      .min(0.0000001, "Min 0.0000001 required")
      .matches(/^\S+$/, t("no_spaces_are_allowed"))
      .max(100000, "Max 100000 allowed"),
  });

  useEffect(() => {
    if (
      !skipRender &&
      !wallet.loading &&
      eventKey == "sell" &&
      Object.keys(wallet.data).length > 0 &&
      currencyPair !== null &&
      (sessionStorage.getItem("token") || localStorage.getItem("token"))
    ) {
      const walletID = wallet.data["crypto_wallets"].find(
        (data) => data.currency_code === currencyPair.currency1
      );
      dispatch(
        fetchSingleWalletViewStart({
          user_wallet_id: walletID?.id,
        })
      );
    }
  }, [currencyPair, eventKey, refresh, wallet]);

  useEffect(() => {
    if (
      !skipRender &&
      !wallet.loading &&
      eventKey == "buy" &&
      Object.keys(wallet.data).length > 0 &&
      currencyPair !== null &&
      (sessionStorage.getItem("token") || localStorage.getItem("token"))
    ) {
      const walletID = wallet.data[
        ["fiat", "fait"].includes(currencyPair.currency_type2)
          ? "user_wallets"
          : "crypto_wallets"
      ].find((data) => data.currency_code === currencyPair.currency2);
      dispatch(
        fetchSingleWalletViewStart({
          user_wallet_id: walletID?.id,
        })
      );
    }
    setSkipRender(false);
  }, [currencyPair, eventKey, refresh, wallet]);

  useEffect(() => {
    if (
      !skipRender &&
      !tokenTradeTransaction.loading &&
      Object.keys(tokenTradeTransaction.data).length > 0
    ) {
      setRefresh(!refresh);
      formRef?.current?.resetForm();
      setRangeValue(0);
    }
    setSkipRender(false);
  }, [tokenTradeTransaction]);

  return (
    <>
      <Tab.Container id="buy-sell-modal" activeKey={eventKey}>
        <div className="trade-modal-header">
          <Nav variant="pills"
            onSelect={(e) => { setEventKey(e) }}
          >
            <Nav.Item>
              <Nav.Link eventKey="buy">Buy</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="sell">Sell</Nav.Link>
            </Nav.Item>
          </Nav>
          <Button type="button" className="cancel-btn" onClick={() => { props.setModal() }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              width="24"
              height="24"
              fillRule="evenodd"
              viewBox="0 0 21 21"
              fill="#4F5459"
            >
              <g>
                <path d="m3.486 16.029 12.41-12.41a.183.183 0 0 1 .258 0l.326.326.004.004.702.702.003.004.327.326a.183.183 0 0 1 0 .258L5.106 17.65a.183.183 0 0 1-.258 0l-.326-.327-.004-.003-.702-.702-.004-.004-.326-.326a.183.183 0 0 1 0-.258z"></path>
                <path d="M15.896 17.649 3.486 5.239a.183.183 0 0 1 0-.258l.327-.326.003-.004.702-.702.004-.004.326-.326a.183.183 0 0 1 .258 0l12.41 12.41a.183.183 0 0 1 0 .258l-.326.326-.004.004-.702.702-.004.003-.326.327a.183.183 0 0 1-.258 0"></path>
              </g>
            </svg>
          </Button>
        </div>
        <div className="trade-modal-tabs">
          <Tab.Content>
            <Tab.Pane eventKey="buy">
              {singleWallet.buttonDisable ?
                <div className="trade-modal-body">
                  <div className="trade-modal-type">
                    <div className="trade-modal-type-icons">
                      <Skeleton width={130} height={25} borderRadius={9} baseColor='#FFFFFF17' />
                    </div>

                  </div>
                  <div className="trade-modal-fields">

                    <Form.Group className="mb-5 position-relative" >
                      <Skeleton height={60} borderRadius={10} baseColor='#FFFFFF17' />
                    </Form.Group>

                    <Form.Group className="mb-4 position-relative" >
                      <Skeleton height={60} borderRadius={10} baseColor='#FFFFFF17' />
                    </Form.Group>
                    <Form.Group
                      className="trade-percent-range mb-3"
                    >
                      <Skeleton height={25} borderRadius={10} baseColor='#FFFFFF17' />
                    </Form.Group>
                    <div className="trade-modal-action">
                      <Skeleton height={60} borderRadius={10} baseColor='#FFFFFF17' />
                      <Skeleton height={60} borderRadius={10} baseColor='#FFFFFF17' />
                    </div>
                  </div>
                </div>
                :
                <div className="trade-modal-body">
                  <div className="trade-modal-type">
                    <div className="trade-modal-type-icons">
                      <Image
                        className="trade-modal-type-img"
                        src={
                          currencyPair?.image ||
                            window.location.origin +
                            "/img/pair-demo.png"
                        }
                        alt="Pair"
                      />
                    </div>
                    <div className="trade-modal-type-name">{currencyPair.currency1}/{currencyPair.currency2}</div>
                  </div>
                  <div className="trade-modal-fields">
                    <Formik
                      initialValues={{
                        value: "",
                        type: "buy",
                        pair_id: "",
                      }}
                      innerRef={formRef}
                      validationSchema={buySchema}
                      onSubmit={(values) => handleSubmit(values)}
                    >
                      {({ touched, errors, setFieldValue, values, resetForm }) => (
                        <FORM>
                          <Form.Group className="mb-5 position-relative" >
                            <Form.Label>Quantity {currencyPair.currency2}  <span>*</span></Form.Label>
                            <Field
                              type="text"
                              name="value"
                              disabled={
                                (!singleWallet.data?.user_wallet?.remaining_original) ||
                                (singleWallet.data?.user_wallet?.remaining_original <= 0)
                              }
                              onKeyDown={(e) => {
                                if (
                                  !/[0-9.]|Backspace|Tab/.test(e.key) &&
                                  !(
                                    (e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")) ||
                                    e.code == "Space" ||
                                    e.code == "ArrowRight" ||
                                    e.code == "ArrowLeft"
                                  )
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                const remainingOriginal =
                                  singleWallet.data?.user_wallet?.remaining_original || 0;

                                if (
                                  newValue <= parseFloat(remainingOriginal) ||
                                  (!localStorage.getItem("token") &&
                                    !sessionStorage.getItem("token"))
                                ) {
                                  setFieldValue("value", newValue);
                                  const percentage =
                                    (parseFloat(newValue) / remainingOriginal) * 100;

                                  setRangeValue(
                                    isNaN(percentage)
                                      ? 0
                                      : Math.min(percentage, 100).toFixed(2)
                                  );
                                } else {
                                  setFieldValue("value", values.value);
                                }
                              }}
                              className="form-control"
                              placeholder={t("you_pay.placeholder")}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="value"
                              className="text-danger text-right"
                            />
                          </Form.Group>

                          <Form.Group className="mb-4 position-relative" >
                            <Form.Label>  Price {currencyPair.currency1} <span>*</span></Form.Label>
                            <Form.Control type="text"
                              value={
                                values.value > 0
                                  ? !isNaN(
                                    ((values.value * 1) /
                                      (cryptoCurrency?.filter(
                                        (data) => currencyPair?.currency1 == data.asset
                                      ))[0]?.value) /
                                    (currencyPair?.currency_type2 == "fiat"
                                      ? currencies.data?.forex_currencies?.filter(
                                        (data) =>
                                          data.currency_code ==
                                          currencyPair?.currency2
                                      )[0]?.exchange_rate
                                      : (cryptoCurrency?.filter(
                                        (data) =>
                                          currencyPair?.currency2 == data.asset
                                      ))[0]?.value)
                                  )
                                    ? (
                                      ((values.value * 1) /
                                        (cryptoCurrency?.filter(
                                          (data) => currencyPair?.currency1 == data.asset
                                        ))[0]?.value) /
                                      (currencyPair?.currency_type2 == "fiat"
                                        ? currencies.data?.forex_currencies?.filter(
                                          (data) =>
                                            data.currency_code ==
                                            currencyPair?.currency2
                                        )[0]?.exchange_rate
                                        : (cryptoCurrency?.filter(
                                          (data) =>
                                            currencyPair?.currency2 == data.asset
                                        ))[0]?.value)
                                    ).toFixed(8)
                                    : "0.0000"
                                  : "0.0000"
                              }
                              disabled={true}
                              placeholder="Min 0.0000001 BCT" />
                          </Form.Group>
                          <Form.Group
                            className="trade-percent-range mb-3"
                          >
                            <div className="range-label">
                              <span>0%</span>
                              <span>100%</span>
                            </div>

                            <Form.Range
                              disabled={
                                !singleWallet.data?.user_wallet?.remaining_original ||
                                singleWallet.data?.user_wallet?.remaining_original <= 0
                              }
                              min="0"
                              max="100"
                              value={rangeValue}
                              onChange={(e) => {
                                const data =
                                  (parseFloat(e.target.value) / 100) *
                                  (singleWallet.data?.user_wallet
                                    ? parseFloat(
                                      singleWallet.data?.user_wallet?.remaining_original
                                    )
                                    : 0);
                                setRangeValue(e.target.value);
                                setFieldValue("value", data.toFixed(8));
                              }}
                              onMouseOver={() => props.range(true)}
                              onMouseLeave={() => props.range(false)}
                            />
                            {/* <div className="trade-range-value">{rangeValue} % / 100 %</div> */}
                          </Form.Group>
                          <div className="trade-modal-action">
                            <div className="trade-bls-card trade-btn bls-trade">
                              Balance <span> {singleWallet.data &&
                                singleWallet.data?.user_wallet?.remaining
                                ? singleWallet.data &&
                                singleWallet.data?.user_wallet?.remaining
                                : "0.00"}</span>
                            </div>
                            {singleWallet.data?.user_wallet?.remaining_original > 0 ? (
                              <Button
                                className="trade-btn buy-trade"
                                type="submit"
                                disabled={tokenTradeTransaction.buttonDisable}
                              >
                                {tokenTradeTransaction.buttonDisable ? (
                                  <ButtonLoader />
                                ) : (
                                  "Place Order"
                                )}
                              </Button>
                            ) : <Button
                              onClick={() => {
                                navigate(
                                  `/wallet/${singleWallet.data?.user_wallet.currency_code}`
                                );
                              }}
                              className="trade-btn buy-trade"
                              disabled={singleWallet.buttonDisable}
                            >
                              {t("add_token")}
                            </Button>}
                          </div>
                        </FORM>
                      )}
                    </Formik>
                  </div>
                </div>}
            </Tab.Pane>
            <Tab.Pane eventKey="sell">
              {singleWallet.buttonDisable ?
                <div className="trade-modal-body">
                  <div className="trade-modal-type">
                    <div className="trade-modal-type-icons">
                      <Skeleton width={130} height={25} borderRadius={9} baseColor='#FFFFFF17' />
                    </div>
                  </div>
                  <div className="trade-modal-fields">
                    <Form.Group className="mb-5 position-relative" >
                      <Skeleton height={60} borderRadius={10} baseColor='#FFFFFF17' />
                    </Form.Group>
                    <Form.Group className="mb-4 position-relative" >
                      <Skeleton height={60} borderRadius={10} baseColor='#FFFFFF17' />
                    </Form.Group>
                    <Form.Group
                      className="trade-percent-range mb-3"
                    >
                      <Skeleton height={25} borderRadius={10} baseColor='#FFFFFF17' />
                    </Form.Group>
                    <div className="trade-modal-action">
                      <Skeleton height={60} borderRadius={10} baseColor='#FFFFFF17' />
                      <Skeleton height={60} borderRadius={10} baseColor='#FFFFFF17' />
                    </div>
                  </div>
                </div>
                :
                <div className="trade-modal-body">
                  <div className="trade-modal-type">
                    <div className="trade-modal-type-icons">
                      <Image
                        className="trade-modal-type-img"
                        src={
                          currencyPair?.image ||
                            window.location.origin +
                            "/img/pair-demo.png"
                        }
                        alt="Pair"
                      />
                    </div>
                    <div className="trade-modal-type-name">{currencyPair.currency1}/{currencyPair.currency2}</div>
                  </div>
                  <div className="trade-modal-fields">
                    <Formik
                      initialValues={{
                        value: "",
                        type: "sell",
                        pair_id: "",
                      }}
                      innerRef={formRef}
                      validationSchema={buySchema}
                      onSubmit={(values) => handleSubmit(values)}
                    >
                      {({ touched, errors, setFieldValue, values }) => (
                        <FORM>
                          <Form.Group className="mb-5 position-relative" >
                            <Form.Label>Quantity {currencyPair.currency1} <span>*</span></Form.Label>
                            <Field
                              type="text"
                              name="value"
                              disabled={
                                (!singleWallet.data?.user_wallet?.remaining_original) ||
                                (singleWallet.data?.user_wallet?.remaining_original <= 0)
                              }
                              onKeyDown={(e) => {
                                if (
                                  !/[0-9.]|Backspace|Tab/.test(e.key) &&
                                  !(
                                    (e.ctrlKey &&
                                      (e.key === "v" ||
                                        e.key === "c" ||
                                        e.key === "x" ||
                                        e.key === "a")) ||
                                    e.code == "Space" ||
                                    e.code == "ArrowRight" ||
                                    e.code == "ArrowLeft"
                                  )
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                const newValue = e.target.value;
                                const remainingOriginal =
                                  singleWallet.data?.user_wallet?.remaining_original || 0;

                                if (
                                  newValue <= parseFloat(remainingOriginal) ||
                                  (!localStorage.getItem("token") &&
                                    !sessionStorage.getItem("token"))
                                ) {
                                  setFieldValue("value", newValue);
                                  const percentage =
                                    (parseFloat(newValue) / remainingOriginal) * 100;
                                  setRangeValue(
                                    isNaN(percentage)
                                      ? 0
                                      : Math.min(percentage, 100).toFixed(2)
                                  );
                                } else {
                                  setFieldValue("value", values.value);
                                }
                              }}
                              className="form-control"
                              placeholder={t("you_pay.placeholder")}
                            />
                            <ErrorMessage
                              component={"div"}
                              name="value"
                              className="text-danger text-right"
                            />
                          </Form.Group>
                          <Form.Group className="mb-4 position-relative" >
                            <Form.Label>Price {currencyPair.currency2}  <span>*</span></Form.Label>
                            <Form.Control
                              type="text"
                              value={
                                values.value > 0
                                  ? !isNaN(
                                    (
                                      values.value *
                                      (cryptoCurrency?.filter(
                                        (data) => currencyPair?.currency1 == data.asset
                                      ))[0]?.value *
                                      (currencyPair?.currency_type2 == "fiat"
                                        ? currencies.data?.forex_currencies?.filter(
                                          (data) =>
                                            data.currency_code ==
                                            currencyPair?.currency2
                                        )[0]?.exchange_rate
                                        : (cryptoCurrency?.filter(
                                          (data) =>
                                            currencyPair?.currency2 == data.asset
                                        ))[0]?.value)
                                    ).toFixed(8)
                                  )
                                    ? (
                                      values.value *
                                      (cryptoCurrency?.filter(
                                        (data) => currencyPair?.currency1 == data.asset
                                      ))[0]?.value *
                                      (currencyPair?.currency_type2 == "fiat"
                                        ? currencies.data?.forex_currencies?.filter(
                                          (data) =>
                                            data.currency_code ==
                                            currencyPair?.currency2
                                        )[0]?.exchange_rate
                                        : (cryptoCurrency?.filter(
                                          (data) =>
                                            currencyPair?.currency2 == data.asset
                                        ))[0]?.value)
                                    ).toFixed(8)
                                    : "0.0000"
                                  : "0.0000"
                              }
                              disabled={true}
                              placeholder="Min 0.0000001 BCT"
                            />
                          </Form.Group>
                          <Form.Group
                            className="trade-percent-range mb-3"
                          >
                            <div className="range-label">
                              <span>0%</span>
                              <span>100%</span>
                            </div>
                            <Form.Range
                              min="0"
                              max="100"
                              disabled={
                                (!singleWallet.data?.user_wallet?.remaining_original) ||
                                (singleWallet.data?.user_wallet?.remaining_original <= 0)
                              }
                              value={rangeValue}
                              onChange={(e) => {
                                const data =
                                  (parseFloat(e.target.value) / 100) *
                                  (singleWallet.data?.user_wallet
                                    ? singleWallet.data?.user_wallet?.remaining_original
                                    : 0);
                                setRangeValue(e.target.value);
                                setFieldValue("value", data.toFixed(8));
                              }}
                              onMouseOver={() => props.range(true)}
                              onMouseLeave={() => props.range(false)}
                            />
                            {/* <div className="trade-range-value">{rangeValue} % / 100 %</div> */}
                          </Form.Group>
                          <div className="trade-modal-action">
                            <div className="trade-bls-card trade-btn bls-trade">
                              Balance <span> {singleWallet.data &&
                                singleWallet.data?.user_wallet?.remaining
                                ? singleWallet.data &&
                                singleWallet.data?.user_wallet?.remaining
                                : "0.00"}</span>
                            </div>
                            {singleWallet?.data.user_wallet?.remaining_original > 0 ? (
                              <Button
                                className="trade-btn sell-trade"
                                type="submit"
                                disabled={tokenTradeTransaction.buttonDisable}
                              >
                                {tokenTradeTransaction.buttonDisable ? (
                                  <ButtonLoader />
                                ) : (
                                  " Place Order"
                                )}
                              </Button>
                            ) :
                              <Button
                                onClick={() => {
                                  navigate(
                                    `/wallet/${singleWallet?.data?.user_wallet.currency_code}`
                                  );
                                }}
                                className="trade-btn sell-trade"
                                disabled={singleWallet.buttonDisable}
                              >
                                {t("add_token")}
                              </Button>
                            }
                          </div>
                        </FORM>
                      )}
                    </Formik>
                  </div>
                </div>}
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </>
  )
}

export default TradeModal;