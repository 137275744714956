import React, { useEffect, useState } from 'react';
import "../Trade/Trade.css"
import NewTradeNav from './TradeNav';
import NewTradeMain from './TradeMain';
import HeaderIndex from '../layouts/Header/HeaderIndex';
import TradeNavResponsive from './TradeNavResponsive';
import { useDispatch, useSelector } from 'react-redux';
import { profileStart } from '../../store/actions/UserAction';
import { set } from 'react-global-configuration';
import KYCUpdateModal from '../KYC/KYCUpdateModal';

const TradeIndex = () => {
  const [modal, setModal] = useState(false);
  const isMobile = useSelector((state) => state.user.isMobile);
  const kycApplicant = useSelector((state) => state.user.kycApplicant);
  const [skipRender, setSkipRender] = useState(true);
  const [kyc, setKyc] = useState(false);

  const dispatch = useDispatch()

  const [isLoggedIn] = useState(
    localStorage.getItem("token") || sessionStorage.getItem("token")
      ? true
      : false
  );

  const profile = useSelector((state) => state.user.profile)

  useEffect(() => {
    if (isLoggedIn && !Object.keys(profile.data).length > 0) {
      dispatch(profileStart())
    }
  }, [])

  useEffect(() => {
    if (
      !skipRender &&
      !kycApplicant.loading &&
      Object.keys(kycApplicant.data).length > 0
    ) {
      setKyc(kycApplicant.data)
    }
    setSkipRender(false);
  }, [kycApplicant]);
  
  return (
    <>
      <div className="trade-body">
        <HeaderIndex />
        <NewTradeNav
          setModal={(data) => setModal(data)}
        />
        {isMobile&& 
        <TradeNavResponsive/>
        }
        <NewTradeMain
          modal={modal}
          setModal={() => setModal(false)}
        />
      </div>
      {kyc && <KYCUpdateModal kyc={kyc} closeKyc={()=>setKyc(false)} />}
    </>
  )
}

export default TradeIndex;