import React, { useEffect } from "react";
import NewHomeBanner from "./HomeBanner";
import HowItsWork from "./HowItsWork";
import GrowthOfExchange from "./GrowthOfExchange";
import BestCryptoExchange from "./BestCryptoExchange";
import ExperienceCatalyzesGrowth from "./ExperienceCatalyzesGrowth";
import MostAdvantageAmongOthers from "./MostAdvantageAmongOthers";
import OurFeatures from "./OurFeatures";
import FutureOfFinance from "./FutureOfFinance";
import WhyChooseUs from "./WhyChooseUs";
import DemoOfProduct from "./DemoOfProduct";
import ContactUs from "./ContactUs";
import FAQIndex from "./FAQIndex";
// import AOS from "aos";
// import "aos/dist/aos.css";
import CryptoLiveData from "./CryptoLiveData";
import ExchangePlatform from "./ExchangePlatform";
import { Helmet } from "react-helmet";
import "../LandingPage/LandingPage.css"

const LandingIndex = (props) => {
  // useEffect(() => {
  //   AOS.init({
  //     // once: true,
  //     disable: "phone",
  //     duration: 700,
  //     easing: "ease-out-cubic",
  //     disable: 'mobile'
  //   });
  // }, []);
  return (
    <>
      {/* <Helmet>
        <script
          src='//in.fw-cdn.com/32218506/1186004.js'
          chat='true'>
        </script>
      </Helmet> */}
      <div className="new-landing-page-sec">
        <NewHomeBanner />
        <BestCryptoExchange />
        <ExchangePlatform />
        <HowItsWork />
        <ExperienceCatalyzesGrowth />
        <DemoOfProduct />
        <ContactUs />
        <FAQIndex />
      </div>
    </>
  );
};

export default LandingIndex;
