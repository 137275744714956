import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { profileStart } from "../../store/actions/UserAction";
import { useDispatch, useSelector } from "react-redux";
import SomethingWentWrong from "../Common/SomethingWentWrong";
import PageLoader from "../helper/PageLoader";

const AuthLayout = (props) => {
  const location = useLocation();
  const dispatch = useDispatch()

  const [isLoggedIn] = useState(
    localStorage.getItem("token") || sessionStorage.getItem("token")
      ? true
      : false
  );

  const profile = useSelector((state) => state.user.profile)

  useEffect(() => {
    if (isLoggedIn && !Object.keys(profile.data).length > 0) {
      dispatch(profileStart())
    }
  }, [])

  return (
    <>
      <div
        className={`${location.pathname == "/onboarding" ? "onboarding-layout" : "auth"
          }`}
      >
        {/* {profile.buttonDisable ? <PageLoader /> : (Object.keys(profile.data).length > 0 || !isLoggedIn) ? */}
        {props.children}
          {/* :
          <SomethingWentWrong />
        } */}
      </div>
    </>
  );
};

export default AuthLayout;
