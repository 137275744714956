import './App.css';
import React from 'react';
import Base from "./components/Base";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import store from "./store";
import { Provider } from "react-redux";
import 'react-loading-skeleton/dist/skeleton.css'

function App() {
  // const [configLoading, setConfigLoading] = useState(true);
  // const [configData, setConfigData] = useState(null);

  // useEffect(() => {
  //   fetchConfig();
  // }, []);

  // const fetchConfig = async () => {
  //   try {
  //     const response = await fetch(apiConstants.settingsUrl);
  //     const configValue = await response.json();
  //     setConfigData(configValue)
  //     configuration.set({ configData: configValue.data }, { freeze: false });
  //     setConfigLoading(false);
  //   } catch (error) {
  //     configuration.set({ configData: [] }, { freeze: false });
  //     setConfigLoading(false);
  //   }
  // };

  return (
    <Provider store={store}>
      {/* {configLoading ?
        <PageLoader />
        :
        configData !== null && configData.data && Object.keys(configData.data).length > 0 ? */}
          <Base /> 
          {/* :
          <SomethingWentWrong />

      } */}
    </Provider>
  );
}

export default App;
