import React, { useState, useEffect } from "react";
import {
  Modal
} from "react-bootstrap";
import SumsubWebSdk from "@sumsub/websdk-react";
import CenterLoader from "../helper/CommonCenterLoader";
import { updateKYCStart } from "../../store/actions/UserAction";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";

const KYCUpdateModal = (props) => {

  const t = useTranslation("transaction_list");
  const errorHandler = (data) => console.log("onError", data);
  const profile = useSelector((state) => state.user.profile);
  const accessTokenExpirationHandler = () => { };
  const [skip, setSkip] = useState(false);
  const [applicantId, setApplicantId] = useState(false);

  const options = {};
  const messageHandler = (type, payload) => {
    setApplicantId(payload?.applicantId);
    if (payload.reviewStatus !== "pending" && payload.reviewStatus !== undefined) {
      const { levelAutoCheckMode, ...data } = payload
      setTimeout(() => {
        (payload?.reviewResult?.reviewAnswer !== undefined && payload?.reviewResult?.reviewAnswer !== "RED") &&
          props.closeKyc()
      }, 2000)
      if (!skip) {
        (payload?.reviewResult?.reviewAnswer !== undefined && payload?.reviewResult?.reviewAnswer !== "RED") &&
          props.dispatch(
            updateKYCStart({ ...data, applicantId: applicantId })
          );
      }
    }
  };

  return (
    <>
      <Modal
        className="modal-dialog-center kyc-update-modal"
        size="md"
        centered
        show={props.kyc}
        onHide={props.closeKyc}
      >
        <Modal.Header closeButton>
          <Modal.Title>{profile.data.user_type == 1 ? t("kyc_update") : t("kyb_update")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.kycApplicant.loading ? (
            <div className="kyc-modal-loader-sec">
              <CenterLoader />
            </div>
          ) : Object.keys(props.kycApplicant.data).length > 0 ? (
            <SumsubWebSdk
              accessToken={props.kycApplicant.data.access_token}
              expirationHandler={accessTokenExpirationHandler}
              config={{ "email": props.kycApplicant.data.email }}
              // options={options}
              onMessage={messageHandler}
              onError={errorHandler}

            />
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  kycApplicant: state.user.kycApplicant,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(
  mapStateToPros,
  mapDispatchToProps
)(KYCUpdateModal);
