import React, {  useEffect, } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import MainLayout from "../layouts/MainLayout";
import AuthLayout from "../layouts/AuthLayout";
import { ToastContainer } from "react-toastify";
import ExchangeIndex from "../Exchange/ExchangeIndex";
import {
  setTranslations,
  setDefaultLanguage,
  setLanguage,
} from "react-multi-lang";
import en from "../translations/en.json";
import es from "../translations/es.json";
import BuyIndex from "../Buy/BuyIndex";
import SwapIndex from "../Sell/SellIndex";
import LoginIndex from "../Auth/LoginIndex";
import RegisterIndex from "../Auth/RegisterIndex";
import ProfileIndex from "../Profile/ProfileIndex";
import NewLandingIndex from "../LandingPage/LandingIndex";
import LandingLayout from "../layouts/LandingLayout";
import EmptyLayout from "../layouts/EmptyLayout";
import ScrollToTop from "../helper/ScrollToTop";
import ContactUsPageIndex from "../LandingPage/ContactUsPageIndex";
import OnboardingIndex from "../Onboarding/OnboardingIndex";
import PageNotFound from "../helper/PageNotFound";
import TermAndCondition from "../Static/NewStaticPage/TermAndCondition";
import AntiMoneyLaundering from "../Static/NewStaticPage/AntiMoneyLaundering";
import RiskDisclosurePolicy from "../Static/NewStaticPage/RiskDisclosurePolicy";
import UserAgreement from "../Static/NewStaticPage/UserAgreement";
import PrivacyAgreement from "../Static/NewStaticPage/PrivacyAgreement";
import Help from "../Static/NewStaticPage/Help";
import AboutUs from "../Static/NewStaticPage/AboutUs";
// import ContactUs from "../Static/NewStaticPage/ContactUs";
import StaticContactUs from "../Static/NewStaticPage/StaticContactUs";
import ThankYou from "../helper/ThankYou";
import { useSelector } from "react-redux";
import metaData from "../helper/metaData.json"
import TradeIndex from "../Trade/TradeIndex";
import NewLayout from "../layouts/NewLayout";
import NewWalletIndex from "../Wallet/WalletIndex";
import NewWalletDetails from "../Wallet/WalletDetails";
import NewTransactionIndex from "../Transaction/TransactionIndex";
import BankIndex from "../BankDetails/BankIndex";
import AddBank from "../BankDetails/AddBank";
import MarketingIndex from "../LandingPage/Marketing";

setTranslations({ en, es });

const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Layout screenProps={ScreenProps} {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => {
  let userId =
    localStorage.getItem("userId") || sessionStorage.getItem("userId");
  let accessToken =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  let authentication = accessToken && userId ? true : false;
  return authentication === true ? (
    <Layout screenProps={ScreenProps}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
};

const App = () => {
  const location = useSelector((state) => state.user.location);

  useEffect(() => {
    const userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setDefaultLanguage(userLanguage);
  }, []);

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{metaData[location == "/" ? "home" : location?.split("/")[1]]?.title}</title>
        <link
          rel="icon"
          type="image/png"
          href={window.location.origin + "/logo192.png"}
        />
        {/* <script src={configuration.get("configData.head_scripts")}></script> */}
        {/* <script
          src='//in.fw-cdn.com/32218506/1186004.js'
          chat='true'>
        </script> */}
      </Helmet>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route
            path={"/"}
            element={
              <AppRoute component={NewLandingIndex} layout={LandingLayout} />
            }
          />
          <Route
            path={"/buy"}
            element={<AppRoute component={BuyIndex} layout={MainLayout} />}
          />
          <Route
            path={"/sell"}
            element={<AppRoute component={SwapIndex} layout={MainLayout} />}
          />
          <Route
            path={"/exchange"}
            element={<AppRoute component={ExchangeIndex} layout={MainLayout} />}
          />
          <Route
            path={"/terms-conditions"}
            element={
              <AppRoute component={TermAndCondition} layout={MainLayout} />
            }
          />
          <Route
            path={"/anti-money-laundering"}
            element={
              <AppRoute component={AntiMoneyLaundering} layout={MainLayout} />
            }
          />
          <Route
            path={"/risk-disclosure-policy"}
            element={
              <AppRoute component={RiskDisclosurePolicy} layout={MainLayout} />
            }
          />
          <Route
            path={"/user-agreement"}
            element={
              <AppRoute component={UserAgreement} layout={MainLayout} />
            }
          />
          <Route
            path={"/privacy-agreement"}
            element={
              <AppRoute component={PrivacyAgreement} layout={MainLayout} />
            }
          />
          <Route
            path={"/thank-you"}
            element={
              <AppRoute component={ThankYou} layout={MainLayout} />
            }
          />
          <Route
            path={"/help-center"}
            element={
              <AppRoute component={Help} layout={MainLayout} />
            }
          />
          <Route
            path={"/about-us"}
            element={
              <AppRoute component={AboutUs} layout={MainLayout} />
            }
          />
          <Route
            path={"/contact-us"}
            element={
              <AppRoute component={StaticContactUs} layout={MainLayout} />
            } />
          <Route
            path={"/login"}
            element={<AppRoute component={LoginIndex} layout={AuthLayout} />}
          />
          <Route
            path={"/register"}
            element={<AppRoute component={RegisterIndex} layout={AuthLayout} />}
          />
          <Route
            path={"/profile"}
            element={
              <PrivateRoute component={ProfileIndex} layout={NewLayout} />
            }
          />
          <Route
            path={"/wallet-currency-list"}
            element={
              <PrivateRoute component={NewWalletIndex} layout={NewLayout} />
            }
          />
           <Route
            path={"/wallet/:user_wallet_id"}
            element={
              <PrivateRoute component={NewWalletDetails} layout={NewLayout} />
            }
          />
           <Route
            path={"/transactions"}
            element={
              <PrivateRoute component={NewTransactionIndex} layout={NewLayout} />
            }
          />
           <Route
            path={"/recipient-list"}
            element={
              <PrivateRoute component={BankIndex} layout={NewLayout} />
            }
          />
          <Route
            path={"/create-personal-recipient"}
            element={
              <PrivateRoute
                component={AddBank}
                layout={NewLayout}
              />
            }
          />
          <Route
            path={"/marketing"}
            element={
              <AppRoute
                component={MarketingIndex}
                layout={EmptyLayout}
              />
            }
          />
          <Route
            path={"/contact-us"}
            element={
              <AppRoute component={ContactUsPageIndex} layout={LandingLayout} />
            }
          />
          <Route
            path={"/onboarding"}
            element={
              <PrivateRoute component={OnboardingIndex} layout={AuthLayout} />
            }
          />
          <Route
            path={"/trading"}
            element={<AppRoute component={TradeIndex} layout={EmptyLayout} />}
          />
          <Route
            path={"/*"}
            element={<AppRoute component={PageNotFound} layout={EmptyLayout} />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
