import React, { useEffect, useState } from 'react';
import "./Wallet.css";
import { Table, Tab, Nav, Button, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWalletDetailsStart } from '../../store/actions/WalletAction';
import { getCurrenciesListStart } from '../../store/actions/BuyAction';
import { useTranslation } from 'react-multi-lang';
import Skeleton from 'react-loading-skeleton';
import NoDataFound from '../helper/NoDataFound';
import SwapCryptoModal from '../Modals/SwapCryptoModal';
import WithdrawCryptoModal from '../Modals/WithdrawCryptoModal';
import WalletWithdrawModal from '../Modals/WalletWithdrawModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DepositCryptoModal from '../Modals/DepositCryptoModal';
import DepositFiatModal from '../Modals/DepositFiatModal';
import SwapFiatModal from '../Modals/SwapFiatModal';
import { Tooltip } from 'react-tooltip'

const WalletIndex = () => {
    const t = useTranslation("wallet_main");
    const navigate = useNavigate();

    const currencies = useSelector((state) => state.buy.currencies);
    const profile = useSelector(state => state.user.profile)
    const wallet = useSelector((state) => state.wallet.walletData,);
    const cryptoCurrency = useSelector((state) => state.buy.cryptoCurrency);
    const dispatch = useDispatch()

    const [modalShow, setModalShow] = useState(false);
    const [cryptoModalShow, setCryptoModalShow] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams("");
    const [depositCryptoModal, setDepositCryptoModal] = useState(false);
    const [depositFiatModal, setDepositFiatModal] = useState(false);
    const [singleWallet, setSingleWallet] = useState(false);
    const [tokenType, setTokenType] = useState("");
    const [swapModalNew, setSwapModalNew] = useState(false);

    useEffect(() => {
        dispatch(getCurrenciesListStart({ user_id: profile.data?.user_id ? profile.data?.user_id : "" }))
    }, []);

    useEffect(() => {
        dispatch(fetchWalletDetailsStart());
    }, []);

    return (
        <>
            <div className="wallet-wrapped">
                <div className="wallet-section-titles">
                    <h2>Wallet</h2>
                </div>
                <div className="wallet-content-wrap">
                    <div className="wallet-tabs-section">
                        <Tab.Container id="wallet-tabs" defaultActiveKey="crypto" >
                            <div className="tab-nav-box">
                            <Nav variant="pills" className="wallet-frame"
                                onClick={() => dispatch(fetchWalletDetailsStart())}
                            >
                                <Nav.Item>
                                    <Nav.Link eventKey="crypto">Crypto Wallets</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="fiat">Fiat Wallets</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <p className="note-info">
                                    <span className="text-danger text bold">Note:</span>
                                   If wallet balance is 0, you can't swap or withdraw.
                                </p>
                                </div>
                            <Tab.Content>
                                <Tab.Pane eventKey="crypto">
                                    <div className="wallet-content-body">
                                        <Table responsive>
                                            {wallet.loading ? (
                                                [...Array(4)].map((i, key) => (
                                                    <Skeleton key={key} height={70} />
                                                ))
                                            ) : (Object.keys(wallet.data).length > 0 &&
                                                Object.keys(currencies.data).length > 0 &&
                                                wallet.data.user_wallets.length > 0) ? (
                                                <>
                                                    <thead>
                                                        <tr>
                                                            <th>Tokens</th>
                                                            <th>Balance</th>
                                                            <th>Swap</th>
                                                            <th>Withdraw</th>
                                                            <th>Deposit</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {wallet.data.user_wallets.map(
                                                            (wallet, i) =>
                                                                wallet.currency_type == "crypto" && (
                                                                    <tr>
                                                                        <td>
                                                                            <div className="wallet-info-td-card">
                                                                                <div className="td-card-icons">
                                                                                    <Image
                                                                                        className="td-card_img"
                                                                                        src={
                                                                                            wallet.currency_picture ? wallet.currency_picture :
                                                                                                window.location.origin +
                                                                                                "/img/phase2/bitcoin.png"
                                                                                        }
                                                                                        alt="Auth Otp"
                                                                                    />
                                                                                </div>
                                                                                <div className="td-card-info">{wallet.currency_code}</div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="td-value-card">
                                                                                {wallet.remaining}
                                                                                <span>
                                                                                    {`${!isNaN(
                                                                                        (
                                                                                            cryptoCurrency.find(
                                                                                                (data) =>
                                                                                                    data.asset ==
                                                                                                    wallet.currency_code
                                                                                            )?.value * wallet.remaining_original
                                                                                        ).toFixed(5)
                                                                                    )
                                                                                        ? (
                                                                                            cryptoCurrency.find(
                                                                                                (data) =>
                                                                                                    data.asset ==
                                                                                                    wallet.currency_code
                                                                                            )?.value *
                                                                                            wallet.remaining_original
                                                                                        ).toFixed(5)
                                                                                        : "0.00000"
                                                                                        } USD`}
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                                                                                                            <Button
                                                                                    onClick={() => {
                                                                                        setSingleWallet(wallet)
                                                                                        setSwapModalNew("crypto")
                                                                                    }}
                                                                                    disabled={
                                                                                        wallet.remaining_original ==
                                                                                        0
                                                                                    }
                                                                                    data-tooltip-id="my-tooltip"
                                                                                    data-tooltip-content="Swap"
                                                                                    data-tooltip-place="top"

                                                                                >
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        id="Layer_1"
                                                                                        width="24"
                                                                                        height="24"
                                                                                        data-name="Layer 1"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="#878e96"
                                                                                    >
                                                                                        <path d="M24 12c0 4.411-3.589 8-8 8H3l2.5 2.5-1.414 1.414-3.5-3.5a2 2 0 0 1 0-2.828l3.5-3.5L5.5 15.5 3 18h13c3.309 0 6-2.691 6-6zM8 6h13l-2.5 2.5 1.414 1.414 3.5-3.5c.78-.779.78-2.049 0-2.828l-3.5-3.5L18.5 1.5 21 4H8c-4.411 0-8 3.589-8 8h2c0-3.309 2.691-6 6-6"></path>
                                                                                    </svg>

                                                                                </Button>
                                                                        </td>
                                                                        <td>
                                                                            {wallet.is_withdraw_allowed == 1 ? (
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        setSingleWallet(wallet)
                                                                                        setCryptoModalShow(
                                                                                            wallet.remaining_original
                                                                                        )
                                                                                    }}
                                                                                    disabled={
                                                                                        wallet?.remaining_original == 0 ||
                                                                                        profile.data.kyc_verified == 0
                                                                                    }
                                                                                    data-tooltip-id="my-tooltip"
                                                                                    data-tooltip-content="Withdraw"
                                                                                    data-tooltip-place="top"
                                                                                >
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        id="Layer_1"
                                                                                        width="20"
                                                                                        height="20"
                                                                                        data-name="Layer 1"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="#878e96"
                                                                                    >
                                                                                        <path d="M20 0h-9a1 1 0 1 0 0 2h9q.269.001.518.068L.293 22.293a.999.999 0 1 0 1.414 1.414L21.932 3.482q.067.248.068.518v9a1 1 0 1 0 2 0V4c0-2.206-1.794-4-4-4"></path>
                                                                                    </svg>
                                                                                </Button>) : "N/A"}
                                                                        </td>
                                                                        <td>
                                                                            {wallet.is_deposit_allowed == 1 ?
                                                                                <Button
                                                                                    disabled={profile.data.kyc_verified == 0}
                                                                                    onClick={() => {
                                                                                        setSingleWallet(wallet)
                                                                                        setTokenType(
                                                                                            wallet.currency_code
                                                                                        );
                                                                                        setDepositCryptoModal(wallet);
                                                                                    }}
                                                                                    data-tooltip-id="my-tooltip"
                                                                                    data-tooltip-content="Deposit"
                                                                                    data-tooltip-place="top"

                                                                                >
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        id="Layer_1"
                                                                                        width="20"
                                                                                        height="20"
                                                                                        data-name="Layer 1"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="#878e96"
                                                                                        style={{ transform: "rotate(90deg)" }}
                                                                                    >
                                                                                        <path d="M20 0h-9a1 1 0 1 0 0 2h9q.269.001.518.068L.293 22.293a.999.999 0 1 0 1.414 1.414L21.932 3.482q.067.248.068.518v9a1 1 0 1 0 2 0V4c0-2.206-1.794-4-4-4"></path>
                                                                                    </svg>
                                                                                </Button> : "N/A"}
                                                                        </td>
                                                                        <td>
                                                                            <Button
                                                                                onClick={() => { navigate(`/wallet/${wallet.currency_code}`) }}
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content="View"
                                                                                data-tooltip-place="top"
                                                                            >
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    id="Outline"
                                                                                    width="24"
                                                                                    height="24"
                                                                                    data-name="Layer 1"
                                                                                    viewBox="0 0 24 24"
                                                                                    fill="#000"
                                                                                >
                                                                                    <path d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.91 4.91 0 0 0 0 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.91 4.91 0 0 0 0-5.162m-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.92 2.92 0 0 1 0-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.92 2.92 0 0 1 0 3.068"></path>
                                                                                    <path d="M12 7a5 5 0 1 0 5 5 5.006 5.006 0 0 0-5-5m0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3"></path>
                                                                                </svg>
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                    </tbody>
                                                </>
                                            ) : <NoDataFound />}
                                        </Table>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fiat">
                                    <div className="wallet-content-body">
                                        <Table responsive>
                                            {wallet.loading ? (
                                                [...Array(4)].map((i, key) => (
                                                    <Skeleton key={key} height={70} />
                                                ))
                                            ) : (Object.keys(wallet.data).length > 0 &&
                                                Object.keys(currencies.data).length > 0 &&
                                                wallet.data.user_wallets.length > 0) ? (
                                                <>
                                                    <thead>
                                                        <tr>
                                                            <th>Tokens</th>
                                                            <th>Balance</th>
                                                            <th>Swap</th>
                                                            <th>Withdraw</th>
                                                            <th>Deposit</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {wallet.data.user_wallets.map(
                                                            (wallet, i) =>
                                                                wallet.currency_type == "forex" && (
                                                                    <tr>
                                                                        <td>
                                                                            <div className="wallet-info-td-card">
                                                                                <div className="td-card-icons">
                                                                                    <Image
                                                                                        className="td-card_img"
                                                                                        src={
                                                                                            wallet.currency_picture ? wallet.currency_picture :
                                                                                                window.location.origin +
                                                                                                "/img/phase2/bitcoin.png"
                                                                                        }
                                                                                        alt="Auth Otp"
                                                                                    />
                                                                                </div>
                                                                                <div className="td-card-info">{wallet.currency_code}</div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="td-value-card">
                                                                                {wallet.remaining}
                                                                                <span>
                                                                                    {`${!isNaN((wallet.remaining_original /
                                                                                        currencies?.data?.forex_currencies
                                                                                            .find(
                                                                                                (data) =>
                                                                                                    data.currency_code ==
                                                                                                    wallet.currency_code
                                                                                            )
                                                                                            ?.exchange_rate).toFixed(5)) ?
                                                                                        (wallet.remaining_original /
                                                                                            currencies?.data?.forex_currencies
                                                                                                .find(
                                                                                                    (data) =>
                                                                                                        data.currency_code ==
                                                                                                        wallet.currency_code
                                                                                                )
                                                                                                ?.exchange_rate).toFixed(5) : "0.0000"
                                                                                        } USD`}
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        setSingleWallet(wallet)
                                                                                        setSwapModalNew("forex")
                                                                                    }}
                                                                                    disabled={
                                                                                        wallet.remaining_original ==
                                                                                        0
                                                                                    }
                                                                                    data-tooltip-id="my-tooltip"
                                                                                    data-tooltip-content="Swap"
                                                                                    data-tooltip-place="top"
                                                                                >  <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    id="Layer_1"
                                                                                    width="24"
                                                                                    height="24"
                                                                                    data-name="Layer 1"
                                                                                    viewBox="0 0 24 24"
                                                                                    fill="#878e96"
                                                                                >
                                                                                        <path d="M24 12c0 4.411-3.589 8-8 8H3l2.5 2.5-1.414 1.414-3.5-3.5a2 2 0 0 1 0-2.828l3.5-3.5L5.5 15.5 3 18h13c3.309 0 6-2.691 6-6zM8 6h13l-2.5 2.5 1.414 1.414 3.5-3.5c.78-.779.78-2.049 0-2.828l-3.5-3.5L18.5 1.5 21 4H8c-4.411 0-8 3.589-8 8h2c0-3.309 2.691-6 6-6"></path>
                                                                                    </svg>
                                                                                </Button>
                                                                        </td>
                                                                        <td>
                                                                            {wallet.is_withdraw_allowed == 1 ? (
                                                                                <Button
                                                                                    onClick={() => {
                                                                                        setSingleWallet(wallet)
                                                                                        setModalShow(Number(wallet.remaining_original))
                                                                                    }}
                                                                                    disabled={
                                                                                        wallet.remaining_original == 0 ||
                                                                                        profile.data.kyc_verified == 0
                                                                                    }
                                                                                    data-tooltip-id="my-tooltip"
                                                                                    data-tooltip-content="Withdraw"
                                                                                    data-tooltip-place="top"
                                                                                > <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    id="Layer_1"
                                                                                    width="20"
                                                                                    height="20"
                                                                                    data-name="Layer 1"
                                                                                    viewBox="0 0 24 24"
                                                                                    fill="#878e96"
                                                                                >
                                                                                        <path d="M20 0h-9a1 1 0 1 0 0 2h9q.269.001.518.068L.293 22.293a.999.999 0 1 0 1.414 1.414L21.932 3.482q.067.248.068.518v9a1 1 0 1 0 2 0V4c0-2.206-1.794-4-4-4"></path>
                                                                                    </svg>
                                                                                </Button>) : "N/A"}
                                                                        </td>
                                                                        <td>
                                                                            {wallet.is_deposit_allowed == 1 ?
                                                                                <Button
                                                                                    disabled={profile.data.kyc_verified == 0}
                                                                                    onClick={() => {
                                                                                        setSingleWallet(wallet)
                                                                                        setTokenType(
                                                                                            wallet.currency_code
                                                                                        );
                                                                                        setDepositFiatModal(wallet);
                                                                                    }}
                                                                                    data-tooltip-id="my-tooltip"
                                                                                    data-tooltip-content="Deposit"
                                                                                    data-tooltip-place="top"
                                                                                >
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        id="Layer_1"
                                                                                        width="20"
                                                                                        height="20"
                                                                                        data-name="Layer 1"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="#878e96"
                                                                                        style={{ transform: "rotate(90deg)" }}
                                                                                    >
                                                                                        <path d="M20 0h-9a1 1 0 1 0 0 2h9q.269.001.518.068L.293 22.293a.999.999 0 1 0 1.414 1.414L21.932 3.482q.067.248.068.518v9a1 1 0 1 0 2 0V4c0-2.206-1.794-4-4-4"></path>
                                                                                    </svg>

                                                                                </Button> : "N/A"}
                                                                        </td>
                                                                        <td>
                                                                            <Button
                                                                                onClick={() => { navigate(`/wallet/${wallet.currency_code}`) }}
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content="View"
                                                                                data-tooltip-place="top"
                                                                            >
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    id="Outline"
                                                                                    width="24"
                                                                                    height="24"
                                                                                    data-name="Layer 1"
                                                                                    viewBox="0 0 24 24"
                                                                                    fill="#000"
                                                                                >
                                                                                    <path d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.91 4.91 0 0 0 0 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.91 4.91 0 0 0 0-5.162m-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.92 2.92 0 0 1 0-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.92 2.92 0 0 1 0 3.068"></path>
                                                                                    <path d="M12 7a5 5 0 1 0 5 5 5.006 5.006 0 0 0-5-5m0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3"></path>
                                                                                </svg>
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                    </tbody>
                                                </>
                                            ) : <NoDataFound />}
                                        </Table>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
            {modalShow && (
                <WalletWithdrawModal
                    show={modalShow}
                    user_wallet_id={singleWallet.id}
                    currency={singleWallet.currency_code}
                    amount={singleWallet.remaining}
                    currencyCode={singleWallet.currency_code}
                    wallet={wallet.data}                       ////need to pass single wallet data ask backend
                    onHide={() => setModalShow(false)}
                    currency_type={singleWallet.currency_type}
                />
            )}
            {depositFiatModal && (
                <DepositFiatModal
                    show={depositFiatModal}
                    token={singleWallet?.currency_code}
                    tokenType={tokenType}
                    user_wallet_id={singleWallet?.id}
                    onHide={() => setDepositFiatModal(false)}
                />
            )}
            {depositCryptoModal && (
                <DepositCryptoModal
                    show={depositCryptoModal}
                    token={singleWallet?.currency_code}
                    tokenType={tokenType}
                    user_wallet_id={singleWallet?.id}
                    onHide={() => setDepositCryptoModal(false)}
                />
            )}
            {cryptoModalShow && (
                <WithdrawCryptoModal
                    show={cryptoModalShow}
                    user_wallet_id={singleWallet.id}
                    amount={singleWallet.remaining}
                    currencyCode={singleWallet.currency_code}
                    onHide={() => setCryptoModalShow(false)}
                    currency_type={singleWallet.currency_type}
                />
            )}
            {swapModalNew == "crypto" && (
                <SwapCryptoModal
                    show={swapModalNew}
                    onHide={() => setSwapModalNew(false)}
                    singleWallet={singleWallet}
                />
            )}
            {swapModalNew == "forex" && (
                <SwapFiatModal
                    show={swapModalNew}
                    onHide={() => setSwapModalNew(false)}
                    singleWallet={singleWallet}
                />
            )}
            <Tooltip id="my-tooltip" />
        </>
    )
}

export default WalletIndex;