import React, { useState, useMemo, useRef, useEffect } from "react";
import { Form, Button, Image, Row, Col, Container, Offcanvas } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { useNavigate, Link } from "react-router-dom";
import PhoneInput, {
    formatPhoneNumberIntl,
    isValidPhoneNumber,
    isPossiblePhoneNumber,
} from "react-phone-number-input";
import { useTranslation } from "react-multi-lang";
import * as Yup from "yup";
import {
    createRecipientStart,
    mobileCodesListStart,
} from "../../store/actions/RecipientAction";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import { ButtonLoader } from "../helper/Loader";
import {
    getSupportedCountriesStart,
    purposeOfPaymentStart,
} from "../../store/actions/BuyAction";
import api from "../Environment";
import { AsyncPaginate } from "react-select-async-paginate";
import Skeleton from "react-loading-skeleton";
import stringSimilarity from "string-similarity"
import { getErrorNotificationMessage } from "../helper/ToastNotification";
import { countriesListStart } from "../../store/actions/UserAction";

const codeValidationRules = {
    AND: {
        min: 24,
        max: 24,
        regex: /^AD\d{22}$/, // Andorra: 24 characters starting with AD followed by 22 digits
        message: "Invalid AND code",
    },
    AUS: {
        min: 8,
        max: 11,
        regex: /^[A-Z]{4}AU.{2}.*$/,
        message: "Invalid AUS code",
    },
    AUT: {
        min: 20,
        max: 20,
        regex: /^AT\d{18}$/, // Austria: 20 characters starting with AT followed by 18 digits
        message: "Invalid AUT code",
    },
    BEL: {
        min: 16,
        max: 16,
        regex: /^BE\d{14}$/, // Belgium: 16 characters starting with BE followed by 14 digits
        message: "Invalid BEL code",
    },
    BGD: {
        min: 8,
        max: 9,
        regex: /^\d{8,9}$/, // Bangladesh: 8 to 9 digits only
        message: "Invalid BGD code",
    },
    BGR: {
        min: 22,
        max: 22,
        regex: /^BG\d{2}[A-Z]{4}\d{4}[A-Z0-9]{10}$/, // Bulgaria: 22 characters with specific format
        message: "Invalid BGR code",
    },
    CHE: {
        min: 21,
        max: 21,
        regex: /^CH\d{19}$/, // Switzerland: 21 characters starting with CH followed by 19 digits
        message: "Invalid CHE code",
    },
    CHN: {
        min: 8,
        max: 11,
        regex: /^[A-Z]{4}CN.{2}.*$/,
        message: "Invalid CHN code",
    },
    CYP: {
        min: 28,
        max: 28,
        regex: /^CY\d{10}[A-Z0-9]{16}$/, // Cyprus: 28 characters starting with CY, 10 digits, and 16 alphanumeric
        message: "Invalid CYP code",
    },
    CZE: {
        min: 24,
        max: 24,
        regex: /^CZ\d{22}$/, // Czech Republic: 24 characters starting with CZ followed by 22 digits
        message: "Invalid CZE code",
    },
    DEU: {
        min: 22,
        max: 22,
        regex: /^DE\d{20}$/, // Germany: 22 characters starting with DE followed by 20 digits
        message: "Invalid DEU code",
    },
    DNK: {
        min: 18,
        max: 18,
        regex: /^DK\d{16}$/, // Denmark: 18 characters starting with DK followed by 16 digits
        message: "Invalid DNK code",
    },
    ESP: {
        min: 24,
        max: 24,
        regex: /^ES\d{22}$/, // Spain: 24 characters starting with ES followed by 22 digits
        message: "Invalid ESP code",
    },
    EST: {
        min: 20,
        max: 20,
        regex: /^EE\d{18}$/, // Estonia: 20 characters starting with EE followed by 18 digits
        message: "Invalid EST code",
    },
    FIN: {
        min: 18,
        max: 18,
        regex: /^FI\d{16}$/, // Finland: 18 characters starting with FI followed by 16 digits
        message: "Invalid FIN code",
    },
    FRA: {
        min: 27,
        max: 27,
        regex: /^FR\d{12}[A-Z0-9]{11}\d{2}$/, // France: 27 characters with specific format
        message: "Invalid FRA code",
    },
    GBR: {
        min: 22,
        max: 22,
        regex: /^GB\d{2}[A-Z]{4}\d{14}$/, // UK: 22 characters starting with GB, 4 letters, 14 digits
        message: "Invalid GBR code",
    },
    GRC: {
        min: 27,
        max: 27,
        regex: /^GR\d{9}[A-Z0-9]{16}$/, // Greece: 27 characters with specific format
        message: "Invalid GRC code",
    },
    HKG: {
        min: 3,
        max: 3,
        regex: /^\d{3}$/, // Hong Kong: 3 digits only
        message: "Invalid HKG code",
    },
    HRV: {
        min: 21,
        max: 21,
        regex: /^HR\d{19}$/, // Croatia: 21 characters starting with HR followed by 19 digits
        message: "Invalid HRV code",
    },
    HUN: {
        min: 28,
        max: 28,
        regex: /^HU\d{26}$/, // Hungary: 28 characters starting with HU followed by 26 digits
        message: "Invalid HUN code",
    },
    IDN: {
        min: 8,
        max: 11,
        regex: /^[A-Z]{4}ID\d{2}\d*$/, // Indonesia: 8 to 11 characters starting with 4 letters followed by "ID", 2 digits, and more digits
        message: "Invalid IDN code",
    },
    IND: {
        min: 11,
        max: 11,
        regex: /^[A-Z]{4}0[A-Z0-9]{6}$/, // India: 11 characters with specific format
        message: "Invalid IND code",
    },
    IRL: {
        min: 22,
        max: 22,
        regex: /^IE\d{2}[A-Z]{4}\d{14}$/, // Ireland: 22 characters starting with IE, 4 letters, 14 digits
        message: "Invalid IRL code",
    },
    ISL: {
        min: 26,
        max: 26,
        regex: /^IS\d{24}$/, // Iceland: 26 characters starting with IS followed by 24 digits
        message: "Invalid ISL code",
    },
    ITA: {
        min: 27,
        max: 27,
        regex: /^IT\d{2}[A-Z]\d{10}[A-Z0-9]{12}$/, // Italy: 27 characters with specific format
        message: "Invalid ITA code",
    },
    JPN: {
        min: 8,
        max: 11,
        // regex: /^\d{7}$/, // Japan: 7 digits only
        regex: /^[A-Z]{4}JP.{2}.*$/,
        message: "Invalid JPN code",
    },
    LKA: {
        min: 8,
        max: 11,
        regex: /^[A-Z]{4}LK\d{2}\d*$/, // Sri Lanka: 8 to 11 characters with specific format
        message: "Invalid LKA code",
    },
    LTU: {
        min: 20,
        max: 20,
        regex: /^LT\d{18}$/, // Lithuania: 20 characters starting with LT followed by 18 digits
        message: "Invalid LTU code",
    },
    LUX: {
        min: 20,
        max: 20,
        regex: /^LU\d{5}[A-Z0-9]{13}$/, // Luxembourg: 20 characters with specific format
        message: "Invalid LUX code",
    },
    MCO: {
        min: 27,
        max: 27,
        regex: /^MC\d{25}$/, // Monaco: 27 characters starting with MC followed by 25 digits
        message: "Invalid MCO code",
    },
    MLT: {
        min: 31,
        max: 31,
        regex: /^MT\d{2}[A-Z]{4}\d{5}[A-Z0-9]{18}$/, // Malta: 31 characters with specific format
        message: "Invalid MLT code",
    },
    MYS: {
        min: 8,
        max: 11,
        regex: /^[A-Z]{4}MY\d{2}\d*$/, // Malaysia: 8 to 11 characters with specific format
        message: "Invalid MYS code",
    },
    NLD: {
        min: 18,
        max: 18,
        regex: /^NL\d{2}[A-Z]{4}\d{10}$/, // Netherlands: 18 characters with specific format
        message: "Invalid NLD code",
    },
    NOR: {
        min: 15,
        max: 15,
        regex: /^NO\d{13}$/, // Norway: 15 characters starting with NO followed by 13 digits
        message: "Invalid NOR code",
    },
    PHL: {
        min: 8,
        max: 11,
        regex: /^[A-Z]{4}PH.{2}.*$/, // Philippines: 8 to 11 characters with specific format
        message: "Invalid PHL code",
    },
    POL: {
        min: 28,
        max: 28,
        regex: /^PL\d{10}[A-Z0-9]{16}$/, // Poland: 28 characters with specific format
        message: "Invalid POL code",
    },
    PRT: {
        min: 25,
        max: 25,
        regex: /^PT\d{23}$/, // Portugal: 25 characters starting with PT followed by 23 digits
        message: "Invalid PRT code",
    },
    ROU: {
        min: 24,
        max: 24,
        regex: /^RO\d{2}[A-Z]{4}[A-Z0-9]{16}$/, // Romania: 24 characters with specific format
        message: "Invalid ROU code",
    },
    SMR: {
        min: 27,
        max: 27,
        regex: /^SM\d{2}[A-Z]\d{22}$/, // San Marino: 27 characters with specific format
        message: "Invalid SMR code",
    },
    SVK: {
        min: 24,
        max: 24,
        regex: /^SK\d{22}$/, // Slovakia: 24 characters starting with SK followed by 22 digits
        message: "Invalid SVK code",
    },
    SVN: {
        min: 19,
        max: 19,
        regex: /^SI\d{17}$/, // Slovenia: 19 characters starting with SI followed by 17 digits
        message: "Invalid SVN code",
    },
    SWE: {
        min: 24,
        max: 24,
        regex: /^SE\d{22}$/, // Sweden: 24 characters starting with SE followed by 22 digits
        message: "Invalid SWE code",
    },
    THA: {
        min: 8,
        max: 11,
        regex: /^[A-Z]{4}TH\d{2}\d*$/, // Thailand: 8 to 11 characters with specific format
        message: "Invalid THA code",
    },
    USA: {
        min: 9,
        max: 9,
        regex: /^\d{9}$/, // USA: 8 to 11 characters with specific format
        message: "Invalid USA code",
    },
    VAT: {
        min: 22,
        max: 22,
        regex: /^VA\d{20}$/, // Vatican City: 22 characters starting with VA followed by 20 digits
        message: "Invalid VAT code",
    },
    VNM: {
        min: 8,
        max: 11,
        regex: /^[A-Z]{4}VN.{2}.*$/, // Vietnam: 8 to 11 characters with specific format
        message: "Invalid VNM code",
    },
};
const AddBank = (props) => {
    const t = useTranslation("recipient");
    const dispatch = useDispatch();
    const mobileCodesList = useSelector((state) => state.recipient.mobileCodesList);
    const createRecipient = useSelector((state) => state.recipient.createRecipient);
    const supportedCountries = useSelector((state) => state.buy.supportedCountries);
    const purposeOfPayment = useSelector((state) => state.buy.purposeOfPayment);
    const countriesList = useSelector((state) => state.user.countriesList);

    const formRef = useRef();
    const [selectedAccountType, setSelectedAccountType] = useState(null);
    const [selectedBankCountry, setSelectedBankCountry] = useState(null);
    const [selectedAddressCountry, setSelectedAddressCountry] = useState(null);
    const [selectedRecivingCurrency, setSelectedRecivingCurrency] = useState(null);
    const [selectedRecivingCountry, setSelectedRecivingCountry] = useState(null);
    const [currencyList, setCurrencyList] = useState([]);
    const [skipRender, setSkipRender] = useState(true);
    const [banks, setBanks] = useState([]);
    const [bankLists, setBankLists] = useState([]);
    const profile = useSelector((state) => state.user.profile);
    const [bankCodeData, setBankCodeData] = useState(null);

    const [countryCodeList, setCountryCodeList] = useState(null);
    const navigate = useNavigate();
    const AccountType = [
        { value: "current", label: "Current Account" },
        { value: "savings", label: "Savings Account" },
    ];

    const jpnAcoountTypes = [
        { value: "ordinary", label: "Ordinary Account" },
        { value: "checking", label: "Checking Account" },
        { value: "savings", label: "Savings Account" },
    ];

    const [selectedDate, setSelectedDate] = useState(null);
    const [filteredCountries, setFilteredCountries] = useState([])
    const [validationSchema, setValidationSchema] = useState(null);
    const [take, setTake] = useState(100);
    const [skip, setSkip] = useState(0);
    const [searchValue, setSearchValue] = useState("null");
    const [recipientType, setRecipientType] = useState(1);
    const [selectedBank, setSelectedBank] = useState(null);
    const [branchCodes, setBranchCodes] = useState(true);
    const [branchCodetake, setbranchCodeTake] = useState(100);
    const [branchCodeskip, setbranchCodeSkip] = useState(0);
    const [branchCodeList, setbranchCodeList] = useState([]);
    const [incomeType, setIncomeType] = useState(null);
    const [occupationType, setOccupationType] = useState(null);
    const [relationShipType, setRelationShipType] = useState(null);
    const [paymentPurposeOptions, setPaymentPurposeOptions] = useState(null);
    const [purpose, setPurpose] = useState("");
    const [incomeOptions, setIncomeOptions] = useState(null);
    const [occupationOptions, setOccupationOptions] = useState(null);
    const [relationshipOptions, setRelationShipOptions] = useState(null);
    const [addressCountries, setAddressCountries] = useState([]);

    const customStyles = {
        ///.....
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            left: "0px",
            borderRadius: "10px",
            overflow: "hidden",
            minWidth: "100px",
        }),
        menuList: (provided) => ({
            ...provided,
            padding: 0,
            minWidth: 250,
            fontSize: "0.85em",
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                borderRadius: "3px",
                backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar": {
                width: "4px",
                backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar-thumb": {
                borderRadius: "3px",
                boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
                backgroundColor: "#555",
            },
        }),
        container: (provided) => ({ ...provided, width: "auto" }),
        control: (provided) => ({
            ...provided,
            backgroundColor: "transparent!important",
            border: "1px solid #e5eaf4!important",
            borderRadius: "10px!important",
            boxShadow: "none!important",
            height: "42px",
            cursor: "pointer",
            minWidth: "auto",
            fontSize: "0.9em",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#878e96",
            fontSize: "0.9em",
            fontWeight: "400",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "#878e96",
            display: "flex",
            alignItems: "center",
            gap: "0.5em",
            fontSize: "0.9em",
            fontWeight: "400",
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            color: "#DDE1E6!important",
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            fill: "#DDE1E6!important",
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: "none",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            svg: {
                fill: "#878e96",
            },
        }),
        // option: (provided, state) => ({
        //   ...provided,
        //   backgroundColor: state.isFocused || state.isSelected ? "#F9C201" : "#fff",
        //   color: state.isFocused || state.isSelected ? "#000" : "#000",
        //   ":hover": {
        //     backgroundColor: "#F9C201",
        //     color: "#000",
        //   },
        // }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? "#F9C201"
                : state.isFocused
                    ? "#f0f0f0"
                    : "transparent",
            color: state.isSelected || state.isFocused ? "#000" : "#000",
            ":hover": {
                backgroundColor: "#f0f0f0",
                color: "#000",
            },
        }),
    };

    const validateMobileNumber = (value) => {
        if (value) {
            if (isPossiblePhoneNumber(value) == false) {
                formRef.current.setFieldError("mobile", t("mobile_number.invalid"));
                return false;
            } else if (isValidPhoneNumber(value) == false) {
                formRef.current.setFieldError("mobile", t("mobile_number.invalid"));
                return false;
            } else {
                return true;
            }
        } else {
            formRef.current.setFieldError("mobile", t("mobile_number.required"));
            return false;
        }
    };

    const handleSubmit = (values) => {

        let inputName = (values.first_name + values.middle_name + values.last_name).replace(/\s+/g, "").toLowerCase();
        let userName = (profile.data.first_name + profile.data.middle_name + profile.data.last_name).replace(/\s+/g, "").toLowerCase();

        const similarity = stringSimilarity.compareTwoStrings(inputName, userName);

        if (similarity >= 0.5) {
            if (validateMobileNumber(values.mobile)) {
                const intlNo = formatPhoneNumberIntl(values.mobile);
                const countryCode = intlNo.substring(
                    intlNo.indexOf("+") + 1,
                    intlNo.indexOf(" ")
                );
                const mobile = intlNo
                    .substring(intlNo.indexOf(" "), intlNo.length)
                    .replaceAll(" ", "");
                const {
                    service_bank_country,
                    user_source_of_income,
                    user_occupation,
                    user_recipient_relationship,
                    branch_code,
                    sub_division,
                    ...rest
                } = values;
                dispatch(
                    createRecipientStart(
                        ["JPN", "LKA"].includes(values.recipient_country)
                            ? {
                                ...rest,
                                mobile: mobile,
                                mobile_country_code: countryCode,
                                branch_code: branch_code,
                                user_source_of_income: user_source_of_income,
                            }
                            : values.recipient_country == "USA"
                                ? {
                                    ...rest,
                                    mobile: mobile,
                                    mobile_country_code: countryCode,
                                    sub_division: sub_division,
                                }
                                : values.recipient_country == "PHL"
                                    ? {
                                        ...rest,
                                        mobile: mobile,
                                        mobile_country_code: countryCode,
                                        user_source_of_income: user_source_of_income,
                                        user_occupation: user_occupation,
                                        user_recipient_relationship: user_recipient_relationship,
                                    }
                                    : {
                                        ...rest,
                                        mobile: mobile,
                                        mobile_country_code: countryCode,
                                    }
                    )
                );
            }
        } else {
            getErrorNotificationMessage("The entered name does not match atleast 50% with the registered username.")
        }
    };

    useEffect(() => {
        if (Object.keys(supportedCountries.data).length > 0) {
            setCountryCodeList(
                supportedCountries.data.supported_countries
                    // .filter((country) => ["IND", "PHL", "VNM"].includes(country.code))
                    .map((country) => ({
                        label: `${country.country_name} (${country.country_code})`,
                        value: country.country_code,
                    }))
            );
        }
        // setCurrencyList(
        //   [
        //     ...new Set(
        //        supportedCountries.data.supported_countries
        //         // .filter((country) => ["IND", "PHL", "VNM"].includes(country.code))
        //         .map((data) => data.currency)
        //     ),
        //   ].map((currency) => ({
        //     label: currency,
        //     value: currency,
        //   }))
        // );
    }, [supportedCountries]);

    useEffect(() => {
        selectedRecivingCountry &&
            selectedRecivingCountry.value &&
            dispatch(
                purposeOfPaymentStart({
                    recipient_type: recipientType,
                    country_code: selectedRecivingCountry.value,
                })
            );
    }, [selectedRecivingCountry]);

    const getCountriesByCurrency = (data) => {
        if (data?.value == "EUR" || data?.value == "GBP") {
            const { ifsc_code, ...rest } = formRef.current.values;
            formRef.current.setValues({ ...rest, iban: "" });
        } else if (data?.value == "INR") {
            const { iban, ...rest } = formRef.current.values;
            if (!formRef.current.values.ifsc_code) {
                formRef.current.setValues({ ...rest, ifsc_code: "" });
            }
        }

        if (data) {
            formRef.current.setFieldValue("receiving_currency", data.value);
            setSelectedRecivingCurrency(data);
            // setCountryCodeList(
            //    supportedCountries.data.supported_countries
            //     .filter((country) => country.currency == data.value)
            //     .map((country) => ({
            //       label: `${country.name} (${country.code})`,
            //       value: country.code,
            //     }))
            // );
        } else {
            // setCountryCodeList(
            //    supportedCountries.data.supported_countries
            //     .filter((country) => ["IND", "PHL", "VNM"].includes(country.code))
            //     .map((country) => ({
            //       label: `${country.name} (${country.code})`,
            //       value: country.code,
            //     }))
            // );
            formRef.current.setFieldValue("account_number", "");
            formRef.current.setFieldValue("account_type", "");
            formRef.current.setFieldValue("account_number_confirmation", "");
            formRef.current.setFieldValue("iban_code", "");
            formRef.current.setFieldValue("ifsc_code", "");
            formRef.current.setFieldValue("bank_id", "");
            formRef.current.setFieldValue("receiving_currency", "");
            setSelectedRecivingCurrency(null);
            setSelectedAccountType(null);
            setSelectedBankCountry(null);
        }
    };

    const getCurrenciesByCountry = (data) => {
        if (data) {
            // setSelectedRecivingCurrency(
            //   data.value != selectedRecivingCountry?.value
            //     ? null
            //     : selectedRecivingCurrency
            // );
            // formRef.current.setFieldValue(
            //   "receiving_currency",
            //   data.value != selectedRecivingCountry?.value
            //     ? ""
            //     : selectedRecivingCurrency?.value
            // );
            formRef.current.setFieldValue("recipient_country", data.value);
            setSelectedRecivingCountry(data);
            // data != null &&
            //   setBanks(
            //     bankList.data.banks?.filter((bank) => bank?.country == data.value)
            //       .map((value) => ({
            //         label: value.bank_name,
            //         value: value.id,
            //       }))
            //   );
            setCurrencyList(
                [
                    ...new Set(
                        supportedCountries.data.supported_countries
                            .filter((country) => country.country_code == data.value)
                            .map((data) => data.currency)
                    ),
                ].map((currency) => ({
                    label: currency,
                    value: currency,
                }))
            );
        } else {
            formRef.current.setFieldValue("recipient_country", "");
            formRef.current.setFieldValue("receiving_currency", "");
            setSelectedRecivingCurrency(null);
            setSelectedRecivingCountry(null);
            setCurrencyList([]);
        }
        if (data?.value != selectedRecivingCountry?.value) {
            formRef.current.setFieldValue("account_number", "");
            formRef.current.setFieldValue("account_type", "");
            formRef.current.setFieldValue("account_number_confirmation", "");
            formRef.current.setFieldValue("iban_code", "");
            formRef.current.setFieldValue("ifsc_code", "");
            formRef.current.setFieldValue("bank_id", "");
            setSelectedAccountType(null);
            setSelectedBankCountry(null);
        }
    };

    const handleDateChange = (values) => {
        formRef.current.setFieldValue(
            "dob",
            values !== null ? JSON.stringify(new Date(values)).slice(1, 11) : ""
        );
        setSelectedDate(values);
    };

    const handleBankListChange = (data) => {
        if (data && data?.value) {
            formRef.current.setFieldValue("service_bank_country", data.value);
            setBanks(false);
            setSelectedBankCountry(data);
            setSkip(0);
            setTake(100);
            setBankLists([]);
        }
        // dispatch(bankListStart({ country_code: data.value }));
        setTimeout(() => {
            setBanks(true);
        }, 0);
    };

    useEffect(() => {
        if (
            !skipRender &&
            Object.keys(createRecipient.data).length > 0 &&
            !createRecipient.loading
        ) {
            navigate("/recipient-list");
        }
        setSkipRender(false);
    }, [createRecipient]);

    useEffect(() => {
        if (
            !skipRender &&
            !purposeOfPayment.loading &&
            Object.keys(purposeOfPayment.data).length > 0
        ) {
            setAddressCountries(
                purposeOfPayment.data.form_fields.find(item => item.field_key == "country")?.values_supported?.map((item) => ({
                    label: item.value,
                    value: item.code,
                }))
            );
            setPaymentPurposeOptions(
                purposeOfPayment.data.form_fields.find(item => item.field_key == "purpose_of_payment")?.values_supported?.map((item) => ({
                    label: item.value,
                    value: item.value,
                }))
            );
            setIncomeOptions(
                purposeOfPayment.data.form_fields.find(item => item.field_key == "user_source_of_income")?.values_supported?.map((item) => ({
                    label: item.value,
                    value: item.value,
                }))
            );
            setOccupationOptions(
                purposeOfPayment.data.form_fields.find(item => item.field_key == "user_occupation")?.values_supported?.map((item) => ({
                    label: item.value,
                    value: item.value,
                }))
            );
            setRelationShipOptions(
                purposeOfPayment.data.form_fields.find(item => item.field_key == "user_recipient_relationship")?.values_supported?.map((item) => ({
                    label: item.value,
                    value: item.value,
                }))
            );
            setBankCodeData(purposeOfPayment.data.form_fields?.find(item => item.field_key == "code")?.field_label)
            let fields = purposeOfPayment.data.form_field_keys;
            // setBankCodeData(purposeOfPayment.data.code_data?.field_label);
            setValidationSchema(
                fields.includes("account_number")
                    ? Yup.object().shape({
                        first_name: Yup.string()
                            .required(t("required"))
                            .min(1, "Must have 1 characters at minimum")
                            .matches(
                                /^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/,
                                "Invalid first name"
                            )
                            .trim().test('no-spaces', t("only_space_not_allowed"), value => {
                                return value?.trim().length > 0;
                            }),
                        // middle_name: Yup.string()
                        //     .min(1, "Must have 1 characters at minimum")
                        //     .matches(
                        //         /^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/,
                        //         "Invalid middle name"
                        //     )
                        //     .trim().test('no-spaces', t("only_space_not_allowed"), value => {
                        //         return value?.trim().length > 0;
                        //     }),
                        last_name: Yup.string()
                            .required(t("required"))
                            .min(1, "Must have 1 characters at minimum")
                            .matches(/^\S.*$/, t("required"))
                            .matches(
                                /^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/,
                                "Invalid last name"
                            )
                            .trim().test('no-spaces', t("only_space_not_allowed"), value => {
                                return value?.trim().length > 0;
                            }),
                        receiving_currency: Yup.string().required(t("required")),
                        recipient_country: Yup.string().required(t("required")),
                        email: Yup.string()
                            .email(t("email.invalid"))
                            .required(t("required"))
                            .matches(/^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/, t("invalid"))
                            .trim(),
                        state: Yup.string()
                            .required(t("required"))
                            .min(1, "Must have 1 characters at minimum")
                            .matches(/^[#.0-9a-zA-Z\s,-_)('‘’‚‛′]+$/, t("invalid")).test('no-spaces', t("only_space_not_allowed"), value => {
                                return value?.trim().length > 0;
                            }),
                        city: Yup.string()
                            .required(t("required"))
                            .min(1, "Must have 1 characters at minimum")
                            .matches(/^[#.0-9a-zA-Z\s,-_)('‘’‚‛′]+$/, t("invalid")).test('no-spaces', t("only_space_not_allowed"), value => {
                                return value?.trim().length > 0;
                            }),
                        country: Yup.string().required(t("required")),
                        primary_address: Yup.string()
                            .required(t("required"))
                            .min(1, "Must have 1 characters at minimum")
                            .matches(/^[#.0-9a-zA-Z\s,-_)('‘’‚‛′]+$/, t("invalid")).test('no-spaces', t("only_space_not_allowed"), value => {
                                return value?.trim().length > 0;
                            }),
                        secondary_address: Yup.string()
                            .required(t("required"))
                            .min(1, "Must have 1 characters at minimum")
                            .matches(/^[#.0-9a-zA-Z\s,-_)('‘’‚‛′]+$/, t("invalid")).test('no-spaces', t("only_space_not_allowed"), value => {
                                return value?.trim().length > 0;
                            }),
                        postal_code: Yup.string()
                            .strict(true) // ensures that trimming won't happen automatically
                            .matches(/^\S+$/, "No spaces are allowed").matches(/^[0-9]+$/, t("invalid"))
                            .required(t("required"))
                            .min(1, "Must have 1 characters at minimum"),
                        service_bank_id: Yup.string().required(t("required")),
                        service_bank_country: Yup.string().required(t("required")),
                        account_name: Yup.string()
                            .required(t("required"))
                            .min(1, "Must have 1 characters at minimum")
                            .matches(/^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/, t("invalid")).test('no-spaces', t("only_space_not_allowed"), value => {
                                return value?.trim().length > 0;
                            }),
                        account_number: Yup.string()
                            .required(t("required"))
                            .min(1, "Must have 1 characters at minimum")
                            // .matches(/^[0-9]+$/, "No spaces or other characters allowed")
                            .matches(/^[#.0-9a-zA-Z\s,-_)(']+$/, "Invalid account number")
                            .trim()
                            .notOneOf([""]),
                        account_confirmation: Yup.string()
                            .required(t("required"))
                            .oneOf([Yup.ref("account_number")], t("account.invalid")),
                        account_type: Yup.string().required(t("required")),
                        // code: Yup.string().required(t("required")),
                        code: Yup.string().when(
                            "recipient_country",
                            (recipient_country, schema) => {
                                const rule = codeValidationRules[recipient_country];
                                if (rule) {
                                    const { min, max, regex, message } = rule;
                                    return (
                                        Yup.string()
                                            .required(t("required"))
                                            .min(min, message)
                                            .max(max, message)
                                            // .min(1, "Must have 1 characters at minimum")
                                            // .matches(/^[A-Za-z0-9]+$/, "Invalid code")
                                            .matches(regex, message)
                                    );
                                }
                                return schema.required(t("required"));
                            }
                        ),
                        purpose_of_payment: Yup.string().required(t("required")),
                        mobile: Yup.string()
                            .test("valid-mobile", t("mobile.invalid"), (value) => {
                                if (!value) {
                                    return false;
                                }
                                return isValidPhoneNumber(value);
                            })
                            .required(t("required"))
                            .min(1, "Must have 1 characters at minimum")
                            .matches(
                                /^(?=.*[0-9])/,
                                t("mobile_number.space_values_not_valid")
                            ),
                        dob: Yup.string().when("receiving_currency", {
                            is: "BDT",
                            then: Yup.string()
                                .required(t("required"))
                                .matches(/^\d{4}-\d{2}-\d{2}$/, t("dob.invalid_date_format"))
                                .test("is-past-date", "date cannot be future", (value) => {
                                    const dob = new Date(value);
                                    const currentDate = new Date();
                                    return dob <= currentDate;
                                })
                                .test("is-18-years-old", "must be 18 years old", (value) => {
                                    const dob = new Date(value);
                                    const currentDate = new Date();
                                    const age = currentDate.getFullYear() - dob.getFullYear();
                                    const monthDiff = currentDate.getMonth() - dob.getMonth();
                                    return (
                                        age > 18 ||
                                        (age === 18 && monthDiff > 0) ||
                                        (age === 18 &&
                                            monthDiff === 0 &&
                                            currentDate.getDate() >= dob.getDate())
                                    );
                                }),
                        }),
                        registered_org_name: Yup.string().when("user_type",
                            (user_type) => {
                                if (user_type == 2) {
                                    return (
                                        Yup.string().required(t("required"))
                                            .test('no-spaces', t("only_space_not_allowed"), value => {
                                                return value?.trim().length > 0;
                                            })

                                    );
                                }
                                return Yup.string()
                            }
                        ),
                        user_source_of_income: Yup.string().when("recipient_country", {
                            is: (country) => ["PHL", "JPN"].includes(country),
                            then: Yup.string().required(t("required")),
                        }),
                        user_occupation: Yup.string().when("recipient_country", {
                            is: "PHL",
                            then: Yup.string().required(t("required")),
                        }),
                        user_recipient_relationship: Yup.string().when(
                            "recipient_country",
                            {
                                is: "PHL",
                                then: Yup.string().required(t("required")),
                            }
                        ),
                        branch_code: Yup.string().when("recipient_country", {
                            is: (country) => ["LKA", "JPN"].includes(country),
                            then: Yup.string()
                                .required(t("branch_code.required"))
                                .matches(/^([0-9]{3})/, t("branch_code.invalid")),
                        }),
                        sub_division: Yup.string().when("recipient_country", {
                            is: "USA",
                            then: Yup.string()
                                .required(t("sub_division.required"))
                                .max(30, t("sub_division.max"))
                                .matches(
                                    /^[#.0-9a-zA-Z\s,-_)('‘’‚‛′]+$/,
                                    t("sub_division.invalid")
                                ),
                        }),
                    })
                    : Yup.object().shape({
                        first_name: Yup.string()
                            .required(t("required"))
                            .min(1, "Must have 1 characters at minimum")
                            .matches(
                                /^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/,
                                "Invalid first name"
                            )
                            .trim()
                            .test('no-spaces', t("only_space_not_allowed"), value => {
                                return value?.trim().length > 0;
                            }),
                        // middle_name: Yup.string()
                        //     .min(1, "Must have 1 characters at minimum")
                        //     .matches(
                        //         /^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/,
                        //         "Invalid middle name"
                        //     )
                        //     .trim().test('no-spaces', t("only_space_not_allowed"), value => {
                        //         return value?.trim().length > 0;
                        //     }),
                        last_name: Yup.string()
                            .required(t("required"))
                            .min(1, "Must have 1 characters at minimum")
                            .matches(/^\S.*$/, t("required"))
                            .matches(
                                /^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/,
                                "Invalid last name"
                            )
                            .trim().test('no-spaces', t("only_space_not_allowed"), value => {
                                return value?.trim().length > 0;
                            }),
                        receiving_currency: Yup.string().required(t("required")),
                        recipient_country: Yup.string().required(t("required")),
                        email: Yup.string()
                            .email(t("email.invalid"))
                            .required(t("required"))
                            .matches(/^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/, t("invalid"))
                            .trim(),
                        state: Yup.string()
                            .required(t("required"))
                            .min(1, "Must have 1 characters at minimum")
                            .matches(/^[#.0-9a-zA-Z\s,-_)('‘’‚‛′]+$/, t("invalid")).test('no-spaces', t("only_space_not_allowed"), value => {
                                return value?.trim().length > 0;
                            }),
                        city: Yup.string()
                            .required(t("required"))
                            .min(1, "Must have 1 characters at minimum")
                            .matches(/^[#.0-9a-zA-Z\s,-_)('‘’‚‛′]+$/, t("invalid")).test('no-spaces', t("only_space_not_allowed"), value => {
                                return value?.trim().length > 0;
                            }),
                        country: Yup.string().required(t("required")),
                        primary_address: Yup.string()
                            .required(t("required"))
                            .min(1, "Must have 1 characters at minimum")
                            .matches(/^[#.0-9a-zA-Z\s,-_)('‘’‚‛′]+$/, t("invalid")),
                        secondary_address: Yup.string()
                            .required(t("required"))
                            .min(1, "Must have 1 characters at minimum")
                            .matches(/^[#.0-9a-zA-Z\s,-_)('‘’‚‛′]+$/, t("invalid")).test('no-spaces', t("only_space_not_allowed"), value => {
                                return value?.trim().length > 0;
                            }),
                        postal_code: Yup.string()
                            .strict(true) // ensures that trimming won't happen automatically
                            .matches(/^\S+$/, "No spaces are allowed").matches(/^[0-9]+$/, t("invalid"))
                            .required(t("required"))
                            .min(1, "Must have 1 characters at minimum"),
                        service_bank_id: Yup.string().required(t("required")),
                        service_bank_country: Yup.string().required(t("required")),
                        account_name: Yup.string()
                            .required(t("required"))
                            .min(1, "Must have 1 characters at minimum")
                            .matches(/^[#.0-9a-zA-Z\s,/\\_:+?')(@#!&-]+$/, t("invalid")).test('no-spaces', t("only_space_not_allowed"), value => {
                                return value?.trim().length > 0;
                            }),
                        account_type: Yup.string().required(t("required")),
                        // code: Yup.string().required(t("required")),
                        code: Yup.string().when(
                            "recipient_country",
                            (recipient_country, schema) => {
                                const rule = codeValidationRules[recipient_country];
                                if (rule) {
                                    const { min, max, regex, message } = rule;
                                    return (
                                        Yup.string()
                                            .required(t("required"))
                                            .min(min, message)
                                            .max(max, message)
                                            // .min(1, "Must have 1 characters at minimum")
                                            // .matches(/^[A-Za-z0-9]+$/, "Invalid code")
                                            .matches(regex, message)
                                    );
                                }
                                return schema.required(t("required"));
                            }
                        ),
                        purpose_of_payment: Yup.string().required(t("required")),
                        mobile: Yup.string()
                            .test("valid-mobile", t("mobile.invalid"), (value) => {
                                if (!value) {
                                    return false;
                                }
                                return isValidPhoneNumber(value);
                            })
                            .required(t("required"))
                            .min(1, "Must have 1 characters at minimum")
                            .matches(
                                /^(?=.*[0-9])/,
                                t("mobile_number.space_values_not_valid")
                            ),
                        dob: Yup.string().when("receiving_currency", {
                            is: "BDT",
                            then: Yup.string()
                                .required(t("required"))
                                .matches(/^\d{4}-\d{2}-\d{2}$/, t("dob.invalid_date_format"))
                                .test("is-past-date", "date cannot be future", (value) => {
                                    const dob = new Date(value);
                                    const currentDate = new Date();
                                    return dob <= currentDate;
                                })
                                .test("is-18-years-old", "must be 18 years old", (value) => {
                                    const dob = new Date(value);
                                    const currentDate = new Date();
                                    const age = currentDate.getFullYear() - dob.getFullYear();
                                    const monthDiff = currentDate.getMonth() - dob.getMonth();
                                    return (
                                        age > 18 ||
                                        (age === 18 && monthDiff > 0) ||
                                        (age === 18 &&
                                            monthDiff === 0 &&
                                            currentDate.getDate() >= dob.getDate())
                                    );
                                }),
                        }),
                        registered_org_name: Yup.string().when("user_type",
                            (user_type) => {
                                if (user_type == 2) {
                                    return (
                                        Yup.string().required(t("required"))
                                            .test('no-spaces', t("only_space_not_allowed"), value => {
                                                return value?.trim().length > 0;
                                            })
                                    );
                                }
                                return Yup.string()
                            }
                        ),
                        user_source_of_income: Yup.string().when("recipient_country", {
                            is: (country) => ["PHL", "JPN"].includes(country),
                            then: Yup.string().required(t("required")),
                        }),
                        user_occupation: Yup.string().when("recipient_country", {
                            is: "PHL",
                            then: Yup.string().required(t("required")),
                        }),
                        user_recipient_relationship: Yup.string().when(
                            "recipient_country",
                            {
                                is: "PHL",
                                then: Yup.string().required(t("required")),
                            }
                        ),
                        branch_code: Yup.string().when("recipient_country", {
                            is: (country) => ["LKA", "JPN"].includes(country),
                            then: Yup.string()
                                .required(t("branch_code.required"))
                                .matches(/^([0-9]{3})/, t("branch_code.invalid")),
                        }),
                        sub_division: Yup.string().when("recipient_country", {
                            is: "USA",
                            then: Yup.string()
                                .required(t("sub_division.required"))
                                .max(30, t("sub_division.max"))
                                .matches(
                                    /^[#.0-9a-zA-Z\s,-_)('‘’‚‛′]+$/,
                                    t("sub_division.invalid")
                                ),
                        }),
                    })
            );
        }
        setSkipRender(false);
    }, [purposeOfPayment]);

    const loadOptions = async (search) => {
        if (search == searchValue) {
            setSkip(skip + take);
        } else {
            setSkip(0);
            setTake(100);
        }
        setSearchValue(search == "" ? "" : search);

        const payload = {
            search_key: search,
            country_code: selectedBankCountry?.value
                ? selectedBankCountry?.value
                : "",
            skip: search == searchValue ? skip + take : 0,
            take: take,
        };

        try {
            if (selectedBankCountry?.value == null) {
                return {
                    options: [{ label: "Select Bank Country", value: "" }],
                    hasMore: false,
                };
            }
            const response = await api.postMethod("service_banks", payload);
            if (response.data.success) {
                if (response.data.data.total_service_banks > 0) {
                    const newBanks = response.data.data.service_banks.map((bank) => ({
                        label: bank.bank_name,
                        value: bank.id,
                    }));
                    setBankLists([...bankLists, ...newBanks]);
                    const uniqueNewBanks = bankLists.filter(
                        (existingBank) =>
                            !newBanks.some((newBank) => existingBank.value === newBank.value)
                    );
                    return {
                        options: newBanks,
                        hasMore:
                            uniqueNewBanks.length < response.data.data.total_service_banks,
                    };
                } else {
                    return {
                        options: [{ label: "No data available", value: "" }],
                        hasMore: false,
                    };
                }
            } else {
                return {
                    options: [{ label: "No data available", value: "" }],
                    hasMore: false,
                };
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false,
            };
        }
    };

    useEffect(() => {
        dispatch(
            getSupportedCountriesStart({
                user_type: profile.data.user_type,
            })
        );
    }, [recipientType]);

    const loadBranchCodeOptions = async (
        search,
        loadedOptions,
        { branchSkip }
    ) => {
        const payload = {
            // search_key: search,
            service_bank_id: selectedBank,
            skip: branchSkip,
            take: branchCodetake,
        };

        try {
            if (selectedBank == null) {
                return {
                    options: [{ label: "Select Bank", value: "" }],
                    hasMore: false,
                };
            }
            const response = await api.postMethod("branch_codes", payload);
            if (response.data.success) {
                if (response.data.data.codes.pagination.total_records > 0) {
                    const newBranchCodes = response.data.data.codes.data.map((bank) => ({
                        label: `${bank.name} (${bank.code})`,
                        value: bank.code,
                    }));
                    setbranchCodeList([...branchCodeList, ...newBranchCodes]);
                    return {
                        options: newBranchCodes,
                        hasMore:
                            branchSkip + response.data.data.codes.data.length <
                            response.data.data.codes.pagination.total_records,
                        additional: {
                            branchSkip: branchSkip + 100,
                        },
                    };
                } else {
                    return {
                        options: [{ label: "No data available", value: "" }],
                        hasMore: false,
                    };
                }
            } else {
                return {
                    options: [{ label: "No data available", value: "" }],
                    hasMore: false,
                };
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false,
            };
        }
    };

    const handleBankChange = (data) => {
        if (data && data.value) {
            formRef.current.setFieldValue("service_bank_id", data.value);
            setSelectedBank(data.value);
        } else {
            setSelectedBank(null);
            formRef.current.setFieldValue("service_bank_id", "");
        }
        formRef.current.setFieldValue("code", "");
        setBranchCodes(false);
        setbranchCodeSkip(0);
        setbranchCodeTake(100);
        setbranchCodeList([]);
        setTimeout(() => {
            setBranchCodes(true);
        }, 0);
    };

    const handlePaste = (event) => {
        event.preventDefault(); // Disable pasting
    };

    useEffect(() => {
        dispatch(mobileCodesListStart())
        dispatch(countriesListStart({ all: 1 }))
    }, [])

    useEffect(() => {
        if (!skipRender && !mobileCodesList.loading && Object.keys(mobileCodesList.data).length > 0) {
            setFilteredCountries(mobileCodesList.data.mobile_country_codes)
        }
        setSkipRender(false);
    }, [mobileCodesList])

    useEffect(() => {
        if (!skipRender && !countriesList.loading && Object.keys(countriesList.data).length > 0) {
            formRef.current.setFieldValue("country", countriesList.data.countries.find((data) => data.alpha_2_code == profile.data.delivery_country)?.alpha_3_code)
        }
    }, [countriesList])

    const validation = Yup.object().shape({
        recipient_country: Yup.string().required(t("required")),
        receiving_currency: Yup.string().required(t("required")),
    })

    return (
        <>
            <Offcanvas  {...props} className="view-canvas-modal">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t("bank_details")}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="pt-0">
                    <div className="add-bank-full-frame">
                        <Formik
                            initialValues={{
                                user_type: profile.data.user_type,
                                recipient_type: profile.data.user_type,
                                first_name: profile.data.first_name,
                                last_name: profile.data.last_name,
                                middle_name: profile.data.middle_name,
                                email: profile.data.email,
                                mobile: `+${profile.data.country_code}${profile.data.mobile}`,
                                account_number: "",
                                account_type: "",
                                account_confirmation: "",
                                receiving_currency: "",
                                recipient_country: "",
                                primary_address: profile.data.delivery_address_1,
                                secondary_address: profile.data.delivery_address_2,
                                city: profile.data.delivery_city,
                                state: profile.data.delivery_state,
                                postal_code: profile.data.delivery_postal_code,
                                code: "",
                                country: "",
                                dob: profile.data.dob,
                                service_bank_id: "",
                                account_name: "",
                                purpose_of_payment: "",
                                service_bank_country: "",
                                user_occupation: "",
                                user_source_of_income: "",
                                user_recipient_relationship: "",
                                registered_org_name: profile.data?.user_business?.company_name
                            }}
                            validationSchema={validationSchema?validationSchema:validation}
                            onSubmit={handleSubmit}
                            innerRef={formRef}
                        >
                            {({ errors, touched, values, setFieldValue, setValues }) => (
                                <FORM className="create-recipient-form">
                                    <Row>
                                        <Col sm={12} lg={12} xl={12} className="mb-3">
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label>
                                                    {t("receiving_country.name")} <span>*</span>
                                                </Form.Label>
                                                <Select
                                                    options={countryCodeList}
                                                    value={selectedRecivingCountry}
                                                    isClearable
                                                    styles={customStyles}
                                                    onChange={(data) => {
                                                        getCurrenciesByCountry(data);
                                                        handleBankListChange(data);
                                                        setSelectedRecivingCurrency(null)
                                                    }}
                                                    placeholder={t("receiving_country.placeholder")}
                                                    isSearchable={true}
                                                />
                                                <ErrorMessage
                                                    component={"div"}
                                                    name="recipient_country"
                                                    className="text-danger text-right"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} lg={12} xl={12} className="mb-3">
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label>
                                                    {t("receiving_currency.name")} <span>*</span>
                                                </Form.Label>
                                                <Select
                                                    className="fillert-drop"
                                                    value={selectedRecivingCurrency}
                                                    options={currencyList}
                                                    isClearable
                                                    styles={customStyles}
                                                    onChange={(data) => {
                                                        getCountriesByCurrency(data);
                                                    }}
                                                    placeholder={t("receiving_currency.placeholder")}
                                                    isSearchable={false}
                                                />
                                                <ErrorMessage
                                                    component={"div"}
                                                    name="receiving_currency"
                                                    className="text-danger"
                                                />
                                            </Form.Group>
                                        </Col>
                                        {values.recipient_country ? (
                                            purposeOfPayment.buttonDisable ? (
                                                <React.Fragment>
                                                    {[...Array(2)].map((item, key) => (
                                                        <React.Fragment key={key} className="mb-3">
                                                            <Col sm={12} lg={12} xl={12} className="mb-3">
                                                                <Skeleton height={20} width={100} className="mb-2" />
                                                                <Skeleton height={45} />
                                                            </Col>
                                                        </React.Fragment>
                                                    ))}
                                                    <Col sm={12} lg={12} xl={12} className="mb-3">
                                                        <Skeleton height={20} width={100} className="mb-2" />
                                                        <Skeleton height={45} />
                                                    </Col>
                                                    <Col sm={12} lg={12} xl={12} className="mb-3">
                                                        <Skeleton height={20} width={100} className="mb-2" />
                                                        <Skeleton height={45} />
                                                    </Col>
                                                    <Col sm={12} lg={12} xl={12} className="mb-3">
                                                        <Skeleton height={20} width={100} className="mb-2" />
                                                        <Skeleton height={45} />
                                                    </Col>

                                                </React.Fragment>
                                            ) : Object.keys(purposeOfPayment.data).length > 0 &&
                                                purposeOfPayment.data.form_field_keys.length > 0 ? (
                                                <React.Fragment>
                                                    <Col sm={12} lg={12} xl={12}>
                                                        <Form.Group
                                                            controlId="formBasicEmail"
                                                            className="mb-3"
                                                        >
                                                            <Form.Label>
                                                                {t("account_type.name")} <span>*</span>
                                                            </Form.Label>
                                                            <Select
                                                                value={selectedAccountType}
                                                                className="fillert-drop"
                                                                options={
                                                                    values.recipient_country == "JPN"
                                                                        ? jpnAcoountTypes
                                                                        : AccountType
                                                                }
                                                                onChange={(account) => {
                                                                    setSelectedAccountType(account);
                                                                    account !== null
                                                                        ? setFieldValue(
                                                                            "account_type",
                                                                            account.value
                                                                        )
                                                                        : setFieldValue("account_type", "");
                                                                }}
                                                                isClearable
                                                                styles={customStyles}
                                                                placeholder={t("account_type.placeholder")}
                                                            />
                                                            <ErrorMessage
                                                                component={"div"}
                                                                name="account_type"
                                                                className="text-danger"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={12} lg={12} xl={12}>
                                                        {!(
                                                            (values.recipient_country == "HKG")
                                                            // values.user_type == 2 &&
                                                            // values.recipient_type == 1
                                                        ) ? (
                                                            <Form.Group
                                                                controlId="formBasicEmail"
                                                                className="mb-3"
                                                            >
                                                                <Form.Label>
                                                                    {bankCodeData || t("code_labels")}{" "}
                                                                    <span>*</span>
                                                                </Form.Label>
                                                                <Field
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="code"
                                                                    placeholder={t("ifsc_code.placeholder")}
                                                                // onKeyPress={handleKeyPress}
                                                                // maxLength={11}
                                                                />
                                                                <ErrorMessage
                                                                    component={"div"}
                                                                    name="code"
                                                                    className="text-danger"
                                                                />
                                                            </Form.Group>
                                                        ) : (
                                                            <Form.Group controlId="formBasicEmail">
                                                                <Form.Label>
                                                                    {t("select_bank.name")} <span>*</span>
                                                                </Form.Label>
                                                                {banks && (
                                                                    <AsyncPaginate
                                                                        isClearable
                                                                        isSearchable={true}
                                                                        placeholder={t("select_bank.placeholder")}
                                                                        debounceTimeout={500}
                                                                        loadOptions={loadOptions}
                                                                        styles={customStyles}
                                                                        onChange={(data) =>
                                                                            handleBankChange(data)
                                                                        }
                                                                        maxLength={32}
                                                                    />
                                                                )}
                                                                <ErrorMessage
                                                                    component={"div"}
                                                                    name="service_bank_id"
                                                                    className="text-danger"
                                                                />
                                                            </Form.Group>
                                                        )}
                                                    </Col>
                                                    {purposeOfPayment.data.form_field_keys.includes(
                                                        "account_number"
                                                    ) ? (
                                                        <React.Fragment>
                                                            <Col sm={12} lg={12} xl={12}>
                                                                <Form.Group
                                                                    controlId="formBasicEmail"
                                                                    className="mb-3"
                                                                >
                                                                    <Form.Label>
                                                                        {t("account_number.name")} <span>*</span>
                                                                    </Form.Label>
                                                                    <Field
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="account_number"
                                                                        placeholder={t(
                                                                            "account_number.placeholder"
                                                                        )}
                                                                        maxLength={50}
                                                                    />
                                                                    <ErrorMessage
                                                                        component={"div"}
                                                                        name="account_number"
                                                                        className="text-danger"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={12} lg={12} xl={12}>
                                                                <Form.Group
                                                                    controlId="formBasicEmail"
                                                                    className="mb-3"
                                                                >
                                                                    <Form.Label>
                                                                        {t("re_account_number.name")}{" "}
                                                                        <span>*</span>
                                                                    </Form.Label>
                                                                    <Field
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="account_confirmation"
                                                                        placeholder={t(
                                                                            "re_account_number.placeholder"
                                                                        )}
                                                                        onPaste={handlePaste}
                                                                    />
                                                                    <ErrorMessage
                                                                        component={"div"}
                                                                        name="account_confirmation"
                                                                        className="text-danger"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </React.Fragment>
                                                    ) : null}
                                                    <Col sm={12} lg={12} xl={12}>
                                                        <Form.Group
                                                            controlId="formBasicEmail"
                                                            className="mb-3"
                                                        >
                                                            <Form.Label>
                                                                {t("account_name.name")}
                                                                <span>*</span>
                                                            </Form.Label>
                                                            <Field
                                                                className="form-control"
                                                                name="account_name"
                                                                type="text"
                                                                placeholder={t("account_name.placeholder")}
                                                                maxLength={50}
                                                            />
                                                            <ErrorMessage
                                                                component={"div"}
                                                                name="account_name"
                                                                className="text-danger"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={12} lg={12} xl={12}>
                                                        {values.recipient_country == "HKG" ? (
                                                            // && values.user_type == 2 && values.recipient_type == 1
                                                            <Form.Group
                                                                controlId="formBasicEmail"
                                                                className="mb-3"
                                                            >
                                                                <Form.Label>
                                                                    {/* {t("code_labels")} */}
                                                                    {bankCodeData || "Clearing Code"}
                                                                    <span>*</span>
                                                                </Form.Label>
                                                                {branchCodes && (
                                                                    <AsyncPaginate
                                                                        isClearable
                                                                        isSearchable={false}
                                                                        placeholder={"Clearing Code"}
                                                                        debounceTimeout={500}
                                                                        loadOptions={loadBranchCodeOptions}
                                                                        additional={{
                                                                            branchSkip: branchCodeskip,
                                                                        }}
                                                                        styles={customStyles}
                                                                        onChange={(data) => {
                                                                            data != null
                                                                                ? setFieldValue("code", data.value)
                                                                                : setFieldValue("code", "");
                                                                        }}
                                                                        maxLength={32}
                                                                    />
                                                                )}
                                                                <ErrorMessage
                                                                    component={"div"}
                                                                    name="code"
                                                                    className="text-danger"
                                                                />
                                                            </Form.Group>
                                                        ) : (
                                                            <Form.Group controlId="formBasicEmail" className="mb-3">
                                                                <Form.Label>
                                                                    {t("select_bank.name")} <span>*</span>
                                                                </Form.Label>
                                                                {/* <Select
                                  className="fillert-drop"
                                  options={banks}
                                  isClearable
                                  styles={customStyles}
                                  placeholder="Select Bank Name"
                                  onChange={(data) => {
                                    {
                                      data !== null &&
                                        setFieldValue(
                                          "service_bank_id",
                                          data.value
                                        );
                                    }
                                  }}
                                /> */}
                                                                {banks && (
                                                                    <AsyncPaginate
                                                                        isClearable
                                                                        isSearchable={true}
                                                                        placeholder={t("select_bank.placeholder")}
                                                                        debounceTimeout={500}
                                                                        loadOptions={loadOptions}
                                                                        styles={customStyles}
                                                                        onChange={(data) => {
                                                                            data != null
                                                                                ? setFieldValue(
                                                                                    "service_bank_id",
                                                                                    data.value
                                                                                )
                                                                                : setFieldValue(
                                                                                    "service_bank_id",
                                                                                    ""
                                                                                );
                                                                        }}
                                                                        maxLength={32}
                                                                    />
                                                                )}
                                                                <ErrorMessage
                                                                    component={"div"}
                                                                    name="service_bank_id"
                                                                    className="text-danger"
                                                                />
                                                            </Form.Group>
                                                        )}
                                                    </Col>
                                                    {["JPN", "LKA"].includes(
                                                        values.recipient_country
                                                    ) ? (
                                                        <Col sm={12} lg={12} xl={12} >
                                                            <Form.Group controlId="formBasicEmail" className="mb-3">
                                                                <Form.Label>
                                                                    {t("branch_code.name")} <span>*</span>
                                                                </Form.Label>
                                                                <Field
                                                                    type="number"
                                                                    className="form-control"
                                                                    name="branch_code"
                                                                    placeholder={t("branch_code.placeholder")}
                                                                />
                                                                <ErrorMessage
                                                                    component={"div"}
                                                                    name="branch_code"
                                                                    className="text-danger"
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    ) : null}
                                                    <Col sm={12} lg={12} xl={12}>
                                                        <Form.Group controlId="formBasicEmail" className="mb-3">
                                                            <Form.Label>
                                                                {t("purpose_of_payment")} <span>*</span>
                                                            </Form.Label>
                                                            <Select
                                                                options={paymentPurposeOptions ? paymentPurposeOptions : []}
                                                                value={purpose}
                                                                isClearable={false}
                                                                styles={customStyles}
                                                                onChange={(data) => {
                                                                    setPurpose(data);
                                                                    setFieldValue(
                                                                        "purpose_of_payment",
                                                                        data.value
                                                                    );
                                                                }}
                                                                placeholder={t(
                                                                    "purpose_of_payment.placeholder"
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                component={"div"}
                                                                name="purpose_of_payment"
                                                                className="text-danger text-right"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    {selectedRecivingCurrency?.value == "PHP" ? (
                                                        <Container>
                                                            <Row className="mt-3">
                                                                <Col sm={12} lg={12} xl={12}>
                                                                    <Form.Group
                                                                        controlId="formBasicEmail"
                                                                        className="mb-3"
                                                                    >
                                                                        <Form.Label>
                                                                            {t("source_of_income.name")}{" "}
                                                                            <span>*</span>
                                                                        </Form.Label>
                                                                        <Select
                                                                            options={incomeOptions}
                                                                            value={incomeType}
                                                                            isClearable={false}
                                                                            styles={customStyles}
                                                                            onChange={(data) => {
                                                                                setIncomeType(data);
                                                                                setFieldValue(
                                                                                    "user_source_of_income",
                                                                                    data.value
                                                                                );
                                                                            }}
                                                                            placeholder={t(
                                                                                "source_of_income.placeholder"
                                                                            )}
                                                                        />
                                                                        <ErrorMessage
                                                                            component={"div"}
                                                                            name="user_source_of_income"
                                                                            className="text-danger"
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col sm={12} lg={12} xl={12}>
                                                                    <Form.Group
                                                                        controlId="formBasicEmail"
                                                                        className="mb-3"
                                                                    >
                                                                        <Form.Label>
                                                                            {t("occupation.name")} <span>*</span>
                                                                        </Form.Label>
                                                                        <Select
                                                                            options={occupationOptions}
                                                                            value={occupationType}
                                                                            isClearable={false}
                                                                            styles={customStyles}
                                                                            onChange={(data) => {
                                                                                setOccupationType(data);
                                                                                setFieldValue(
                                                                                    "user_occupation",
                                                                                    data.value
                                                                                );
                                                                            }}
                                                                            placeholder={t(
                                                                                "occupation.placeholder"
                                                                            )}
                                                                        />
                                                                        <ErrorMessage
                                                                            component={"div"}
                                                                            name="user_occupation"
                                                                            className="text-danger"
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col sm={12} lg={12} xl={12}>
                                                                    <Form.Group
                                                                        controlId="formBasicEmail"
                                                                        className="mb-3"
                                                                    >
                                                                        <Form.Label>
                                                                            {t("beneficiary_relationship.name")}{" "}
                                                                            <span>*</span>
                                                                        </Form.Label>
                                                                        <Select
                                                                            options={relationshipOptions}
                                                                            value={relationShipType}
                                                                            isClearable={false}
                                                                            styles={customStyles}
                                                                            onChange={(data) => {
                                                                                setRelationShipType(data);
                                                                                setFieldValue(
                                                                                    "user_recipient_relationship",
                                                                                    data.value
                                                                                );
                                                                            }}
                                                                            placeholder={t(
                                                                                "beneficiary_relationship.placeholder"
                                                                            )}
                                                                        />
                                                                        <ErrorMessage
                                                                            component={"div"}
                                                                            name="user_recipient_relationship"
                                                                            className="text-danger"
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    ) : null}
                                                    {selectedRecivingCountry?.value == "JPN" ? (
                                                        <Container>
                                                            <Row className="mt-3">
                                                                <Col sm={12} lg={12} xl={12}>
                                                                    <Form.Group
                                                                        controlId="formBasicEmail"
                                                                        className="mb-3"
                                                                    >
                                                                        <Form.Label>
                                                                            {t("source_of_income.name")}{" "}
                                                                            <span>*</span>
                                                                        </Form.Label>
                                                                        <Select
                                                                            options={incomeOptions}
                                                                            value={incomeType}
                                                                            isClearable
                                                                            styles={customStyles}
                                                                            onChange={(data) => {
                                                                                setIncomeType(data);
                                                                                setFieldValue(
                                                                                    "user_source_of_income",
                                                                                    data.value
                                                                                );
                                                                            }}
                                                                            placeholder={t(
                                                                                "source_of_income.placeholder"
                                                                            )}
                                                                        />
                                                                        <ErrorMessage
                                                                            component={"div"}
                                                                            name="user_source_of_income"
                                                                            className="text-danger"
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    ) : null}
                                                </React.Fragment>
                                            ) : (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <Form.Group
                                                        controlId="formBasicEmail"
                                                        className="mb-3"
                                                    >
                                                        <Form.Label>
                                                            <span>Please try again later</span>
                                                        </Form.Label>
                                                    </Form.Group>
                                                    <Button
                                                        className="efi-primary-btn"
                                                        type="button"
                                                        onClick={() =>
                                                            dispatch(
                                                                purposeOfPaymentStart({
                                                                    recipient_type: recipientType,
                                                                    country_code: selectedRecivingCountry.value,
                                                                })
                                                            )
                                                        }
                                                        disabled={purposeOfPayment.buttonDisable}
                                                    >
                                                        Retry
                                                    </Button>
                                                </div>
                                            )
                                        ) : null}

                                    </Row>
                                    <Col md={12} lg={12}>
                                        <div className="recipient-form-box-action-btn-sec">
                                            <div className="">
                                            </div>
                                            <div className="create-recipient-btn-sec">
                                                <Button
                                                    className="action-btn overlay"
                                                    onClick={() => props.onHide()}
                                                >
                                                    {t("cancel")}
                                                </Button>
                                                <Button className="action-btn primary" disabled={createRecipient.loading} type="submit">
                                                    {createRecipient.loading ? (
                                                        <ButtonLoader />
                                                    ) : (
                                                        t("create")
                                                    )}
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>

                                </FORM>
                            )}
                        </Formik>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default AddBank;