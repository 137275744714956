import React, { useRef, useState } from 'react'
import NewTradePair from './TradeBody/TradePair';
import NewTradeSocket from './TradeBody/TradeSocket';
import NewTradeGraph from './TradeBody/TradeGraph';
import Draggable from 'react-draggable';
import ReactDOM from 'react-dom';
import NewTradeModal from '../Modals/TradeModal';
import { useSelector } from 'react-redux';


const TradeMain = (props) => {
  const [currentRotate, setCurrentRotate] = useState(0);
  const [range, setRange] = useState(false);
  const isMobile = useSelector((state) => state.user.isMobile);
  

  const isDraggingRef = useRef(false);

  return (
    <>
    <div className="trade-layout-frame">
         {!isMobile&&
          <NewTradePair />
          }
         <NewTradeGraph />
         {!isMobile&&
         <NewTradeSocket />}
    </div>
    {props.modal &&
        <Draggable 
        // disabled={range}
        disabled={isMobile||range}
        bounds="parent"
        >
          <div className="modal-drag-wrapped">
            <NewTradeModal
              range={setRange}
              modal={props.modal}
              setModal={() => { props.setModal() }}
            />
          </div>
        </Draggable>}
    </>
  )
}

export default TradeMain;