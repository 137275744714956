import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import NewTradePair from '../TradeBody/TradePair';

const MobilePairModal = (props) => {
  return (
   <>
     <Offcanvas  {...props} className="system-pair-modal">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Currency Pair</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <NewTradePair
            onHide={props.onHide}
             />
        </Offcanvas.Body>
      </Offcanvas>
   </>
  )
}

export default MobilePairModal