import axios from "axios";
import { apiConstants } from "./Constant/constants";
import CryptoJS from "crypto-js";
// export const apiUrl = "https://cms-efiexchange.blockstall.com/api/v1/"; // Production Mode
export const apiUrl = "https://cms.herald.exchange/api/v1/";
// export const apiUrl = "http://127.0.0.1:8000/api/v1/"; // localhost URL

const signContent = (url, body, apiKey) => {
  const stringifiedObj = {};

  for (const key in body) {
    if (Object.hasOwnProperty.call(body, key) && !('File' in window && body[key] instanceof File)) {
      if (body[key] && typeof body[key] === 'object' && !(body[key] instanceof File)) {

        stringifiedObj[key] = String(JSON.stringify(body[key]));
      } else {

        stringifiedObj[key] = String(body[key]).trim();
      }
    }
  }

  const plainContent = `/${url.substring(url.lastIndexOf('/') + 1)}${body ? JSON.stringify(stringifiedObj) : "{}"}${sessionStorage.getItem("salt_key") || localStorage.getItem("salt_key")}`;

  return CryptoJS.HmacSHA256(plainContent, apiKey).toString(CryptoJS.enc.Hex);
}

const Environment = {
  postMethod: async (action, object, baseUrl = apiUrl) => {
    const url = baseUrl + action;

    let formData = new FormData();
    // append your data
    for (var key in object) {

      if (object[key] && typeof object[key] === 'object' && !(object[key] instanceof File)) {
        formData.append(key, JSON.stringify(object[key]));
      } else {
        formData.append(key, object[key]);
      }
    }
    formData.append("device_type", apiConstants.DEVICE_TYPE);
    formData.append("device_token", apiConstants.DEVICE_TOKEN);

    const accessToken = localStorage.getItem("token") ?
      localStorage.getItem("token")
      : sessionStorage.getItem("token") ?
        sessionStorage.getItem("token")
        : "";

    const language = localStorage.getItem("lang") ?
      localStorage.getItem("lang")
      : "en";
    formData.append("language", language);
    //signature generation
    const apiKey = (sessionStorage.getItem('api_key') || localStorage.getItem('api_key'));
    let signature = "";
    if (apiKey) {
      signature = signContent(action, { ...object, "device_type": apiConstants.DEVICE_TYPE, "device_token": apiConstants.DEVICE_TOKEN, "language": language }, apiKey);
    }
    // let config = { headers: { Authorization: 'Bearer ' + accessToken, 'X-Api-Key': ((localStorage.getItem('api_key') || sessionStorage.getItem('api_key'))) || "", 'X-Api-Signature': signature, "ngrok-skip-browser-warning": "69420", } };
    let config = { headers: { Authorization: 'Bearer ' + accessToken, 'X-Api-Key': ((sessionStorage.getItem('api_key') || localStorage.getItem('api_key'))) || "", 'X-Api-Signature': signature } };

    return await axios.post(url, formData, config);
  },

  getMethod: async (action, object) => {
    let userId =
      localStorage.getItem("userId") !== "" &&
        localStorage.getItem("userId") !== null &&
        localStorage.getItem("userId") !== undefined
        ? localStorage.getItem("userId")
        : "";
    let accessToken =
      localStorage.getItem("accessToken") !== "" &&
        localStorage.getItem("accessToken") !== null &&
        localStorage.getItem("accessToken") !== undefined
        ? localStorage.getItem("accessToken")
        : "";

    const url = apiUrl + action;

    let formData = new FormData();

    // By Default Id and token

    formData.append("id", userId);
    formData.append("token", accessToken);

    // append your data
    for (var key in object) {
      formData.append(key, object[key]);
    }

    // By Default added device type and login type in future use

    formData.append("login_by", apiConstants.LOGIN_BY);
    formData.append("device_type", apiConstants.DEVICE_TYPE);
    formData.append("device_token", apiConstants.DEVICE_TOKEN);

    return await axios.get(url, formData);
  },
  getConversion: async (from, to) => {
    return await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${from}&vs_currencies=${to}`);
  }
};

export default Environment;
