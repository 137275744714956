import React, { useState, useEffect } from "react";
import {  Tab, Nav, } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { useSelector } from "react-redux";
import "../Transaction/Transaction.css"
import BuyTransactionTable from "./BuyTransactionTable";
import SellTransactionTable from "./SellTransactionTable";
import ExchangeTransactionTable from "./ExchangeTransactionTable";
import DepositTransactionTable from "./DepositTransactionTable";
import SwapTransactionTable from "./SwapTransactionTable";
import WithdrawTransactionTable from "./WithdrawTransactionTable";
import TradeTransactionTable from "./TradeTransactionTable.";
import { useSearchParams } from "react-router-dom";

const NewTradeIndex = () => {
  const t = useTranslation("transactions");
  const [activeTab, setActiveTab] = useState("buy");
  const [skipRender, setSkipRender] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams("");
  const sendIbanTransfers = useSelector(
    (state) => state.transfer.sendIbanTransfers
  );

  useEffect(() => {
    if (
      !skipRender &&
      !sendIbanTransfers.loading &&
      Object.keys(sendIbanTransfers.data).length > 0
    ) {
      setActiveTab("iban");
    }
    setSkipRender(false);
  }, [sendIbanTransfers]);

  return (
    <>
      <div className="transation-wrapped">
        <div className="transation-titles">
          <h2>Transactions</h2>
        </div>
            <div className="transaction-tabs-frame">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav
                      className="transaction-tabs"
                      variant="pills"
                      onClick={() => {
                        setSearchParams({});
                      }}
                    >
                      <Nav.Item>
                        <Nav.Link
                          eventKey="first"
                          onClick={() => setActiveTab("buy")}
                          className="wallet_tab_btn"
                        >
                          {t("buy_transaction.header")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="second"
                          onClick={() => setActiveTab("sell")}
                          className="wallet_tab_btn"
                        >
                          {t("sell_transaction.header")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="exchange"
                          onClick={() => setActiveTab("exchange")}
                          className="wallet_tab_btn"
                        >
                          {t("exchange_transaction.header")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="third"
                          onClick={() => setActiveTab("deposit")}
                          className="wallet_tab_btn"
                        >
                          {t("deposit_transaction.header")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="fourth"
                          onClick={() => setActiveTab("swap")}
                          className="wallet_tab_btn"
                        >
                          {t("swap_transaction.header")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="fifth"
                          onClick={() => setActiveTab("withdraw")}
                          className="wallet_tab_btn"
                        >
                          {t("withdraw_transaction.header")}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="sixth"
                          onClick={() => setActiveTab("trading")}
                          className="wallet_tab_btn"
                        >
                          {t("trading_transaction.header")}
                        </Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                        <Nav.Link eventKey="fifth"
                          onClick={() => setActiveTab("iban")}
                        >{t("iban_transaction.header")}</Nav.Link>
                      </Nav.Item> */}
                    </Nav>
                    {activeTab == "buy" && (
                      <div className="transaction-tabs-content">
                        <BuyTransactionTable activeTab={activeTab} />
                      </div>
                    )}
                    {activeTab == "sell" && (
                      <div className="payment-tabs-content">
                        <SellTransactionTable activeTab={activeTab} />
                      </div>
                    )}
                    {activeTab == "exchange" && (
                      <div className="payment-tabs-content">
                        <ExchangeTransactionTable activeTab={activeTab} />
                      </div>
                    )}
                    {activeTab === "deposit" && (
                      <div className="payment-tabs-content">
                        <DepositTransactionTable activeTab={activeTab} />
                      </div>
                    )}
                    {activeTab === "swap" && (
                      <div className="payment-tabs-content">
                        <SwapTransactionTable activeTab={activeTab} />
                      </div>
                    )}
                    {activeTab === "withdraw" && (
                      <div className="payment-tabs-content">
                        <WithdrawTransactionTable activeTab={activeTab} />
                      </div>
                    )}
                    {activeTab === "trading" && (
                      <div className="payment-tabs-content">
                        <TradeTransactionTable activeTab={activeTab} />
                      </div>
                    )}
                    {/* {activeTab === "iban" && (
                      <div className="payment-tabs-content">
                        <IbanTransaction
                          activeTab={activeTab}
                        />
                      </div>
                    )} */}
              </Tab.Container>
            </div>
      </div>
    </>
  );
};

export default NewTradeIndex;
