import React, { useEffect, useRef, useState } from "react";
import { getCurrenciesListStart } from "../../../store/actions/BuyAction";
import { useDispatch, useSelector } from "react-redux";


const TradeGraph = () => {
  const container = useRef(null);
  const currencyPairs = useSelector((state) => state.buy.currencyPair);



  const timezoneMapping = {
    "Asia/Calcutta": "Asia/Kolkata",
    "Asia/Bombay": "Asia/Mumbai",
  };

  useEffect(() => {
    if (currencyPairs) {
      const containerCurrent = container.current;

      while (containerCurrent.firstChild) {
        containerCurrent.removeChild(containerCurrent.firstChild);
      }
      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;
      let symbol = `${currencyPairs?.currency1}${currencyPairs?.currency2}` == "USDTIDR" ? "USDIDR" : `${currencyPairs?.currency1}${currencyPairs?.currency2}`;
      const rawTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const timezone = timezoneMapping[rawTimezone] || rawTimezone;
      script.innerHTML = JSON.stringify({
        autosize: true,
        symbol: symbol,
        interval: "1",
        timezone: timezone,
        theme: "dark",
        style: "1",
        locale: "en",
        withdateranges: true,
        range: "ALL",
        hide_side_toolbar: true,
        allow_symbol_change: false,
        details: true,
        calendar: true,
        support_host: "https://www.tradingview.com",
      });

      containerCurrent.appendChild(script);
    }
  }, [currencyPairs]);

  return (
    <div className="tradingview-widget-container" ref={container}>
    </div>
  )
}

export default TradeGraph;