import React, { useEffect, useState } from "react";
import { Modal, Button, Table, Tab, Nav, Image } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-multi-lang";
import { useSelector, useDispatch } from "react-redux";
import { getSuccessNotificationMessage } from "../helper/ToastNotification";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import NoDataFound from "../helper/NoDataFound";
import { walletPaymentViewStart } from "../../store/actions/WalletAction";

const WalletTransactionViewModal = (props) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const t = useTranslation("transactions");
  const walletTransactionView =  useSelector((state) => state.wallet.walletPaymentView);

  useEffect(() => {
    dispatch(
      walletPaymentViewStart({
        user_wallet_payment_id: props.show.id,
      })
    );
  }, []);

  const formatNumber = (number) => {
    if (number) {
      return number
        .replace(/\s+/g, "")
        .split("")
        .reduce((seed, next, index) => {
          if (index !== 0 && index % 4 === 0) {
            seed += " ";
          }
          return seed + next;
        }, "");
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="view-modal-transaction"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Wallet Transaction Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {walletTransactionView.loading ? (
            <>
              <Skeleton height={200} className="mb-3" />
              <Skeleton height={200} className="mb-3" />
            </>
          ) : Object.keys(walletTransactionView.data).length > 0 ? (
            <div className="transaction-view-body">
              <div className="transaction-basic-info-wrap">
                <div className="transaction-basic-info-head">
                  <div className="transaction-basic-info-head-titles">
                    <h3 className={`table_value ${walletTransactionView.data.user_wallet_payment.wallet_type == "minus" ? "amt_processing" : `amt_paid` }`}>
                        {walletTransactionView.data.user_wallet_payment.amount_formatted}
                    </h3>
                    {/* <p>Tokens Transferred</p> */}
                    <p>Transaction Amount</p>
                  </div>
                </div>
                <div className="transaction-space"></div>
                <div className="transaction-basic-info-content">
                  {/* <div className="transaction-basic-content-card">
                    <p>Amount Paid</p>
                    <h5>
                      {
                        walletTransactionView.data.user_wallet_payment
                          .from_amount_formatted
                      }{" "}
                    </h5>
                  </div> */}
                  <div className="transaction-basic-content-card">
                    <p>Status</p>
                     <div
                      className={`table_badge  ${walletTransactionView.data.user_wallet_payment.status == 1 ? "success" : walletTransactionView.data.user_wallet_payment.status == 0 ? "pending" : "fail"
                        }`}
                    >
                      {" "}
                      {walletTransactionView.data.user_wallet_payment.status_formatted}
                    </div>
                  </div>
                </div>
              </div>
              <div className="transaction-basic-tab-wrap out_space_mt_1">
                <Tab.Container
                  id="transaction-basic-tab"
                  defaultActiveKey="transaction"
                >
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey="transaction" className="view_tab_btn">
                        Transaction Details
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="transaction">
                      <div className="transaction-tab-frame">
                        {walletTransactionView.data.user_wallet_payment.adjusted_exchange_rate_formatted ? <div className="transaction-tab-info-card">
                          <p>Exchange Rate</p>
                          <h4>
                            {
                              walletTransactionView.data.user_wallet_payment
                                .adjusted_exchange_rate_formatted
                            }
                          </h4>
                        </div> : null}
                        <div className="transaction-tab-info-card">
                          <p>Transaction Date</p>
                          <h4>
                            {
                              walletTransactionView.data.user_wallet_payment
                                .created_at
                            }{" "}
                          </h4>
                        </div>
                        {walletTransactionView.data.user_wallet_payment.service_fee ? <div className="transaction-tab-info-card">
                          <p>Service Fee</p>
                          <h4>{walletTransactionView.data.user_wallet_payment.service_fee}</h4>
                        </div> : null}
                        {walletTransactionView.data.user_wallet_payment
                          .payment_id ? (
                          <div className="transaction-tab-info-card">
                            <p>Payment ID</p>
                            <h4 className="copy-view-card">
                              {" "}
                              <span>
                                {walletTransactionView.data.user_wallet_payment.payment_id.length > 10 ? 
                                `${walletTransactionView.data.user_wallet_payment.payment_id.slice(0,5)}...${walletTransactionView.data.user_wallet_payment.payment_id.slice(-5)}`
                                : walletTransactionView.data.user_wallet_payment.payment_id}
                              </span>{" "}
                              <CopyToClipboard text={walletTransactionView.data.user_wallet_payment.payment_id}>
                                <Button className="action-btn copy-btn item_center" onClick={()=> getSuccessNotificationMessage("Copied!")}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#298BFF"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                    <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                                  </svg>
                                </Button>
                              </CopyToClipboard>
                            </h4>
                          </div>
                        ) : null}
                        {walletTransactionView.data.user_wallet_payment
                          .received_transaction_hash ? (
                          <div className="transaction-tab-info-card">
                            <p>Transaction ID</p>
                            <h4 className="copy-view-card">
                              {" "}
                              <span>
                                {
                                  walletTransactionView.data.user_wallet_payment
                                    .received_transaction_hash
                                }
                              </span>{" "}
                              <CopyToClipboard>
                                <Button className="action-btn copy-btn item_center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#298BFF"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                    <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                                  </svg>
                                </Button>
                              </CopyToClipboard>
                            </h4>
                          </div>
                        ) : null}
                        {walletTransactionView.data.user_wallet_payment?.usage ? <div className="transaction-tab-info-card">
                          <p>Type</p>
                          <h4>{walletTransactionView.data.user_wallet_payment?.usage?.toUpperCase()}</h4>
                        </div> : null}
                        {walletTransactionView.data.user_wallet_payment.payment_type ? <div className="transaction-tab-info-card">
                          <p>Payment Type</p>
                          <h4>{walletTransactionView.data.user_wallet_payment.payment_type}</h4>
                        </div> : null}
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          ) : (
            <NoDataFound />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WalletTransactionViewModal;
