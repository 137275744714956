import React, { useState } from "react";
import { Container, Image, Nav, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import CustomLazyLoad from "../helper/CustomLazyLoad";

const BestCryptoExchange = (props) => {

  const t = useTranslation("best_crypto");

  return (
    <>
      <div className="best-crypto-wrapped sm-padding">
        <Container fluid>
          <Row>
            <Col md={6} lg={6} xl={5}>
              <div className="best-crypto-step-title ">
                <h2>{t("how_does")} <span>{t("heraldEX_work")}?</span></h2>
                <p>{t("best_crypto_content_1")}</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs mb-lg">
              <div className="best-card-full-frame outer-border">
                <div className="best-crypto-step-card fade-color-one" >
                  <div className="num-bold" ><span>01</span></div>
                  <div className="best-card-head">
                    <CustomLazyLoad
                      className="best-crypto-exchange-icon"
                      src={
                        window.location.origin + "/img/how-its-work/signup-icon.svg"
                      }
                      type="image/png"
                    />
                    <h4>{t("sign_up")}</h4>
                  </div>
                  <p>{t("best_crypto_content_2")}</p>
                </div>
              </div>
            </Col>

            <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs mb-lg">
              <div className="best-card-full-frame outer-border">
                <div className="best-crypto-step-card fade-color-five">
                  <div className="num-bold"><span> 02 </span></div>
                  <div className="best-card-head">
                    <CustomLazyLoad
                      className="best-crypto-exchange-icon"
                      src={
                        window.location.origin + "/img/how-its-work/complete-kyc.svg"
                      }
                      type="image/png"
                    />
                    <h4>{t("complete_kyc")}</h4>
                  </div>
                  <p>{t("initiate_onboarding")}</p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs mb-lg">

              <div className="best-card-full-frame outer-border">
                <div className="best-crypto-step-card fade-color-two">
                  <div className="num-bold"> <span> 03 </span></div>
                  <div className="best-card-head">
                    <CustomLazyLoad
                      className="best-crypto-exchange-icon"
                      src={
                        window.location.origin + "/img/how-its-work/deposit-icon.svg"
                      }
                      type="image/png"
                    />
                    <h4>{t("deposit_funds")}</h4>
                  </div>
                  <p>{t("best_crypto_content_3")}</p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs">
              <div className="best-card-full-frame outer-border">
                <div className="best-crypto-step-card fade-color-three">
                  <div className="num-bold"><span> 04 </span></div>
                  <div className="best-card-head">
                    <CustomLazyLoad
                      className="best-crypto-exchange-icon"
                      src={
                        window.location.origin + "/img/how-its-work/buy-icon.svg"
                      }
                      type="image/png"
                    />
                    <h4>{t("buy_crypto")}</h4>
                  </div>
                  <p>{t("best_crypto_content_4")}</p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs">
              <div className="best-card-full-frame outer-border">
                <div className="best-crypto-step-card fade-color-four">
                  <div className="num-bold"><span> 05 </span></div>
                  <div className="best-card-head">
                    <CustomLazyLoad
                      className="best-crypto-exchange-icon"
                      src={
                        window.location.origin + "/img/how-its-work/sell-icon.svg"
                      }
                      type="image/png"
                    />
                    <h4>{t("sell_crypto")}</h4>
                  </div>
                  <p>{t("best_crypto_content_5")}</p>
                </div>
              </div>
            </Col>
            {/* <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs">
            <div className="best-card-full-frame outer-border">
              <div className="best-crypto-step-card fade-color-five">
                <div className="num-bold"><span> 05 </span></div>
                <div className="best-card-head">
                <CustomLazyLoad
                    className="best-crypto-exchange-icon"
                    src={
                      window.location.origin + "/img/how-its-work/swap-icon.svg"
                    }
                    type="image/png"
                  />
                <h4>Swap Cryptocurrencies</h4>
                </div>
                <p>Looking to switch between different cryptocurrencies? Use our swap feature to instantly exchange one crypto for another. Just pick the currencies you want to swap, confirm the amount, and you’re good to go.</p>
              </div>
              </div>
            </Col> */}
            {/* <Col md={6} lg={6} xl={4} className="resp-marg-btm-xs">
            <div className="best-card-full-frame outer-border">
              <div className="best-crypto-step-card fade-color-six">
                <div className="num-bold"><span> 06 </span></div>
                <div className="best-card-head">
                <CustomLazyLoad
                    className="best-crypto-exchange-icon"
                    src={
                      window.location.origin + "/img/how-its-work/trade-icon.svg"
                    }
                    type="image/png"
                  />
                <h4>Trade Crypto</h4>
                </div>
                <p>For those who want to take their crypto journey to the next level, explore our trading platform. Buy, sell, and manage your crypto assets in real time with advanced trading tools and competitive fees.</p>
              </div>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BestCryptoExchange;
