import React, { useEffect } from 'react';
import { Offcanvas, Tab, Nav, Button } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { singleRecipientStart } from '../../store/actions/RecipientAction';
import { useTranslation } from 'react-multi-lang';
import NoDataFound from '../helper/NoDataFound';
import Skeleton from 'react-loading-skeleton';
import { getSuccessNotificationMessage } from '../helper/ToastNotification';
import { getSupportedCountriesStart } from '../../store/actions/BuyAction';

const BankDetailsViewModal = (props) => {
    const t = useTranslation("recipient.recipient_view");
    const dispatch = useDispatch();
    const singleRecipient = useSelector(
        (state) => state.recipient.singleRecipient
    );
      const supportedCountries = useSelector((state) => state.buy.supportedCountries);

    useEffect(() => {
        dispatch(singleRecipientStart({ beneficiary_account_id: props.show }));
        dispatch(getSupportedCountriesStart())
    }, [props.show]);

    return (
        <>
            <Offcanvas {...props} className="view-canvas-modal">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Account Details</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="bank-details-view-wrapped">
                        {(singleRecipient.loading ||supportedCountries.loading) ? (
                            <>
                                <div className="bank-view-account-card">
                                    <Skeleton height={15} width={120} />
                                    <Skeleton height={15} width={130} />
                                </div>
                                <Skeleton height={100} style={{ marginBottom: "1em" }} />

                                <Skeleton height={280} />

                            </>) :
                            Object.keys(singleRecipient.data).length > 0 &&Object.keys(supportedCountries.data).length > 0 ?
                                (
                                    <>
                                        <div className="bank-view-account-card">
                                            <div className="bank-ac-lable">{t("account_number")}</div>
                                            <div className="bank-ac-value">{singleRecipient.data.beneficiary_account.account_number}</div>
                                        </div>
                                        <div className="bank-user-card">
                                            <div className="bank-use-avater">
                                                <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 100 100" width="48" height="48">
                                                    <path d="M50 2.5C23.81 2.5 2.5 23.81 2.5 50c0 12.103 4.555 23.627 12.814 32.448C24.258 92.015 36.896 97.5 50 97.5s25.742-5.485 34.686-15.052C92.945 73.628 97.5 62.103 97.5 50 97.5 23.81 76.19 2.5 50 2.5m0 15.57c10.436 0 18.896 8.46 18.896 18.896S60.436 55.863 50 55.863s-18.896-8.46-18.896-18.897c0-10.435 8.46-18.896 18.896-18.896M23.117 71.275c4.485-4.476 10.672-7.25 17.51-7.25h18.747c9.786 0 18.255 5.679 22.275 13.93-.263.306-.535.605-.807.894C72.89 87.354 61.647 92.234 50 92.234s-22.89-4.88-30.842-13.385c-.272-.29-.544-.588-.807-.895a24.7 24.7 0 0 1 4.766-6.68"></path>
                                                </svg>
                                            </div>
                                            <div className="bank-use-info">
                                                <div className="bank-use-name"> {singleRecipient.data.beneficiary_account.first_name} {singleRecipient.data.beneficiary_account.last_name}</div>
                                                <div className="bank-use-type">{singleRecipient.data.beneficiary_account.account_type}</div>
                                            </div>
                                        </div>
                                        <div className="bank-other-details">
                                            <Tab.Container id="view-transaction-tabs" defaultActiveKey="details">
                                                <Nav variant="pills" className="view-tabs" >
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="details">{t("beneficiary")}</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="bank">{t("personal_details")}</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="details">
                                                        <div className="bank-tabs-body">
                                                            <div className="bank-info-card">
                                                                <div className="bank-label">{t("bank_account_id")}</div>
                                                                <div className="bank-card-pay">{singleRecipient.data.beneficiary_account.unique_id}<CopyToClipboard
                                                                    text={singleRecipient.data.beneficiary_account.unique_id}
                                                                >
                                                                    <Button
                                                                        onClick={() =>
                                                                            getSuccessNotificationMessage(t("copied"))
                                                                        }
                                                                        className="copy-view-btn"
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            xmlSpace="preserve"
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 24 24"
                                                                        >
                                                                            <path
                                                                                fill="#298bff"
                                                                                d="M18.375 6.25h-8.75A3.044 3.044 0 0 0 6.25 9.625v8.75a3.044 3.044 0 0 0 3.375 3.375h8.75a3.044 3.044 0 0 0 3.375-3.375v-8.75a3.044 3.044 0 0 0-3.375-3.375m1.875 12.125c0 1.332-.543 1.875-1.875 1.875h-8.75c-1.332 0-1.875-.543-1.875-1.875v-8.75c0-1.332.543-1.875 1.875-1.875h8.75c1.332 0 1.875.543 1.875 1.875zM3.75 5.62v8.76c0 1.2.483 1.493.642 1.59a.751.751 0 0 1-.784 1.28 3.1 3.1 0 0 1-1.358-2.87V5.62a3.068 3.068 0 0 1 3.37-3.37h8.76a3.1 3.1 0 0 1 2.87 1.358.75.75 0 1 1-1.279.784c-.1-.159-.393-.642-1.591-.642H5.62c-1.328 0-1.87.542-1.87 1.87"
                                                                                data-original="#000000"
                                                                            ></path>
                                                                        </svg>
                                                                    </Button>
                                                                </CopyToClipboard></div>
                                                            </div>
                                                            <div className="bank-info-card">
                                                                <div className="bank-label">{t("bank_code")}</div>
                                                                <div className="bank-value"> {singleRecipient.data.beneficiary_account.code}</div>
                                                            </div>
                                                            <div className="bank-info-card">
                                                                <div className="bank-label">{t("account_type")}</div>
                                                                <div className="bank-value">{singleRecipient.data.beneficiary_account.account_type}</div>
                                                            </div>
                                                            <div className="bank-info-card">
                                                                <div className="bank-label">{t("receiving_currency")}</div>
                                                                <div className="bank-value">{singleRecipient.data.beneficiary_account.receiving_currency}</div>
                                                            </div>
                                                            <div className="bank-info-card">
                                                                <div className="bank-label">{t("bank_name")}</div>
                                                                <div className="bank-value">{singleRecipient.data.beneficiary_account.additional_details.service_bank}</div>
                                                            </div>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="bank">
                                                        <div className="bank-tabs-body">
                                                            <div className="bank-info-card">
                                                                <div className="bank-label">{t("email")}</div>
                                                                <div className="bank-value">{singleRecipient.data.beneficiary_account.email}</div>
                                                            </div>
                                                            <div className="bank-info-card">
                                                                <div className="bank-label">{t("phone_number")}</div>
                                                                <div className="bank-value">{singleRecipient.data.beneficiary_account.mobile}</div>
                                                            </div>
                                                            <div className="bank-info-card">
                                                                <div className="bank-label">{t("country")}</div>
                                                                <div className="bank-value">{
                                                                    supportedCountries.data.supported_countries.find(
                                                                        (country) =>
                                                                            country.country_code == singleRecipient.data.beneficiary_account.additional_details.country
                                                                    )?.country_name
                                                                }</div>
                                                            </div>

                                                            <div className="bank-info-card">
                                                                <div className="bank-label">{t("city")}</div>
                                                                <div className="bank-value">{singleRecipient.data.beneficiary_account.additional_details.city}</div>
                                                            </div>
                                                            <div className="bank-info-card">
                                                                <div className="bank-label">{t("zip_code")}</div>
                                                                <div className="bank-value">{singleRecipient.data.beneficiary_account.additional_details.postal_code}</div>
                                                            </div>
                                                            <div className="bank-info-card p-0">
                                                                <div className="bank-label">{t("full_address")}</div>
                                                                <div className="bank-value">{singleRecipient.data.beneficiary_account.additional_details.primary_address},{" "}
                                                                    {singleRecipient.data.beneficiary_account.additional_details.secondary_address},{singleRecipient.data.beneficiary_account.additional_details.city},{" "}
                                                                    {singleRecipient.data.beneficiary_account.additional_details.state}, {singleRecipient.data.beneficiary_account.additional_details.postal_code}</div>
                                                            </div>
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </div>
                                    </>
                                ) : <NoDataFound />}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default BankDetailsViewModal;