import React, { useEffect, useState } from "react";
import { Image, Form, Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./Exchange.css";
import ExchangeCrptoForm from "./ExchangeCrptoForm";
import ExchangeWalletAddressSec from "./ExchangeWalletAddressSec";
import ExchangeSuccess from "./ExchangeSuccess";
import { resetTokenExchangeData } from "../../store/actions/BuyAction";

const ExchangeIndex = () => {

  const dispatch = useDispatch();

  const tokenExchangeData = useSelector((state) => state.buy.tokenExchangeData);

  useEffect(() => {
    dispatch(resetTokenExchangeData())
  }, [])
  
  return (
    <>
      <div className="exchange-sec">
        <Container fluid>
          <div className="buy-crypto-sec">
            <Row className="justify-content-md-center">
              <Col md={10} xl={7} lg={7}>
                <div className="buy-flow-product-sec">
                  {tokenExchangeData.step == 1 ? (
                    <div className="step-1">
                      <ExchangeCrptoForm />
                    </div>
                  ) : tokenExchangeData.step == 2 ? (
                    <div className="step-2">
                      <ExchangeWalletAddressSec  />
                    </div>
                  ) :tokenExchangeData.step == 3 ? (
                    <div className="step-3">
                      <ExchangeSuccess />
                    </div>
                  )  : null}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ExchangeIndex;
