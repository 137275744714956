import React, { useEffect, useState, useRef } from "react";
import { Form, InputGroup, Table, Button, Image } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import {
    tokenTradeTransactionListStart,
    exportTradeTransactionStart,
} from "../../store/actions/BuyAction";
import { useTranslation } from "react-multi-lang";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Field, Form as FORM, Formik } from "formik";
import * as Yup from "yup";
import Pagination from "../helper/Pagination";
import { useSelector, useDispatch } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage, getErrorNotificationMessage } from "../helper/ToastNotification";
import Select from "react-select";
import DatePicker from "react-multi-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import InputIcon from "react-multi-date-picker/components/input_icon";
import dayjs from "dayjs";
import NoTransactionData from "../helper/NoTransactionData";
import TradeTransactionView from "./TransactionSingleView/TradeTransactionView";
import { Tooltip } from 'react-tooltip'
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const TradeTransactionTable = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const [searchParams] = useSearchParams();
    const t = useTranslation("transactions");
    const isMobile = useSelector((state) => state.user.isMobile);

    const options = [
        { value: "0", label: t("processing") },
        { value: "1", label: t("success") },
        { value: "2 ", label: t("failed") },
    ];

    const customStyles = {
        ///.....
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({
            ...provided,
            width: "150px",
            zIndex: 9999,
            left: "0px",
            borderRadius: "10px",
            overflow: "hidden",
        }),
        menuList: (provided) => ({
            ...provided,
            padding: 0,
            width: "150px",
            fontSize: "0.85em",
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                borderRadius: "8px",
                backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar": {
                width: "4px",
                backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar-thumb": {
                borderRadius: "3px",
                boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
                backgroundColor: "#555",
            },
        }),
        container: (provided) => ({ ...provided, width: "auto" }),
        control: (provided) => ({
            ...provided,
            backgroundColor: "transparent!important",
            border: "1px solid #e5eaf4!important",
            borderRadius: "10px!important",
            boxShadow: "none!important",
            height: "48px",
            minWidth: "150px",
            cursor: "pointer",
            fontSize: "0.9em",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#6b7178",
            fontSize: "0.85em",
            fontWeight: "500",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "#878e96",
            display: "flex",
            alignItems: "center",
            gap: "0.5em",
            fontSize: "0.9em",
            fontWeight: "400",
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            color: "#298bff!important",
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            fill: "#298bff!important",
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: "none",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            svg: {
                fill: "#111",
            },
        }),
        // option: (provided, state) => ({
        //   ...provided,
        //   backgroundColor: state.isFocused || state.isSelected ? "#F9C201" : "#fff",
        //   color: state.isFocused || state.isSelected ? "#000" : "#000",
        //   ":hover": {
        //     backgroundColor: "#F9C201",
        //     color: "#000",
        //   },
        // }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? "#F9C201"
                : state.isFocused
                    ? "#f0f0f0"
                    : "transparent",
            color: state.isSelected || state.isFocused ? "#000" : "#000",
            ":hover": {
                backgroundColor: "#f0f0f0",
                color: "#000",
            },
        }),
    };

    const [skipRender, setSkipRender] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [transactionId, setTransactionId] = useState("");
    const [value, setValue] = useState([null, null]);
    const tokenTradeTransactionList = useSelector(
        (state) => state.buy.tokenTradeTransactionList
    );
    const exportTradeTransaction = useSelector(
        (state) => state.buy.exportTradeTransaction
    );
    const [filterStatus, setFilterStatus] = useState(false);
    const [filter, setFilter] = useState({
        search_key: searchParams.get("search_key")
            ? searchParams.get("search_key")
            : "",
        status: "",
        from_date: "",
        to_date: "",
    });
    const [close, setClose] = useState(false);
    const [page, setPage] = useState(
        searchParams.get("page") ? searchParams.get("page") : 1
    );
    const calenderRefs = useRef(null);
    const [dateValues, setDateValues] = useState({
        from_date: "",
        to_date: "",
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(
            tokenTradeTransactionListStart({
                ...filter,
                skip: 12 * (page - 1),
                take: 12,
            })
        );
    }, [filter, page]);

    const handleDateChange = (values) => {
        if (values) {
            setValue(values);
            formRef?.current?.setFieldValue(
                "from_date",
                values && values.length > 0
                    ? JSON.stringify(new Date(values[0])).slice(1, 11)
                    : ""
            );
            formRef?.current?.setFieldValue(
                "to_date",
                values && values.length > 1
                    ? JSON.stringify(new Date(values[1])).slice(1, 11)
                    : ""
            );
            setDateValues({
                from_date:
                    values.length > 0
                        ? JSON.stringify(new Date(values[0])).slice(1, 11)
                        : "",
                to_date:
                    values.length > 1
                        ? JSON.stringify(new Date(values[1])).slice(1, 11)
                        : "",
            });
        } else {
            setDateValues({
                from_date: "",
                to_date: "",
            });
            formRef?.current?.setFieldValue("from_date", "");
            formRef?.current?.setFieldValue("to_date", "");
            calenderRefs.current.closeCalendar();
            setFilter({
                ...filter,
                skip: 12 * (page - 1),
                take: 12,
                from_date: "",
                to_date: "",
            });
        }
    };

    const handleExport = () => {
        dispatch(
            exportTradeTransactionStart({
                ...filter,
                from_date: dateValues.from_date,
                to_date: dateValues.to_date,
            })
        );
    };

    const handlePageClick = ({ selected }) => {
        let params = searchParams.get("search_key")
            ? `search_key=${searchParams.get("search_key")}&`
            : "";
        navigate(`/transactions?${params}page=${selected + 1}`);
    };

    useEffect(() => {
        if (!skipRender) {
            setPage(searchParams.get("page") ? searchParams.get("page") : 1);
            setFilter({
                ...filter,
                search_key: searchParams.get("search_key")
                    ? searchParams.get("search_key")
                    : "",
                from_date: dateValues.from_date,
                to_date: dateValues.to_date,
            });
        }
        setSkipRender(false);
    }, [searchParams.get("page"), searchParams.get("search_key")]);

    useEffect(() => {
        if (!skipRender) {
            setPage(searchParams.get("page") ? searchParams.get("page") : 1);
        }
        setSkipRender(false);
    }, [searchParams.get("page")]);

    useEffect(() => {
        if (
            !skipRender &&
            !exportTradeTransaction.buttonDisable &&
            Object.keys(exportTradeTransaction.data).length > 0
        ) {
            window.open(exportTradeTransaction.data.url, "_blank", "noreferrer");
        }
        setSkipRender(false);
    }, [exportTradeTransaction]);

    const validationSchema = Yup.object().shape({
        search_key: Yup.string()
            .required(t("required"))
            .matches(/^(?=\S*$)/, t("white_space")),
    });

    return (
        <>
            <div className="wallet_table_head item_line_between   out_space_my_1_5">
                {(filter.status !== "" ||
                    filter.search_key !== "" ||
                    filter.from_date !== "" ||
                    filter.to_date !== "" ||
                    (Object.keys(tokenTradeTransactionList.data).length > 0 &&
                        tokenTradeTransactionList.data.total > 0 &&
                        filter.status == "" &&
                        filter.search_key == "" &&
                        filter.from_date == "" &&
                        filter.to_date == "")) && (
                        <>
                            <div className="table_head_titles">
                                <h3>Trade Transactions</h3>

                                <h5 className="wallet_count item_center">{tokenTradeTransactionList.data.total}</h5>
                            </div>

                            <div className="table_head_action">
                                <Formik
                                    initialValues={{
                                        search_key: searchParams.get("search_key")
                                            ? searchParams.get("search_key")
                                            : "",
                                        sort_by: searchParams.get("sort_by")
                                            ? searchParams.get("sort_by")
                                            : filter.sort_by,
                                        from_date: "",
                                        to_date: "",
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={(val) => {
                                        setFilter({
                                            ...filter,
                                            search_key: val.search_key,
                                            from_date: dateValues.from_date,
                                            to_date: dateValues.to_date,
                                        });

                                        navigate(
                                            searchParams.get("sort_by")
                                                ? `/transactions?search_key=${val.search_key
                                                }&sort_by=${searchParams.get("sort_by")}`
                                                : `/transactions?search_key=${val.search_key}`,
                                            { state: { value: value } }
                                        );
                                    }}
                                    innerRef={formRef}
                                >
                                    {({ values, setFieldValue, resetForm, submitForm }) => (
                                        <FORM>
                                            <div className="wallet_sort_form ">
                                                <div className="wallet_sort_search">
                                                    <InputGroup className="mb-0">
                                                        <Field
                                                            name="search_key"
                                                            placeholder={t("search")}
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                {
                                                                    e.target.value == "" && submitForm();
                                                                    formRef.current.setFieldValue(
                                                                        "search_key",
                                                                        e.target.value
                                                                    );
                                                                }
                                                            }}
                                                            onKeyPress={(event) => {
                                                                if (event.key === "Enter") {
                                                                    event.preventDefault();
                                                                    formRef.current.handleSubmit();
                                                                }
                                                            }}
                                                        />
                                                        <InputGroup.Text id="basic-addon2">
                                                            {searchParams.get("search_key") ? (
                                                                <Button
                                                                    type="button"
                                                                    className="action-btn link-btn"
                                                                    onClick={() => {
                                                                        setFieldValue("search_key", "");
                                                                        setFilter({
                                                                            ...filter,
                                                                            search_key: "",
                                                                            from_date: dateValues.from_date,
                                                                            to_date: dateValues.to_date,
                                                                        });
                                                                        navigate(
                                                                            searchParams.get("sort_by")
                                                                                ? `/transactions?sort_by=${searchParams.get(
                                                                                    "sort_by"
                                                                                )}`
                                                                                : `/transactions`
                                                                        );
                                                                    }}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-x"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    type="submit"
                                                                    className="action-btn link-btn"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                    >
                                                                        <path
                                                                            fill="#171717"
                                                                            d="M11.5 21.75c-5.65 0-10.25-4.6-10.25-10.25S5.85 1.25 11.5 1.25s10.25 4.6 10.25 10.25-4.6 10.25-10.25 10.25zm0-19c-4.83 0-8.75 3.93-8.75 8.75s3.92 8.75 8.75 8.75 8.75-3.93 8.75-8.75-3.92-8.75-8.75-8.75zM22 22.75c-.19 0-.38-.07-.53-.22l-2-2a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l2 2c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22z"
                                                                        ></path>
                                                                    </svg>
                                                                </Button>
                                                            )}
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </div>
                                                <div className="wallet_sort_date">
                                                    <Form.Group>
                                                        <InputGroup className="mb-0">
                                                            <DatePicker
                                                                value={value}
                                                                style={{ padding: "1em" }}
                                                                format="DD/MM/YYYY"
                                                                placeholder="Select Date Range"
                                                                onChange={(date) => {
                                                                    handleDateChange(date);
                                                                }}
                                                                range
                                                                numberOfMonths={isMobile?1:2}
                                                                ref={calenderRefs}
                                                                maxDate={
                                                                    new Date(new Date().setHours(23, 59, 59, 999))
                                                                }
                                                                editable={false}
                                                                onOpenPickNewDate={false}
                                                                render={!close && <InputIcon placeholder="Select Date Range" />}
                                                            >
                                                                <div className="new-action-frame layout-element p-2 m-0">
                                                                    <Button
                                                                        className="new-overlay-btn"
                                                                        onClick={() => {
                                                                            setValue([null, null]);
                                                                            calenderRefs.current.closeCalendar();
                                                                        }}
                                                                    >
                                                                        Close
                                                                    </Button>
                                                                    <Button
                                                                        className="new-primary-btn"
                                                                        onClick={() => {
                                                                            if (values.from_date || values.to_date) {
                                                                                setFilter({
                                                                                    ...filter,
                                                                                    skip: 12 * (page - 1),
                                                                                    take: 12,
                                                                                    from_date: dateValues.from_date,
                                                                                    to_date: dateValues.to_date,
                                                                                });
                                                                                calenderRefs.current.closeCalendar();
                                                                                setClose(true);
                                                                            } else
                                                                                getErrorNotificationMessage(
                                                                                    "Please select date"
                                                                                );
                                                                        }}
                                                                    >
                                                                        Apply
                                                                    </Button>
                                                                </div>
                                                            </DatePicker>
                                                            {close && (
                                                                <>
                                                                    <button
                                                                        type="button"
                                                                        className=" date-cancel"
                                                                        onClick={() => {
                                                                            setDateValues({
                                                                                from_date: "",
                                                                                to_date: "",
                                                                            });
                                                                            setValue([null, null]);
                                                                            setClose(false);
                                                                            setFilter({
                                                                                ...filter,
                                                                                from_date: "",
                                                                                to_date: "",
                                                                            });
                                                                        }}
                                                                    >
                                                                        <svg
                                                                            height="20"
                                                                            width="20"
                                                                            viewBox="0 0 20 20"
                                                                            aria-hidden="true"
                                                                            focusable="false"
                                                                            class="css-tj5bde-Svg"
                                                                        >
                                                                            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                                                        </svg>
                                                                    </button>
                                                                </>
                                                            )}
                                                        </InputGroup>
                                                    </Form.Group>
                                                </div>
                                                <div className="wallet_sort_filter">
                                                    <Select
                                                        className="fillert-drop"
                                                        options={options}
                                                        isClearable
                                                        styles={customStyles}
                                                        isSearchable={false}
                                                        value={filterStatus || null}
                                                        onChange={(selectedOption) => {
                                                            if (selectedOption) {
                                                                setFilter({
                                                                    ...filter,
                                                                    status: selectedOption.value,
                                                                });
                                                                setFilterStatus(selectedOption);
                                                                navigate(
                                                                    filter.search_key
                                                                        ? `/transactions?search_key=${filter.search_key}&status=${selectedOption.value}`
                                                                        : `/transactions?status=${selectedOption.value}`
                                                                );
                                                            } else {
                                                                const newFilter = { ...filter };
                                                                delete newFilter.status;
                                                                setFilter(newFilter);

                                                                navigate(
                                                                    filter.search_key
                                                                        ? `/transactions?search_key=${filter.search_key}`
                                                                        : "/transactions"
                                                                );
                                                                setFilterStatus(null);
                                                            }
                                                        }}
                                                        placeholder={
                                                            <div className="placeholder-flex">
                                                                Status
                                                            </div>
                                                        }
                                                    />
                                                </div>
                                                <div className="wallet_sort_export">
                                                    <Button
                                                        type="submit"
                                                        className="action-btn gradient"
                                                        onClick={handleExport}
                                                        disabled={
                                                            exportTradeTransaction.buttonDisable ||
                                                            tokenTradeTransactionList.loading ||
                                                            !Object.keys(tokenTradeTransactionList.data).length > 0 ||
                                                            !tokenTradeTransactionList.data.trade_transactions.length > 0
                                                        }
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <path
                                                                fill="#171717"
                                                                d="M11.88 14.99c-.19 0-.38-.07-.53-.22l-2.56-2.56a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l2.03 2.03 2.03-2.03c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-2.56 2.56c-.15.15-.34.22-.53.22z"
                                                            ></path>
                                                            <path
                                                                fill="#171717"
                                                                d="M11.88 14.92c-.41 0-.75-.34-.75-.75V4c0-.41.34-.75.75-.75s.75.34.75.75v10.17c0 .41-.34.75-.75.75z"
                                                            ></path>
                                                            <path
                                                                fill="#171717"
                                                                d="M12 20.93c-5.15 0-8.75-3.6-8.75-8.75 0-.41.34-.75.75-.75s.75.34.75.75c0 4.27 2.98 7.25 7.25 7.25s7.25-2.98 7.25-7.25c0-.41.34-.75.75-.75s.75.34.75.75c0 5.15-3.6 8.75-8.75 8.75z"
                                                            ></path>
                                                        </svg>
                                                        <span>{t("buy_transaction.export")}</span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </FORM>
                                    )}
                                </Formik>
                            </div>
                        </>
                    )}
            </div>
            {tokenTradeTransactionList.loading ? (
                <Skeleton count={4} height={80} />
            ) : Object.keys(tokenTradeTransactionList.data).length > 0 &&
                tokenTradeTransactionList.data.total > 0 ? (
                <React.Fragment>
                    {tokenTradeTransactionList.data.total > 12 ? (
                        <div className="new-billings-pagination-sec">
                            <Pagination
                                page={page}
                                pageCount={Math.ceil(tokenTradeTransactionList.data.total / 12)}
                                handlePageClick={handlePageClick}
                            />
                        </div>
                    ) : null}
                    <div className="transaction-table">
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>{t("trading_transaction.payment_id")}</th>
                                    <th>{t("trading_transaction.date")}</th>
                                    <th>{t("trading_transaction.currency_pair")}</th>
                                    <th>{t("trading_transaction.token")}</th>
                                    {/* <th>{t("buy_transaction.exchange_value")}</th> */}
                                    {/* <th>{t("buy_transaction.amount")}</th> */}
                                    <th>{t("trading_transaction.from")}</th>
                                    <th>{t("trading_transaction.to")}</th>
                                    {/* <th>{t("buy_transaction.requested_date")}</th> */}
                                    <th>{t("trading_transaction.status")}</th>
                                    <th>{t("trading_transaction.action")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tokenTradeTransactionList.data.trade_transactions.map((transactions) => (
                                    <tr>
                                        <td>
                                            {transactions.unique_id ? (
                                                <div className="td-copy-token">
                                                    { transactions.unique_id}
                                                    <CopyToClipboard
                                                        text={transactions.unique_id}
                                                        onCopy={() =>
                                                            getSuccessNotificationMessage(
                                                                t(
                                                                    "buy_transaction.payment_id_copied_to_clipboard"
                                                                )
                                                            )
                                                        }
                                                    >
                                                        <Button
                                                            className="copy-token-btn"
                                                            data-tooltip-id="my-tooltip"
                                                            data-tooltip-content="Copy"
                                                            data-tooltip-place="top"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                xmlSpace="preserve"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <path
                                                                    fill="#298bff"
                                                                    d="M18.375 6.25h-8.75A3.044 3.044 0 0 0 6.25 9.625v8.75a3.044 3.044 0 0 0 3.375 3.375h8.75a3.044 3.044 0 0 0 3.375-3.375v-8.75a3.044 3.044 0 0 0-3.375-3.375m1.875 12.125c0 1.332-.543 1.875-1.875 1.875h-8.75c-1.332 0-1.875-.543-1.875-1.875v-8.75c0-1.332.543-1.875 1.875-1.875h8.75c1.332 0 1.875.543 1.875 1.875zM3.75 5.62v8.76c0 1.2.483 1.493.642 1.59a.751.751 0 0 1-.784 1.28 3.1 3.1 0 0 1-1.358-2.87V5.62a3.068 3.068 0 0 1 3.37-3.37h8.76a3.1 3.1 0 0 1 2.87 1.358.75.75 0 1 1-1.279.784c-.1-.159-.393-.642-1.591-.642H5.62c-1.328 0-1.87.542-1.87 1.87"
                                                                    data-original="#000000"
                                                                ></path>
                                                            </svg>
                                                        </Button>
                                                    </CopyToClipboard>
                                                </div>) : (
                                                <h5 className="table_code">{t("n_a")}</h5>
                                            )}
                                        </td>
                                        <td>
                                            {transactions.created_at_formatted}
                                        </td>
                                        <td>
                                            <div className="token-date">
                                                {transactions.pair}
                                            </div>
                                        </td>
                                        <td>
                                        {transactions.to_amount_formatted.slice(-3)}
                                        </td>
                                        <td>{transactions.from_amount_formatted}</td>
                                        <td>{transactions.to_amount_formatted}</td>
                                        <td>
                                            <div
                                                className={`token-status-badges   ${transactions.status == 1 ? "success" : transactions.status == 0 ? "pending" : "fail"
                                                    }`}
                                            >
                                                {" "}
                                                {transactions.status_formatted}
                                            </div>
                                        </td>
                                        <td>
                                            <Button className="view-btn"
                                                onClick={() => {
                                                    setModalShow(transactions);
                                                    setTransactionId(transactions.id);
                                                }}
                                            >
                                                {t("view")}
                                            </Button>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </Table>
                    </div>
                    {tokenTradeTransactionList.data.total > 12 ? (
                        <div className="new-billings-pagination-sec">
                            <Pagination
                                page={page}
                                pageCount={Math.ceil(tokenTradeTransactionList.data.total / 12)}
                                handlePageClick={handlePageClick}
                            />
                        </div>
                    ) : null}
                </React.Fragment>
            ) : (
                <NoTransactionData />
            )}
            {modalShow &&
                <TradeTransactionView
                    placement="end" name="end"
                    show={modalShow}
                    transactionId={transactionId}
                    onHide={() => setModalShow(false)}
                />}
                            <Tooltip id="my-tooltip" />
        </>
    );
};

export default TradeTransactionTable;
