import React, {  useEffect, useState, useRef } from "react";
import {
  Modal,
  Button,
  Form,
  Image,
} from "react-bootstrap";
import "../Modals/Wallet.css";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CommonCenterLoader from "../helper/CommonCenterLoader";
import {
  depositTokenStart,
  getAdminCryptoAccountStart,
  getCurrenciesListStart,
} from "../../store/actions/BuyAction";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/ToastNotification";
import { useTranslation } from "react-multi-lang";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import {
  fetchAllTransactionStart,
  fetchSingleWalletViewStart,
  fetchWalletDetailsStart,
} from "../../store/actions/WalletAction";
import NoDataFound from "../helper/NoDataFound";
import TransactionSuccess from "../helper/TransactionSuccess";
import Select from "react-select";
import { networkOption } from "../helper/NetworkOption";
import QRCode from "react-qr-code";
import ButtonStepper from "../Buy/ButtonStepper";

const DepositCryptoModal = (props) => {
  const t = useTranslation("deposit_modal");
  const formRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState(false);
  const [step, setStep] = useState(1);
  const [url, setUrl] = useState(null);
  const profile = useSelector((state) => state.user.profile);
  const currencies = useSelector((state) => state.buy.currencies);
  const [minAmount, setMinAmount] = useState(0.00000001);
  const [maxAmount, setMaxAmount] = useState(100);
  const [networkType, setNetworkType] = useState(null);
  const [cryptoDepositStep, setCryptoDepositStep] = useState(1);
  const [retry, setRetry] = useState(true);
  const [paymentType, setPaymentType] = useState(props.show.currency_type == "crypto" ? "card" : "bank");
  const [buttonStepper, setButtonStepper] = useState(false);
  const [validDecimalValue, setValidDecimalValue] = useState();
  const dispatch = useDispatch();
  const adminCryptoAccount = useSelector((state) => state.buy.adminCryptoAccount);
  const [skipRender, setSkipRender] = useState(true);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const depositToken = useSelector((state) => state.buy.depositToken);

  const handleSubmit = (values) => {
    dispatch(
      depositTokenStart({
        ...values,
        token_type: props.tokenType,
        network_type: networkType?.value ? networkType?.value : "",
      })
    );
  };

  const validationSchema = Yup.object().shape({
    tokens: Yup.number()
      .required(t("required"))
      .min(
        !isNaN(minAmount) && minAmount,
        t("tokens.invalid", {
          value: !isNaN(minAmount) && parseFloat(minAmount).toFixed(8),
        })
      )
      .max(maxAmount, t("tokens.max_invalid", { value: maxAmount })),
    transaction_hash: Yup.string().when("is_crypto", {
      is: "1",
      then: Yup.string()
        .required(t("required"))
        .min(3, t("transaction_id.invalid"))
      // .max(66, t("transaction_id.max_invalid", { value: 66 })),
    }),
    // payment_file: Yup.string().required(t("please_upload_payment_screenshot")),
  });

  useEffect(() => {
    if (
      (networkType != null && ["USDT", "USDC"].includes(props.token)) ||
      (!["USDT", "USDC"].includes(props.token) &&
        props.show.currency_type == "crypto")
    ) {
      dispatch(
        getAdminCryptoAccountStart({
          network_type: !["USDT", "USDC"].includes(props.token)
            ? props.token
            : networkType?.value, user_id: profile.data?.user_id ? profile.data?.user_id : ""
        })
      );
      // setCryptoDepositStep(2);
    }
  }, [networkType, retry]);

  useEffect(() => {
    if (
      !skipRender &&
      !adminCryptoAccount.loading &&
      Object.keys(adminCryptoAccount.data).length > 0
    ) {
      // setCryptoDepositStep(2);
    }
    setSkipRender(false);
  }, [adminCryptoAccount]);

  useEffect(() => {
    if (
      !skipRender &&
      !depositToken.loading &&
      Object.keys(depositToken.data).length > 0
    ) {
      //  props.onHide();
      setButtonStepper(true)
      setTimeout(() => {
        setUrl(depositToken.data?.deposit_transaction?.explorer_url);
        setTransactionSuccess(true);
        dispatch(fetchWalletDetailsStart())
      }, 3000);

    }
    setSkipRender(false);
  }, [depositToken]);

  function handleKeyPress(event) {
    if (!/^[0-9.]$/.test(event.key) && event.key == "e") {
      event.preventDefault();
    }
  }

  const onHide = () => {
    setTransactionSuccess(false);
    props.onHide();
   dispatch(
         fetchWalletDetailsStart()
       );
    dispatch(
      fetchAllTransactionStart({
        user_wallet_id: props.user_wallet_id,
        skip: 0,
        take: 12,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !currencies.loading &&
      Object.keys(currencies.data).length > 0
    ) {
      setMinAmount(
        parseFloat(
          currencies.data?.user_commissions?.min_deposit_amount
        ) <= 0
          ? 0.00000001
          : parseInt(
            currencies.data?.user_commissions?.min_deposit_amount
          ).toFixed(8)
      );

      setMaxAmount(
        parseFloat(
          currencies.data?.user_commissions?.max_deposit_amount
        ) <= 0
          ? 100000
          : parseInt(
            currencies.data?.user_commissions?.max_deposit_amount
          ).toFixed(8)
      );

    }
    setSkipRender(false);
  }, [currencies]);

  const customStylesone = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "10px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid #E5EAF4!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "42px",
      cursor: "pointer",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#7d7d7d",
      fontSize: "0.8em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#DDE1E6!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#DDE1E6!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#F9C201"
        : state.isFocused
          ? "#f0f0f0"
          : "transparent",
      color: state.isSelected || state.isFocused ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#f0f0f0",
        color: "#000",
      },
    }),
  };

  useEffect(() => {
    if(!Object.keys(currencies.data).length > 0)
    dispatch(getCurrenciesListStart({ user_id: profile.data?.user_id ? profile.data?.user_id : "" }));
  }, []);

  useEffect(() => {
    if (cryptoDepositStep == 1) {
      !networkType && setNetworkType(networkOption[0])
    }
  }, [cryptoDepositStep])

  useEffect(() => {
    if (!currencies.loading && Object.keys(currencies.data).length > 0) {
      let decimal = (currencies.data.balance_steps.find(item => item.currency_code == props.tokenType)?.balance_step);
      if (decimal) {
        decimal = decimal.slice(decimal.indexOf('.') + 1, decimal.indexOf('1') + 1).length
        setValidDecimalValue(decimal)
      }
    }
    if (!["USDT", "USDC"].includes(props.tokenType)) {
      setCryptoDepositStep(2);
    }
    setSkipRender(false);
  }, [currencies, props.tokenType]);

  const onAmountChange = (value, setFieldValue) => {
    let decimal = value.slice(value.indexOf('.') + 1).length;
    if (value.includes('.')) {
      if (decimal <= validDecimalValue) {
        if (Number(value) == value) {
          setFieldValue("tokens", value)
        }
      } else {
        getErrorNotificationMessage(`You can input upto ${validDecimalValue} decimal places.`)
      }
    } else {
      if (Number(value) == value) {
        setFieldValue("tokens", value)
      }
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="auth_deposit_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!transactionSuccess ? (
            <div className="deposit_modal_content">
              {cryptoDepositStep == 1 ? (
                <div className="auth-usdt-box">
                  <div className="auth_deposit_content">
                    <div className="auth-deposit-img-sec">
                      <Image
                        className="auth_2fa-sec"
                        src={
                          window.location.origin +
                          "/img/phase_1/auth/deposit-icon.svg"
                        }
                        alt="onboard"
                      />
                    </div>
                    <div className="auth_deposit_form">
                      <Form.Group className="mb-3">
                        <Form.Label>{t("select_network_type")}<span>*</span></Form.Label>
                        < Select
                          className="fillert-drop"
                          options={networkOption}
                          onChange={(select) => setNetworkType(select)}
                          value={networkType}
                          styles={customStylesone}
                          placeholder="Select Network Type"
                        />
                      </Form.Group>
                    </div>
                    <div className="auth_deposit_action item_flex_x_5">
                      <Button
                        type="button"
                        className="action-btn primary"
                        disabled={!networkType}
                        onClick={() => setCryptoDepositStep(2)}
                      >
                        {t("continue")}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : adminCryptoAccount.loading ? (
                <CommonCenterLoader />
              ) : Object.keys(adminCryptoAccount.data).length > 0 ? (
                <>
                  <Formik
                    initialValues={{
                      tokens: "",
                      transaction_hash: "",
                      is_crypto:
                        props.show.currency_type == "crypto" ? "1" : "0",
                    }}
                    validationSchema={validationSchema}
                    innerRef={formRef}
                    onSubmit={handleSubmit}
                  >
                    {({ touched, errors, setFieldValue, values }) => (
                      <FORM>
                        <>
                          <div className="auth_deposit_content">
                            <div className="auth_deposit_avater out_space_mx_1_5">
                              <Image
                                className="auth_deposit-sec"
                                src={
                                  window.location.origin +
                                  "/img/phase_1/auth/deposit-icon.svg"
                                }
                                alt="onboard"
                              />
                            </div>
                            <div className="auth_deposit_titles out_space_mx_1_5">
                              <h2> {t("heading")}</h2>
                              <p>{t("please_deposit_to_the_qrcode_shown_below")}</p>
                            </div>
                            <>
                              {values.is_crypto == 1 && (
                                <>
                                  <div className="auth_deposit_selected out_space_mx_1_5 item_center">
                                    <p className="auth_deposit_type">
                                      {" "}
                                      {t("selected_token")} :{" "}
                                      <span> {props.tokenType} </span>
                                    </p>
                                  </div>

                                  <div className="auth_deposit_qr_scan out_space_mx_1_5">
                                    <div className="admin-wallet-qr-frame">
                                      {adminCryptoAccount.data.admin_crypto_account
                                        .qr_code ? (
                                        <CustomLazyLoad
                                          src={
                                            adminCryptoAccount.data
                                              .admin_crypto_account.qr_code
                                          }
                                          className="auth_deposit_qr"
                                        />
                                      ) :
                                        <QRCode
                                          size={135}
                                          value={adminCryptoAccount.data?.admin_crypto_account.wallet_address}
                                          viewBox={`0 0 256 256`}
                                        />

                                      }
                                    </div>
                                  </div>
                                  <div className="auth_deposit_space item_center out_space_mx_1_5">
                                    <p>{t("or_enter_the_address_manually")}</p>
                                  </div>
                                  <div className="auth_deposit_code">
                                    <div className="auth_deposit_code_card">
                                      <span>
                                        {" "}
                                        {
                                          adminCryptoAccount.data
                                            .admin_crypto_account.wallet_address
                                        }
                                      </span>{" "}
                                    </div>

                                    <CopyToClipboard
                                      text={
                                        adminCryptoAccount.data
                                          .admin_crypto_account.wallet_address
                                      }
                                      onCopy={() =>
                                        getSuccessNotificationMessage(
                                          t("wallet_address_copied")
                                        )
                                      }
                                    >
                                      <Button className=" action-btn overlay item_center_column p-0">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="#298BFF"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          />
                                          <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                          <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                        </svg>
                                      </Button>
                                    </CopyToClipboard>
                                  </div>
                                </>
                              )}
                            </>
                            <>
                              <div className="auth_deposit_form out_space_mx_1_5">
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("amount_in")} {props?.token}
                                    <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="number"
                                    name="tokens"
                                    placeholder={t("tokens.placeholder")}
                                    className="form-control"
                                    onKeyPress={handleKeyPress}
                                    onPaste={(event) => {
                                      event.preventDefault();
                                    }}
                                    disabled={depositToken.buttonDisable}
                                    onChange={(e) => onAmountChange(e.target.value, setFieldValue)}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="tokens"
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Label>
                                    {t("transaction_id.label")}
                                    <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="text"
                                    name="transaction_hash"
                                    placeholder={t(
                                      "transaction_id.placeholder"
                                    )}
                                    className="form-control"
                                    disabled={depositToken.buttonDisable}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="transaction_hash"
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                              </div>
                              <div className="commission-sec mb-3">
                                <div className="commission-box">
                                  <div className="commission-card">
                                    <h5>
                                      {t("service_fees", {
                                        value: props?.token,
                                      })}
                                      :
                                    </h5>
                                    <h5>
                                      {!isNaN(
                                        values.tokens *
                                        (currencies.data?.user_commissions?.deposit_commission /
                                          100)
                                      )
                                        ? (
                                          values.tokens *
                                          (currencies.data?.user_commissions?.deposit_commission /
                                            100)
                                        ).toFixed(8)
                                        : "0.00"}
                                    </h5>
                                  </div>
                                  <div className="commission-card">
                                    <h5>
                                      {t("user_receive_tokens", {
                                        value: props?.token,
                                      })}:
                                    </h5>
                                    <h5>
                                      {!isNaN(
                                        values.tokens -
                                        values.tokens *
                                        (currencies.data?.user_commissions?.deposit_commission /
                                          100)
                                      )
                                        ? (
                                          values.tokens -
                                          values.tokens *
                                          (currencies.data?.user_commissions?.deposit_commission /
                                            100)
                                        ).toFixed(8)
                                        : "0.00"}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                              {depositToken.buttonDisable || (Object.keys(depositToken.data).length > 0 &&
                                buttonStepper) ? (
                                // <div className="withdraw-stepper">
                                <div className="mt-3 dark-stepper">
                                  <ButtonStepper
                                    props={depositToken}
                                  />
                                </div>
                              ) : (
                                <div className="auth_deposit_action item_flex_x_5">

                                  {props.show.currency_type == "crypto" && (
                                    <Button
                                      className="action-btn secondary"
                                      onClick={() => {
                                        ["USDT", "USDC"].includes(props.token)
                                          ? setCryptoDepositStep(1)
                                          : props.onHide();
                                      }}
                                      disabled={depositToken.buttonDisable}
                                    >
                                      {["USDT", "USDC"].includes(props.token)
                                        ? t("back")
                                        : t("cancel")}
                                    </Button>
                                  )}
                                  {props.show.currency_type !== "crypto" && (
                                    <Button
                                      className="action-btn secondary"
                                      onClick={() => setStep(step + 1)}
                                    >
                                      {t("continue")}
                                    </Button>
                                  )}
                                  {props.show.currency_type == "crypto" && (
                                    <Button
                                      type="submit"
                                      className="action-btn primary"
                                      disabled={depositToken.buttonDisable}
                                    >
                                      {t("deposit")}
                                    </Button>
                                  )}

                                </div>
                              )}
                            </>
                          </div>
                        </>
                      </FORM>
                    )}
                  </Formik>
                </>
              ) : (
                <>
                  <NoDataFound message={t("no_admin_account_not_found")} />
                </>
              )}
            </div>
          ) : (
            <TransactionSuccess
              message={`Amount Deposit ${paymentType == "bank" ? "Initiated" : "Deposited"} Successfully`}
              onHide={onHide}
              url={url}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DepositCryptoModal;