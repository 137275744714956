import React, { useEffect, useState } from 'react';
import { InputGroup, Form, Button, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { currencyPairStart, getCurrenciesListStart, getCurrencyPairListStart } from '../../../store/actions/BuyAction';
import { fetchWalletDetailsStart } from '../../../store/actions/WalletAction';
import Skeleton from 'react-loading-skeleton';
import NoDataFound from '../../helper/NoDataFound';

const TradePair = (props) => {

    const dispatch = useDispatch();
    const currencies = useSelector((state) => state.buy.currencies);
    const currencyPairList = useSelector((state) => state.buy.currencyPairList);
    const currencyPairs = useSelector((state) => state.buy.currencyPair);
    const profile = useSelector((state) => state.user.profile);
    const [search, setSearch] = useState("");
    const [currencyPair, setCurrencyPair] = useState([]);
     const isMobile = useSelector((state) => state.user.isMobile);


    useEffect(() => {
        dispatch(getCurrenciesListStart({ user_id: profile.data?.user_id ? profile.data?.user_id : "" }));
        if (sessionStorage.getItem("token") || localStorage.getItem("token")) {
            dispatch(fetchWalletDetailsStart());
        }
        dispatch(getCurrencyPairListStart());
    }, []);

    const handleSubmit = (search) => {
        const searchValue = search.toLowerCase();
        if (searchValue == "") handleClear();
        else {
            const filteredPairs = currencyPairList.data.currency_pairs.filter(
                (pair) => {
                    return (
                        pair.currency1.toLowerCase().includes(searchValue) ||
                        pair.currency2.toLowerCase().includes(searchValue) ||
                        pair.unique_id.toLowerCase().includes(searchValue)
                    );
                }
            );

            setCurrencyPair(filteredPairs);
        }
    };

    const handleClear = () => {
        setSearch("");
        setCurrencyPair(currencyPairList.data.currency_pairs);
    };

    useEffect(() => {
        if (
            !currencyPairList.loading &&
            Object.keys(currencyPairList.data).length > 0 &&
            Object.keys(currencyPairList.data.currency_pairs).length > 0
        ) {
            !currencyPairs&&dispatch(currencyPairStart(currencyPairList.data.currency_pairs[0]))
            setCurrencyPair(currencyPairList.data.currency_pairs);
        }
    }, [currencyPairList]);


    return (
        <>
            <div className="trade-pair-wrapped">
                {currencyPairList.loading ? (
                    <div className="trade-pair-list-frame">
                        {[...Array(15)].map(() => (
                            <div className="trade-pair-card">
                                <Skeleton height={40} width={250} baseColor='#FFFFFF17' />
                            </div>
                        ))}
                    </div>) :
                    ((Object.keys(currencyPairList.data).length > 0 &&
                        Object.keys(currencyPairList.data.currency_pairs).length > 0) ||
                        search) ? (
                        <>
                            <div className="trade-pair-head">

                                <Form>
                                    <InputGroup>
                                        <Form.Control
                                            placeholder="Search currency pair"
                                            onChange={(e) => {
                                                setSearch(e.target.value);
                                                e.target.value == ""
                                                    ? handleClear()
                                                    : handleSubmit(e.target.value);
                                            }}
                                            value={search}
                                            onKeyDown={(event) => {
                                                if (event.key === "Enter") {
                                                    event.preventDefault();
                                                    handleSubmit(search);
                                                }
                                            }}
                                        />
                                        {search == "" ? (
                                            <InputGroup.Text
                                                id="basic-addon2"
                                                onClick={() => {
                                                    handleSubmit(search);
                                                }}
                                            >
                                                <svg width="18" height="18" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2324 -0.00836182C5.12442 -0.00836182 0.982422 4.13164 0.982422 9.23964C0.982422 14.3476 5.12442 18.4876 10.2324 18.4876C12.4263 18.4923 14.5497 17.7127 16.2194 16.2896L19.7004 19.7696C19.7691 19.8433 19.8519 19.9024 19.9439 19.9434C20.0359 19.9844 20.1352 20.0065 20.2359 20.0082C20.3366 20.01 20.4366 19.9915 20.53 19.9538C20.6234 19.916 20.7082 19.8599 20.7795 19.7887C20.8507 19.7175 20.9068 19.6326 20.9445 19.5392C20.9823 19.4458 21.0008 19.3458 20.999 19.2451C20.9972 19.1444 20.9752 19.0451 20.9342 18.9531C20.8932 18.8611 20.8341 18.7783 20.7604 18.7096L17.2804 15.2296C18.7056 13.5597 19.4866 11.4351 19.4824 9.23964C19.4824 4.13164 15.3404 -0.00836182 10.2324 -0.00836182ZM2.48242 9.23964C2.48295 7.18456 3.2997 5.21383 4.75305 3.76085C6.2064 2.30788 8.17734 1.49164 10.2324 1.49164C11.2604 1.47512 12.2813 1.66333 13.2358 2.04529C14.1903 2.42724 15.0593 2.99532 15.7921 3.71643C16.5249 4.43754 17.1068 5.29725 17.5041 6.24549C17.9013 7.19374 18.1059 8.21155 18.1059 9.23964C18.1059 10.2677 17.9013 11.2855 17.5041 12.2338C17.1068 13.182 16.5249 14.0417 15.7921 14.7628C15.0593 15.484 14.1903 16.052 13.2358 16.434C12.2813 16.816 11.2604 17.0042 10.2324 16.9876C8.17734 16.9876 6.2064 16.1714 4.75305 14.7184C3.2997 13.2654 2.48295 11.2947 2.48242 9.23964Z" fill="#F6F6F6" />
                                                </svg>
                                            </InputGroup.Text>
                                        ) : (
                                            <InputGroup.Text
                                                id="basic-addon2"
                                                onClick={() => {
                                                    handleClear();
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlSpace="preserve"
                                                    width="10"
                                                    height="10"
                                                    viewBox="0 0 512 512"
                                                >
                                                    <g fill="#fff" data-name="02 User">
                                                        <path
                                                            d="M25 512a25 25 0 0 1-17.68-42.68l462-462a25 25 0 0 1 35.36 35.36l-462 462A24.93 24.93 0 0 1 25 512"
                                                            data-original="#000000"
                                                        ></path>
                                                        <path
                                                            d="M487 512a24.93 24.93 0 0 1-17.68-7.32l-462-462A25 25 0 0 1 42.68 7.32l462 462A25 25 0 0 1 487 512"
                                                            data-original="#000000"
                                                        ></path>
                                                    </g>
                                                </svg>
                                            </InputGroup.Text>
                                        )}

                                    </InputGroup>
                                </Form>
                            </div>
                            <div className="trade-pair-list-frame">
                                {/* for hight trade rate  */}
                                {currencyPair.map((currency) => (
                                    <div className={`trade-pair-card ${currencyPairs?.id == currency.id && "active"} `} onClick={() => {
                                        dispatch(currencyPairStart(currency));
                                         isMobile && props.onHide();
                                        
                                    }}>
                                        <div className="trade-pair-one">
                                            <div className="trade-pair-icons">
                                                <Image
                                                    className="trade-pair-img"
                                                    src={
                                                        currency.image ? currency.image :
                                                            window.location.origin +
                                                            "/img/pair-demo.png"
                                                    }
                                                    alt="Pair"
                                                />
                                            </div>
                                            <div className="trade-pair-label"> {currency.unique_id}</div>
                                        </div>
                                        {/* <div className="trade-pair-two">
                                            <span className="trade-pair-rate high  " > replace "low" with "high" class for change
                                                -56.0%
                                            </span>
                                        </div> */}
                                    </div>
                                ))}

                            </div>
                        </>):<NoDataFound/>}
            </div>
        </>
    )
}

export default TradePair;