import React from "react";
import { Col, Row, Form, Button } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import { Checkbox, Switch } from "pretty-checkbox-react";
import "@djthoms/pretty-checkbox";

const customStyles = {
  ///.....
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    left: "0px",
    borderRadius: "10px",
    overflow: "hidden",
    minWidth: "100px",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    minWidth: 250,
    fontSize: "0.85em",
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      borderRadius: "3px",
      backgroundColor: "#fff",
    },
    "&::-webkit-scrollbar": {
      width: "4px",
      backgroundColor: "#fff",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "3px",
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
      backgroundColor: "#555",
    },
  }),
  container: (provided) => ({ ...provided, width: "auto" }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "transparent!important",
    border: "1px solid #e5eaf4!important",
    borderRadius: "10px!important",
    boxShadow: "none!important",
    height: "42px",
    cursor: "pointer",
    minWidth: "auto",
    fontSize: "0.9em",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#878e96",
    fontSize: "0.9em",
    fontWeight: "400",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#878e96",
    display: "flex",
    alignItems: "center",
    gap: "0.5em",
    fontSize: "0.9em",
    fontWeight: "400",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    color: "#DDE1E6!important",
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    fill: "#DDE1E6!important",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    svg: {
      fill: "#878e96",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused || state.isSelected ? "#F9C201" : "#fff",
    color: state.isFocused || state.isSelected ? "#000" : "#000",
    ":hover": {
      backgroundColor: "#F9C201",
      color: "#000",
    },
  }),
};

const BusinessFormFields = () => {
  return (
    <>
      <div className="business-from-wrap">
        <div className="business-from-titles">
          <h3>Business</h3>
          <p>
            Please provide your basic details to proceed further and enjoy the
            banking services with us to the fullest extent.
          </p>
        </div>
        <Form>
          <Row>
            <Col lg={6}>
              <div className="business-from-card">
                <Form.Group className="mb-3">
                  <Form.Label>
                    Email Company Name <span>*</span>
                  </Form.Label>
                  <Form.Control type="text" placeholder="Enter Company Name" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Tax ID Type <span>*</span>
                  </Form.Label>
                  <Select
                    // value={selectedAccountType}
                    className="fillert-drop"
                    // options={AccountType}
                    styles={customStyles}
                    placeholder="Select Tax ID Type"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Registered Date<span>*</span>
                  </Form.Label>
                  <DatePicker
                    // value={selectedDate}
                    maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                    minDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 60)
                      )
                    }
                    render={<InputIcon />}
                    format="DD/MM/YYYY"
                    // onChange={handleDateChange}
                    multiple={false}
                    placeholder="dob.placeholder"
                    className="w-100"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Tax ID Date Issued<span>*</span>
                  </Form.Label>
                  <DatePicker
                    // value={selectedDate}
                    maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                    minDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 60)
                      )
                    }
                    render={<InputIcon />}
                    format="DD/MM/YYYY"
                    // onChange={handleDateChange}
                    multiple={false}
                    placeholder="dob.placeholder"
                    className="w-100"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Purpose of Account Opening <span>*</span>
                  </Form.Label>
                  <Select
                    // value={selectedAccountType}
                    className="fillert-drop"
                    // options={AccountType}
                    styles={customStyles}
                    placeholder="Select Purpose of account Opening"
                  />
                </Form.Group>
              </div>
            </Col>
            <Col lg={6}>
              <div className="business-from-card">
                <Form.Group className="mb-3">
                  <Form.Label>
                    Preferred Name <span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Preferred Name"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Tax ID Number <span>*</span>
                  </Form.Label>
                  <Form.Control type="text" placeholder="Enter Tax ID Number" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Tax ID Issuance Country <span>*</span>
                  </Form.Label>
                  <Select
                    // value={selectedAccountType}
                    className="fillert-drop"
                    // options={AccountType}
                    styles={customStyles}
                    placeholder="Select Tax ID Issuance Country"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Tax ID Date Expiry <span>*</span>
                  </Form.Label>
                  <DatePicker
                    // value={selectedDate}
                    maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                    minDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 60)
                      )
                    }
                    render={<InputIcon />}
                    format="DD/MM/YYYY"
                    // onChange={handleDateChange}
                    multiple={false}
                    placeholder="dob.placeholder"
                    className="w-100"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Source of Funds<span>*</span>
                  </Form.Label>
                  <Select
                    // value={selectedAccountType}
                    className="fillert-drop"
                    // options={AccountType}
                    styles={customStyles}
                    placeholder="Select Source of Funds"
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>
          <div className="from-space"></div>
          <Row>
            <Col lg={6}>
              <div className="business-from-card">
                <div className="business-from-card-titles">
                  <h3>Operational Address</h3>
                </div>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Email Company Name <span>*</span>
                  </Form.Label>
                  <Form.Control type="text" placeholder="Enter Company Name" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Tax ID Type <span>*</span>
                  </Form.Label>
                  <Select
                    // value={selectedAccountType}
                    className="fillert-drop"
                    // options={AccountType}
                    styles={customStyles}
                    placeholder="Select Tax ID Type"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Registered Date<span>*</span>
                  </Form.Label>
                  <DatePicker
                    // value={selectedDate}
                    maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                    minDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 60)
                      )
                    }
                    render={<InputIcon />}
                    format="DD/MM/YYYY"
                    // onChange={handleDateChange}
                    multiple={false}
                    placeholder="dob.placeholder"
                    className="w-100"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Tax ID Date Issued<span>*</span>
                  </Form.Label>
                  <DatePicker
                    // value={selectedDate}
                    maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                    minDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 60)
                      )
                    }
                    render={<InputIcon />}
                    format="DD/MM/YYYY"
                    // onChange={handleDateChange}
                    multiple={false}
                    placeholder="dob.placeholder"
                    className="w-100"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Purpose of Account Opening <span>*</span>
                  </Form.Label>
                  <Select
                    // value={selectedAccountType}
                    className="fillert-drop"
                    // options={AccountType}
                    styles={customStyles}
                    placeholder="Select Purpose of account Opening"
                  />
                </Form.Group>
              </div>
            </Col>
            <Col lg={6}>
              <div className="business-from-card-titles">
                <h3>Registered Address</h3>
                <Checkbox
                // onClick={() => {
                //   handleSameBilling(checked);
                // }}
                // checked={checked}
                >
                  Same Billing Details
                </Checkbox>
              </div>
              <div className="business-from-card">
                <Form.Group className="mb-3">
                  <Form.Label>
                    Preferred Name <span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Preferred Name"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Tax ID Number <span>*</span>
                  </Form.Label>
                  <Form.Control type="text" placeholder="Enter Tax ID Number" />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Tax ID Issuance Country <span>*</span>
                  </Form.Label>
                  <Select
                    // value={selectedAccountType}
                    className="fillert-drop"
                    // options={AccountType}
                    styles={customStyles}
                    placeholder="Select Tax ID Issuance Country"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Tax ID Date Expiry <span>*</span>
                  </Form.Label>
                  <DatePicker
                    // value={selectedDate}
                    maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                    minDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 60)
                      )
                    }
                    render={<InputIcon />}
                    format="DD/MM/YYYY"
                    // onChange={handleDateChange}
                    multiple={false}
                    placeholder="dob.placeholder"
                    className="w-100"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Source of Funds<span>*</span>
                  </Form.Label>
                  <Select
                    // value={selectedAccountType}
                    className="fillert-drop"
                    // options={AccountType}
                    styles={customStyles}
                    placeholder="Select Source of Funds"
                  />
                </Form.Group>
              </div>
            </Col>
            {/* <Col lg={12}>
                        <div className="form-card-action">
                                <Button className="action-btn overlay">Back</Button>
                                <Button className="action-btn primary">Submit</Button>
                        </div>
                        </Col> */}
          </Row>
        </Form>
      </div>
    </>
  );
};

export default BusinessFormFields;
