import React, { useCallback, useEffect, useState, useRef } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import {
  getAdminWalletStart,
  sellTokenStart,
} from "../../store/actions/BuyAction";
import CommonCenterLoader from "../helper/CommonCenterLoader";
import { useDropzone } from "react-dropzone";
import { setTokenSellData } from "../../store/actions/BuyAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/ToastNotification";
import { updateSwapData } from "../../store/actions/UserAction";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import CopyToClipboard from "react-copy-to-clipboard";
import QRCode from "react-qr-code";
import ButtonStepper from "../Buy/ButtonStepper";

const SellAdminCryptoAccount = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("sell_index");
  const formRef = useRef(null);

  const tokenSellData = useSelector((state) => state.buy.tokenSellData);
  const adminWallet = useSelector((state) => state.buy.adminWallet);
  const profile = useSelector((state) => state.user.profile);
  const bankAccountList = useSelector((state) => state.bankAccount.bankAccount);
  const tokenSell = useSelector((state) => state.buy.tokenSell);
  const swapData = useSelector((state) => state.user.swapData);
  const currencies = useSelector((state) => state.buy.currencies);
  const setSwapData = (data) => dispatch(updateSwapData(data));
  const updateTokenSellData = (data) => dispatch(setTokenSellData(data));

  const [errorMessage, setErrorMessage] = useState(false);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [sellStaticCaseWallet, setSellStaticCaseWallet] = useState(false);
  const [files, setFiles] = useState([]);

  const validationSchema = Yup.object().shape({
    // user_bank_account_id: Yup.string().required(t("bank_account.required")),
    transaction_hash: Yup.string()
      .required(t("transaction.required"))
      .min(3, t("transaction.invalid"))
    // .max(66, "Max 66 characters allowed"),
    // file: Yup.string().required(t("please_upload_payment_screenshot")),
  });

  const validationAddressSchema = Yup.object().shape({
    user_wallet_address: Yup.string().required(
      t("user_wallet_address.required")
    ),
    transaction_hash: Yup.string()
      .required(t("transaction.required"))
      .min(3, t("transaction.invalid")),
    file: Yup.string().required(t("please_upload_payment_screenshot")),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getAdminWalletStart({
        network_type: !["USDT", "USDC"].includes(tokenSellData.from_currency) ? tokenSellData.from_currency : tokenSellData.network_type,
        user_id: profile.data?.user_id ? profile.data?.user_id : ""
      })
    );
    // dispatch(getBankAccountStart());
  }, []);

  // Sell Static Case for Specific Emails
  useEffect(() => {
    if (Object.keys(profile.data).length > 0) {
      let network = !["USDT", "USDC"].includes(tokenSellData.from_currency) ? tokenSellData.from_currency : tokenSellData.network_type;
      if (["mail@aravinth.net", "reezan01@gmail.com","farvez@timexpay.io", "robertpattison7079@gmail.com"].includes(profile.data.email)) {
        if (["ETH", "MATIC", "BNB"].includes(network))
          setSellStaticCaseWallet("0xaCBDec71EF84Ca8C711B5a8908e597bF6467F4c0")
        else if (network == "TRX")
          setSellStaticCaseWallet("TSpBj9kSnMJkbVDGYQmGzp8BCbBTQ2unhM")
        else
          setSellStaticCaseWallet(null);
      }
    }
  }, [profile])

  const onDropRejected = useCallback((rejectedFiles) => {
    if (rejectedFiles.length > 1) {
      setErrorMessage(t("please_upload_only_one_file_at_a_time"));
    } else {
      const invalidFiles = rejectedFiles.filter(
        (file) => !["image/jpeg", "image/png", "image/jpg"].includes(file.type)
      );
      if (invalidFiles.length > 0) {
        setErrorMessage(t("invalid_file_format"));
      }
    }
  }, []);

  const onDropAccepted = () => {
    setErrorMessage("");
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropRejected,
    onDropAccepted,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      formRef.current.setFieldValue("file", "preview");
    },
  });

  const images = files.map((file, key) => (
    <div className="preview-added-image" key={key}>
      <Button
        variant="link"
        className="preview-cancel"
        onClick={() => {
          formRef.current.setFieldValue("file", "");
          setFiles([]);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#fff"
          viewBox="0 0 24 24"
        >
          <path d="M18 6a1 1 0 00-1.414 0L12 10.586 7.414 6A1 1 0 006 6a1 1 0 000 1.414L10.586 12 6 16.586A1 1 0 006 18a1 1 0 001.414 0L12 13.414 16.586 18A1 1 0 0018 18a1 1 0 000-1.414L13.414 12 18 7.414A1 1 0 0018 6z"></path>
        </svg>
      </Button>
      <CustomLazyLoad
        className="product-preview"
        key={file.name}
        src={file.preview}
        alt="image-preview"
      />
    </div>
  ));

  const onSubmit = (values) => {
    const { to_currency_conversion_value, ...data } = tokenSellData;
    if (
      currencies.data.currencies.find(
        (item) => item.currency_code == tokenSellData.to_currency
      ).currency_type == "forex"
    ) {
      dispatch(
        sellTokenStart({
          ...data,
          transaction_hash: values.transaction_hash,
          admin_crypto_account_id: "1",
          // user_bank_account_id: values.user_bank_account_id,
          // file: files[0] ? files[0] : "",
        })
      );
    } else {
      dispatch(
        sellTokenStart({
          ...data,
          transaction_hash: values.transaction_hash,
          user_wallet_address: values.user_wallet_address,
          admin_crypto_account_id: "1",
          // file: files[0],
        })
      );
    }
    // } else {
    //   getErrorNotificationMessage(t("please_upload_payment_screenshot"));
    // }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !tokenSell.loading &&
      Object.keys(tokenSell.data).length > 0
    ) {
      setTransactionSuccess(true);

      setTimeout(() => {
        // if (exchangeCrypto.data.exchange.status == 5) {
        updateTokenSellData({
          ...tokenSellData,
          step: 3,
        });
        // } else {
        //   navigate("/transactions");
        // }
      }, 4000);
    }
    setSkipRender(false);
  }, [tokenSell]);

  return (
    <>
      {adminWallet.loading ? (
        <CommonCenterLoader />
      ) : Object.keys(adminWallet.data).length > 0 ? (
        <>
          <div className="bank-account-details-sec">
            <div className="bank-account-details out_space_mx_1_5">
              <div
                className="back-arrow-btn-sec"
                onClick={() =>
                  updateTokenSellData({ ...tokenSellData, step: 1 })
                }
              >
                <Image
                  src={
                    window.location.origin + "/img/exchange/back-arrow-icon.svg"
                  }
                  className="back-arrow-btn"
                />
                <h4>{t("sell_crypto")} </h4>
              </div>
            </div>

            <div className="auth-qr-details-swap-frame">
              <div className="deposit-amt-details mb-0 p-0">
                <div className="deposit-amt-details-info ">
                  <div className="deposit-amt-pay">
                    <span className="amt-pay-label">You Pay </span>
                    <span className="amt-pay-amt">
                      {tokenSellData.from_amount} {tokenSellData.from_currency}{" "}
                      {(["USDT", "USDC"].includes(tokenSellData.from_currency) && tokenSellData.network_type) ? `(${tokenSellData.network_type})` : ""}
                    </span>
                  </div>
                  <div className="deposit-amt-get">
                    <span className="amt-pay-label">You Get </span>
                    <span className="amt-pay-amt">
                      {tokenSellData.to_currency_conversion_value}{" "}
                      {tokenSellData.to_currency}
                    </span>
                  </div>
                </div>
                <div className="efi-swap-exchange-info efi-info-frame mt-2">
                  <p>
                    {" "}
                    <span>Estimated Rate </span>{" "}
                    <span>
                      1 {tokenSellData.from_currency}=
                      {!isNaN(tokenSellData.exchange_rate)
                        ? (tokenSellData.exchange_rate).toFixed(2)
                        : 0.0}{" "}
                      {tokenSellData.to_currency}
                    </span>
                  </p>
                </div>
              </div>
              <div className="auth_deposit_qr_scan">
                <div className="admin-wallet-qr-frame">
                  {sellStaticCaseWallet ? <QRCode
                    size={135}
                    value={sellStaticCaseWallet}
                    viewBox={`0 0 256 256`}
                  /> : adminWallet.data?.admin_crypto_account?.qr_code ?
                    <CustomLazyLoad
                      src={adminWallet.data?.admin_crypto_account.qr_code}
                      className="qr-code-payment-image"
                    />
                    : (
                      <QRCode
                        size={135}
                        value={adminWallet.data?.admin_crypto_account.wallet_address}
                        viewBox={`0 0 256 256`}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
          <Formik
            initialValues={
              currencies.data?.currencies?.find(
                (item) => item.currency_code == tokenSellData.to_currency
              ).currency_type == "forex"
                ? {
                  transaction_hash: "",
                  // user_bank_account_id: "",
                  file: "",
                }
                : {
                  transaction_hash: "",
                  user_wallet_address: "",
                  file: "",
                }
            }
            validationSchema={
              currencies.data.currencies?.find(
                (item) => item.currency_code == tokenSellData.to_currency
              ).currency_type == "forex"
                ? validationSchema
                : validationAddressSchema
            }
            innerRef={formRef}
            onSubmit={(values) => onSubmit(values)}
          >
            {({ values, touched, errors, setFieldValue }) => (
              <FORM>
                <div className="deposit_modal_form">
                  <div className="sell_address_card  out_space_mx_1_5">
                    <div className="buy-data-card mb-3">
                      <h6>{t("wallet_address")}</h6>
                      <div className="auth_buy_code">
                        <div className="auth_deposit_code_card">
                          <span>
                            {" "}
                            {
                              sellStaticCaseWallet || adminWallet.data?.admin_crypto_account
                                ?.wallet_address
                            }
                          </span>{" "}
                        </div>

                        <CopyToClipboard
                          text={
                            adminWallet.data?.admin_crypto_account
                              ?.wallet_address
                          }
                          onCopy={(e) =>
                            getSuccessNotificationMessage(
                              "Wallet Address Copied!"
                            )
                          }
                        >
                          <Button className="action-btn overlay item_center_column p-0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#298BFF"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                              <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                            </svg>
                          </Button>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div className="deposit_address_field">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          {t("transaction.label")} <span>*</span>
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder={t("transaction.placeholder")}
                          name="transaction_hash"
                          disabled={tokenSell.buttonDisable}
                        />
                        <ErrorMessage
                          component={"div"}
                          name="transaction_hash"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                    </div>
                    {currencies.data.currencies?.find(
                      (item) => item.currency_code == tokenSellData.to_currency
                    ).currency_type == "forex" ? null : (
                      <div className="deposit_address_field">
                        <Form.Group className="mb-3">
                          <Form.Label>{t("your_wallet_address")}</Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder={t("your_wallet_address")}
                            name="user_wallet_address"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="user_wallet_address"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                      </div>
                    )}
                  </div>
                  <div className="efi-swap-action">
                    {tokenSell.buttonDisable ||
                      (Object.keys(tokenSell.data).length > 0 &&
                        transactionSuccess) ? (
                      <div className="mt-3">
                        <ButtonStepper props={tokenSell} />
                      </div>
                    ) : (
                      <Button
                        className="action-btn primary w-100"
                        type="submit"
                        disabled={tokenSell.buttonDisable}
                      >
                        {tokenSell.buttonDisable ? t("loading") : t("continue")}
                      </Button>
                    )}
                  </div>
                  <div className="deposit_bank_action">
                    <p className="out_space_mt_1 other_links">
                      {t("by_continuing_you_agree_to_our")}{" "}
                      <Link className="cookie-txt" to="/page/privacy-agreement">
                        {t("cookie_policy")}.
                      </Link>
                    </p>
                  </div>
                </div>
              </FORM>
            )}
          </Formik>
        </>
      ) : (
        <>
          <div className="bank-account-details out_space_mx_1_5">
            <div
              className="back-arrow-btn-sec"
              onClick={() => updateTokenSellData({ ...tokenSellData, step: 1 })}
            >
              <Image
                src={
                  window.location.origin + "/img/exchange/back-arrow-icon.svg"
                }
                className="back-arrow-btn"
              />
              <h4>{t("sell_crypto")} </h4>
            </div>
          </div>
          <div className="no-bank-details-sec exchange-btn-sec">
            <CustomLazyLoad
              src={window.location.origin + "/img/no-bank-found.png"}
              className="no-data-found-img"
            />
            <p>{t("no_wallet_details_found")}</p>
            <div>
              <Button
                className="action-btn primary w-100"
                onClick={() =>
                  dispatch(
                    getAdminWalletStart({
                      network_type: !["USDT", "USDC"].includes(
                        tokenSellData.from_currency
                      )
                        ? tokenSellData.from_currency
                        : tokenSellData.network_type, user_id: profile.data?.user_id ? profile.data?.user_id : ""
                    })
                  )
                }
              >
                {t("retry")}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SellAdminCryptoAccount;
