import { React, useEffect, useState } from 'react';
import { Image, Button } from 'react-bootstrap';
import MobilePairModal from './Mobile/MobilePairModal';
import MobileStockModal from './Mobile/MobileStockModal';
import { useDispatch, useSelector } from 'react-redux';
import { currencyPairStart, getCurrenciesListStart, getCurrencyPairListStart } from '../../store/actions/BuyAction';
import { fetchWalletDetailsStart } from '../../store/actions/WalletAction';


const TradeNavResponsive = (props) => {

    const [modalShow, setModalShow] = useState(false);
    const [stockmodalShow, setStockModalShow] = useState(false);
    const currencyPairs = useSelector((state) => state.buy.currencyPair);

    const currencyPairList = useSelector((state) => state.buy.currencyPairList);
     
      const profile = useSelector((state) => state.user.profile);
      const dispatch = useDispatch();
  useEffect(() => {
          dispatch(getCurrenciesListStart({ user_id: profile.data?.user_id ? profile.data?.user_id : "" }));
          if (sessionStorage.getItem("token") || localStorage.getItem("token")) {
              dispatch(fetchWalletDetailsStart());
          }
          dispatch(getCurrencyPairListStart());
      }, []);

        useEffect(() => {
              if (
                  !currencyPairList.loading &&
                  Object.keys(currencyPairList.data).length > 0 &&
                  Object.keys(currencyPairList.data.currency_pairs).length > 0
              ) {
                  !currencyPairs&&dispatch(currencyPairStart(currencyPairList.data.currency_pairs[0]))
                  
              }
          }, [currencyPairList]);

    return (
        <>
            <div className="trade-nav-system">
                <div className="trade-nav-system-pair">
                    <div className="trade-system-pair-card " onClick={() => {
                        setModalShow(true);
                    }}>
                        <div className="trade-system-pair-one">
                            <div className="trade-pair-icons">
                                <Image
                                    className="trade-system-pair-img"
                                    src={
                                        window.location.origin +
                                        "/img/pair-demo.png"
                                    }
                                    alt="Pair"
                                />
                            </div>
                            <div className="trade-system-pair-label"> {currencyPairs?.unique_id}
                                <span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlSpace="preserve"
                                        width="12"
                                        height="12"
                                        viewBox="0 0 240.823 240.823"
                                    >
                                        <path
                                            fill="#fff"
                                            d="M183.189 111.816 74.892 3.555c-4.752-4.74-12.451-4.74-17.215 0-4.752 4.74-4.752 12.439 0 17.179l99.707 99.671-99.695 99.671c-4.752 4.74-4.752 12.439 0 17.191 4.752 4.74 12.463 4.74 17.215 0l108.297-108.261c4.68-4.691 4.68-12.511-.012-17.19"
                                            data-original="#000000"
                                        ></path>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="trade-nav-system-stock">
                    <Button className="trade-nav-btn-stock" onClick={() => {
                        setStockModalShow(true);
                    }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlSpace="preserve"
                            width="24"
                            height="24"
                            viewBox="0 0 32 32"
                        >
                            <g data-name="Layer 2">
                                <path
                                    fill="#52e3a6"
                                    d="M10.25 26v4A1.25 1.25 0 0 1 9 31.25a1.2 1.2 0 0 1-.74-.25 1.23 1.23 0 0 1-.51-1v-4a1.23 1.23 0 0 1 .51-1 1.2 1.2 0 0 1 .74-.25A1.25 1.25 0 0 1 10.25 26m0-24v8A1.25 1.25 0 0 1 9 11.25a1.2 1.2 0 0 1-.74-.25 1.23 1.23 0 0 1-.51-1V2a1.23 1.23 0 0 1 .51-1A1.2 1.2 0 0 1 9 .75 1.25 1.25 0 0 1 10.25 2"
                                    data-original="#ebc5ff"
                                ></path>
                                <path
                                    fill="#ff3b3e"
                                    d="M24.25 2v4A1.25 1.25 0 0 1 23 7.25a1.2 1.2 0 0 1-.74-.25 1.23 1.23 0 0 1-.51-1V2a1.23 1.23 0 0 1 .51-1A1.2 1.2 0 0 1 23 .75 1.25 1.25 0 0 1 24.25 2m0 20v8A1.25 1.25 0 0 1 23 31.25a1.2 1.2 0 0 1-.74-.25 1.23 1.23 0 0 1-.51-1v-8a1.23 1.23 0 0 1 .51-1 1.2 1.2 0 0 1 .74-.25A1.25 1.25 0 0 1 24.25 22"
                                    data-original="#bb6ddb"
                                ></path>
                                <rect
                                    width="9"
                                    height="18"
                                    x="4.5"
                                    y="9"
                                    fill="#52e3a6"
                                    data-original="#ebc5ff"
                                    rx="2"
                                ></rect>
                                <rect
                                    width="9"
                                    height="18"
                                    x="18.5"
                                    y="5"
                                    fill="#ff3b3e"
                                    data-original="#bb6ddb"
                                    rx="2"
                                ></rect>
                            </g>
                        </svg>
                    </Button>
                </div>
            </div>
            {modalShow &&
                <MobilePairModal
                    placement="start" name="start"
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />}
            {stockmodalShow &&
                <MobileStockModal
                    placement="end" name="end"
                    show={stockmodalShow}
                    onHide={() => setStockModalShow(false)}
                />}
        </>
    )
}

export default TradeNavResponsive