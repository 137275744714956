import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";
import CustomLazyLoad from "../helper/CustomLazyLoad";

const HowItsWork = (props) => {

  const t = useTranslation("how_its_work")

  return (
    <>
      <div className="how-its-work-sec sm-padding">
        <Container fluid>
          <Row className="justify-content-md-center">
            <Col md={8} lg={9} className="text-center">
              <div className="section-title">
                <h2>{t("what_we_provide")}</h2>
                <p>{t("we_offer_secure")}</p>
              </div>
            </Col>
          </Row>
          <div className="how-its-work-box">
            <div className="how-its-work-card">
              <div className="how-its-work-icon-sec">
                <CustomLazyLoad
                  className="how-its-work-icon"
                  src={
                    window.location.origin + "/img/currency/buy-crypto.svg"
                  }
                  type="image/png"
                />
              </div>
              <div className="how-its-work-info">
                <h4>{t("buy_crypto_instantly")}</h4>
                <p>{t("easily_convert_usd")}
                </p>
                <div className="learn-more-btn-sec">
                  <Link to="/buy" className="learn-more-btn">
                    {t("learn_more")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 23 22"
                    >
                      <path
                        stroke="#E89900"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M4.701 11.246h12.951m-4.55 4.701l4.692-4.701-4.693-4.701"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="how-its-work-card">
              <div className="how-its-work-icon-sec">
                <CustomLazyLoad
                  className="how-its-work-icon"
                  src={
                    window.location.origin + "/img/currency/sell-crypto.svg"
                  }
                  type="image/png"
                />
              </div>
              <div className="how-its-work-info">
                <h4>{t("sell_crypto_effortlessly")}</h4>
                <p>{t("sell_your_cryptocurrency")}</p>
                <div className="learn-more-btn-sec">
                  <Link to="/sell" className="learn-more-btn">
                    {t("learn_more")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 23 22"
                    >
                      <path
                        stroke="#E89900"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M4.701 11.246h12.951m-4.55 4.701l4.692-4.701-4.693-4.701"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="how-its-work-card">
              <div className="how-its-work-icon-sec">
                <CustomLazyLoad
                  className="how-its-work-icon"
                  src={
                    window.location.origin + "/img/currency/swap-crypto.svg"
                  }
                  type="image/png"
                />
              </div>
              <div className="how-its-work-info">
                <h4>{t("exchange_crypto_seamlessly")}</h4>
                <p>{t("quickly_swap_one")}</p>
                <div className="learn-more-btn-sec">
                  <Link to="/exchange" className="learn-more-btn">
                    {t("learn_more")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 23 22"
                    >
                      <path
                        stroke="#E89900"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M4.701 11.246h12.951m-4.55 4.701l4.692-4.701-4.693-4.701"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
            <div className="how-its-work-card">
              <div className="how-its-work-icon-sec">
                <CustomLazyLoad
                  className="how-its-work-icon"
                  src={
                    window.location.origin + "/img/currency/trade-crypto.svg"
                  }
                  type="image/png"
                />
              </div>
              <div className="how-its-work-info">
                <h4>{t("trade_crypto_professionally")}</h4>
                <p>{t("trade_digital_assets")}</p>
                <div className="learn-more-btn-sec">
                  <Link to="/trading" className="learn-more-btn">
                    {t("learn_more")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 23 22"
                    >
                      <path
                        stroke="#E89900"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M4.701 11.246h12.951m-4.55 4.701l4.692-4.701-4.693-4.701"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default HowItsWork;
