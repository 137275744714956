import React from 'react';
import { Image } from 'react-bootstrap';

const TradeSocketHigh = (props) => {
  return (
    <>
      {props?.buyTrade?.length > 0 ?
        props.buyTrade.map((data) => (
          <div className="trade-socket-stack">
            <div className="stack-price high">{data.price}</div>
            <div className="stack-amt">{data.value}</div>
          </div>
        )) :
        <>
          <div className="trade-empty-frame">
            <Image
              src={window.location.origin + "/img/new/no-trade.svg"}
              className="trade-empty-avater"
            />
            <h4>No Trade Found</h4>
          </div>
        </>
      }
    </>
  )
}

export default TradeSocketHigh;