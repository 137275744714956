import React, { useEffect, useRef, useState } from "react";
import {
    Container,
    Tab,
    Tabs,
    Image,
    Button,
    Nav,
    Row,
    Col,
    Table,
    Pagination, Form, InputGroup
} from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { getCurrenciesListStart, userWithdrawalsExportStart, withdrawalStatusStart } from "../../store/actions/BuyAction";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleWalletViewStart, fetchWithDrawalsStart } from "../../store/actions/WalletAction";
import { useTranslation } from "react-multi-lang";
import NoDataFound from "../helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import NoTransactionData from "../helper/NoTransactionData";
import { getErrorNotificationMessage, getSuccessNotificationMessage } from "../helper/ToastNotification";
import * as Yup from "yup";
import WithdrawTransactionView from "../Transaction/TransactionSingleView/WithdrawTransactionView";
import { Field, Form as FORM, Formik } from "formik";
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import Select from "react-select";
import TokenTransactions from "./TokenTransactions";
import SwapFiatModal from "../Modals/SwapFiatModal";
import SwapCryptoModal from "../Modals/SwapCryptoModal";
import WithdrawCryptoModal from "../Modals/WithdrawCryptoModal";
import DepositCryptoModal from "../Modals/DepositCryptoModal";
import DepositFiatModal from "../Modals/DepositFiatModal";
import WalletWithdrawModal from "../Modals/WalletWithdrawModal";
import { Tooltip } from 'react-tooltip'

const WalletDetails = (props) => {
    const location = useLocation();
    const t = useTranslation("wallet_index");
    const [activeTab, setActiveTab] = useState("transactions");
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [modalShow, setModalShow] = useState(false);
    const [withdrawModalShow, setWithdrawModal] = useState(false);
    const [cryptoModalShow, setCryptoModalShow] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams("");
    const [depositModal, setDepositModal] = useState(false);
    const [swapModal, setSwapModal] = useState(false);
    const [tokenType, setTokenType] = useState("");
    const wallet = useSelector((state) => state.wallet.singleWallet);
    const [withdrawalId, setWithdrawalId] = useState(null);
    const withDrawals = useSelector((state) => state.wallet.withDrawals);
    const userWithdrawalsExport = useSelector((state) => state.buy.userWithdrawalsExport);
    const withdrawalStatus = useSelector((state) => state.buy.withdrawalStatus);
    const [value, setValue] = useState([null, null]);
    const [skipRender, setSkipRender] = useState(true);
    const [filterStatus, setFilterStatus] = useState(false);
    const isMobile = useSelector((state) => state.user.isMobile);
    const [filter, setFilter] = useState({
        search_key: searchParams.get("search_key")
            ? searchParams.get("search_key")
            : "",
        status: "",
        from_date: "",
        to_date: "",
    });
    const [close, setClose] = useState(false);
    const [page, setPage] = useState(
        searchParams.get("page") ? searchParams.get("page") : 1
    );
    const calenderRefs = useRef(null);
    const [dateValues, setDateValues] = useState({
        from_date: "",
        to_date: "",
    });
    const formRef = useRef(null);

    const [transactionId, setTransactionId] = useState("");
    const profile = useSelector(state => state.user.profile);
    const [eventKey, setEventKey] = useState("transactions");
    const [depositCryptoModal, setDepositCryptoModal] = useState(false);
    const [depositFiatModal, setDepositFiatModal] = useState(false);
    const [singleWallet, setSingleWallet] = useState(false);
    const [swapModalNew, setSwapModalNew] = useState(false);


    useEffect(() => {
        dispatch(
            fetchSingleWalletViewStart({
                currency_code: params.user_wallet_id,
            })
        );
    }, [params.user_wallet_id]);

    useEffect(() => {
        dispatch(getCurrenciesListStart({ user_id: profile.data?.user_id ? profile.data?.user_id : "" }))
    }, [])



    const options = [
        { value: "0", label: t("pending") },
        { value: "1", label: t("success") },
        { value: "3", label: t("failed") },
    ];

    const customStyles = {
        ///.....
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({
            ...provided,
            width: "150px",
            zIndex: 9999,
            left: "0px",
            borderRadius: "10px",
            overflow: "hidden",
        }),
        menuList: (provided) => ({
            ...provided,
            padding: 0,
            width: "150px",
            fontSize: "0.85em",
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
                borderRadius: "8px",
                backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar": {
                width: "4px",
                backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar-thumb": {
                borderRadius: "3px",
                boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
                backgroundColor: "#555",
            },
        }),
        container: (provided) => ({ ...provided, width: "auto" }),
        control: (provided) => ({
            ...provided,
            backgroundColor: "transparent!important",
            border: "1px solid #e5eaf4!important",
            borderRadius: "10px!important",
            boxShadow: "none!important",
            height: "48px",
            minWidth: "150px",
            cursor: "pointer",
            fontSize: "0.9em",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#878e96",
            fontSize: "0.85em",
            fontWeight: "400",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "#298bff",
            display: "flex",
            alignItems: "center",
            gap: "0.5em",
            fontSize: "0.9em",
            fontWeight: "500",
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            color: "#298bff!important",
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            fill: "#298bff!important",
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: "none",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            svg: {
                fill: "#111",
            },
        }),
        // option: (provided, state) => ({
        //   ...provided,
        //   backgroundColor: state.isFocused || state.isSelected ? "#F9C201" : "#fff",
        //   color: state.isFocused || state.isSelected ? "#000" : "#000",
        //   ":hover": {
        //     backgroundColor: "#F9C201",
        //     color: "#000",
        //   },
        // }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? "#F9C201"
                : state.isFocused
                    ? "#f0f0f0"
                    : "transparent",
            color: state.isSelected || state.isFocused ? "#000" : "#000",
            ":hover": {
                backgroundColor: "#f0f0f0",
                color: "#000",
            },
        }),
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (eventKey == "withdraw") {
            dispatch(
                fetchWithDrawalsStart({
                    ...filter,
                    user_wallet_id: wallet.data.user_wallet?.id,
                    skip: 0,
                    take: 12,
                })
            );
        }
    }, [filter, page, eventKey]);

    const handleDateChange = (values) => {
        if (values) {
            setValue(values);
            formRef?.current?.setFieldValue(
                "from_date",
                values && values.length > 0
                    ? JSON.stringify(new Date(values[0])).slice(1, 11)
                    : ""
            );
            formRef?.current?.setFieldValue(
                "to_date",
                values && values.length > 1
                    ? JSON.stringify(new Date(values[1])).slice(1, 11)
                    : ""
            );
            setDateValues({
                from_date:
                    values.length > 0
                        ? JSON.stringify(new Date(values[0])).slice(1, 11)
                        : "",
                to_date:
                    values.length > 1
                        ? JSON.stringify(new Date(values[1])).slice(1, 11)
                        : "",
            });
        } else {
            setDateValues({
                from_date: "",
                to_date: "",
            });
            formRef?.current?.setFieldValue("from_date", "");
            formRef?.current?.setFieldValue("to_date", "");
            calenderRefs.current.closeCalendar();
            setFilter({
                ...filter,
                skip: 12 * (page - 1),
                take: 12,
                from_date: "",
                to_date: "",
            });
        }
    };

    const handleExport = () => {
        dispatch(
            userWithdrawalsExportStart({
                ...filter,
                user_wallet_id: wallet.data.user_wallet?.id,
                from_date: dateValues.from_date,
                to_date: dateValues.to_date,
            })
        );
    };

    useEffect(() => {
        if (
            !skipRender &&
            !withdrawalStatus.loading &&
            Object.keys(withdrawalStatus.data).length > 0
        ) {
            dispatch(
                fetchWithDrawalsStart({
                    ...filter,
                    user_wallet_id: wallet.data.user_wallet?.id,
                    skip: 0,
                    take: 12,
                })
            );
        }
    }, [withdrawalStatus]);

    const handlePageClick = ({ selected }) => {
        let param = searchParams.get("search_key")
            ? `search_key=${searchParams.get("search_key")}&`
            : "";
        navigate(`/wallet/${params.user_wallet_id}?${param}page=${selected + 1}`);
    };

    useEffect(() => {
        if (!skipRender) {
            setPage(searchParams.get("page") ? searchParams.get("page") : 1);
            setFilter({
                ...filter,
                search_key: searchParams.get("search_key")
                    ? searchParams.get("search_key")
                    : "",
                from_date: dateValues.from_date,
                to_date: dateValues.to_date,
            });
        }
        setSkipRender(false);
    }, [searchParams.get("page"), searchParams.get("search_key")]);

    useEffect(() => {
        if (!skipRender) {
            setPage(searchParams.get("page") ? searchParams.get("page") : 1);
        }
        setSkipRender(false);
    }, [searchParams.get("page")]);

    useEffect(() => {
        if (
            !skipRender &&
            !userWithdrawalsExport.buttonDisable &&
            Object.keys(userWithdrawalsExport.data).length > 0
        ) {
            window.open(userWithdrawalsExport.data.url, "_blank", "noreferrer");
        }
        setSkipRender(false);
    }, [userWithdrawalsExport]);

    const validationSchema = Yup.object().shape({
        search_key: Yup.string()
            .required(t("required"))
            .matches(/^(?=\S*$)/, t("white_space")),
    });

    const cancelWithdrawal = (accout_id) => {
        setWithdrawalId(accout_id);
        setModalShow(true);
    };

    const handleRefresh = (values) => {
        setWithdrawalId(values.id);
        dispatch(
            withdrawalStatusStart({ user_withdrawal_id: values.user_withdrawal_id })
        );
    };

    return (
        <>
            <div className="wallet-details-wrapped">
                <div className="wallet-details-head">
                    <Link to={location.search == "" ? -1 : "/wallet-currency-list"}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 9 16"
                        >
                            <path
                                stroke="#000"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M8 15 1 8l7-7"
                            ></path>
                        </svg></Link>
                    <div className="wallet-titles-label">
                        Token Details
                        {!isMobile &&
                            <div className="wallet-head-action">
                                {wallet.data?.user_wallet?.currency_type == "forex" ? (
                                    <>
                                        {wallet.data?.user_wallet?.is_swap_allowed == 1 && (
                                            <Button
                                                className="action-btn primary"
                                                onClick={() => {
                                                    setSingleWallet(wallet.data.user_wallet)
                                                    setSwapModalNew("forex")
                                                }}
                                                disabled={
                                                    wallet.data.user_wallet.remaining_original ==
                                                    0
                                                }
                                            > Swap
                                            </Button>)}
                                        {wallet.data?.user_wallet?.is_withdraw_allowed == 1 && (
                                            <Button
                                                className="action-btn secondary"
                                                onClick={() => {
                                                    setSingleWallet(wallet.data.user_wallet)
                                                    setModalShow(Number(wallet.data.user_wallet.remaining_original))
                                                }}
                                                disabled={
                                                    wallet.data.user_wallet.remaining_original == 0 ||
                                                    profile.data.kyc_verified == 0
                                                }
                                            > Withdraw
                                            </Button>)}
                                        {wallet.data.user_wallet?.is_deposit_allowed == 1 &&
                                            <Button
                                                className="action-btn overlay-primary"
                                                disabled={profile.data.kyc_verified == 0}
                                                onClick={() => {
                                                    setSingleWallet(wallet.data.user_wallet)
                                                    setTokenType(
                                                        wallet.data.user_wallet.currency_code
                                                    );
                                                    setDepositFiatModal(wallet.data.user_wallet);
                                                }}
                                            >
                                                Deposit
                                            </Button>}
                                    </>) : (
                                    <>
                                        {wallet.data?.user_wallet?.is_swap_allowed == 1 && (
                                            <Button
                                                onClick={() => {
                                                    setSingleWallet(wallet.data?.user_wallet)
                                                    setSwapModalNew("crypto")
                                                }}
                                                disabled={
                                                    wallet.data?.user_wallet?.remaining_original ==
                                                    0
                                                }
                                                className="action-btn primary"
                                            >
                                                Swap

                                            </Button>)}

                                        {wallet.data?.user_wallet?.is_withdraw_allowed == 1 && (
                                            <Button
                                                className="action-btn secondary"
                                                onClick={() => {
                                                    setSingleWallet(wallet.data.user_wallet)
                                                    setCryptoModalShow(
                                                        wallet.data?.user_wallet?.remaining_original
                                                    )
                                                }}
                                                disabled={
                                                    wallet.data?.user_wallet?.remaining_original == 0 ||
                                                    profile.data.kyc_verified == 0
                                                }
                                            >
                                                Withdraw
                                            </Button>)}
                                        {wallet.data?.user_wallet?.is_deposit_allowed == 1 &&
                                            <Button
                                                className="action-btn overlay-primary"
                                                disabled={profile.data.kyc_verified == 0}
                                                onClick={() => {
                                                    setSingleWallet(wallet.data?.user_wallet)
                                                    setTokenType(
                                                        wallet.data?.user_wallet?.currency_code
                                                    );
                                                    setDepositCryptoModal(wallet.data?.user_wallet);
                                                }}

                                            >
                                                Deposit
                                            </Button>}
                                    </>)}
                            </div>
                        }
                    </div>
                </div>
                <div className="wallet-token-frame">
                {wallet.loading ? (
                            <>
                            <div className="wallet-token-wrapped">
                                {[...Array(3)].map(() => (
                                    <div className="token-card">
                                        <Skeleton height={40} />
                                    </div>))}
                                    </div>
                            </>
                        ) : Object.keys(wallet.data).length > 0 ? (
                    <div className="wallet-token-wrapped">
                       
                            <>
                                <div className="token-card">
                                    <div className="token-card-avater">
                                        <Image
                                            className="token-card-img"
                                            src={
                                                wallet.data.user_wallet?.currency_picture ||
                                                    window.location.origin +
                                                    "/img/phase2/bitcoin.png"
                                            }
                                            alt="Auth Otp"
                                        />
                                        <div className="token-card-avater-label">{wallet.data.user_wallet?.currency_code}</div>
                                    </div>
                                    <div className="token-card-info">
                                        <div className="token-card-info-value">
                                            {wallet.data?.user_wallet?.total}
                                        </div>
                                        <div className="token-card-info-status">
                                            Total Token Balance
                                        </div>

                                    </div>
                                </div>
                                <div className="token-card">
                                    <div className="token-card-avater">
                                        <Image
                                            className="token-card-img"
                                            src={
                                                wallet.data.user_wallet?.currency_picture ||
                                                    window.location.origin +
                                                    "/img/phase2/bitcoin.png"
                                            }
                                            alt="Auth Otp"
                                        />
                                        <div className="token-card-avater-label">{wallet.data?.user_wallet?.currency_code}</div>
                                    </div>
                                    <div className="token-card-info">
                                        <div className="token-card-info-value">
                                            {wallet.data?.user_wallet?.onhold}
                                        </div>
                                        <div className="token-card-info-status">
                                            Onhold Token Balance
                                        </div>

                                    </div>
                                </div>
                                <div className="token-card">
                                    <div className="token-card-avater">
                                        <Image
                                            className="token-card-img"
                                            src={
                                                wallet.data?.user_wallet?.currency_picture ||
                                                    window.location.origin +
                                                    "/img/phase2/bitcoin.png"
                                            }
                                            alt="Auth Otp"
                                        />
                                        <div className="token-card-avater-label">{wallet.data?.user_wallet?.currency_code}</div>
                                    </div>
                                    <div className="token-card-info">
                                        <div className="token-card-info-value">
                                            {wallet.data?.user_wallet?.remaining}
                                        </div>
                                        <div className="token-card-info-status">
                                            Remaining Token Balance
                                        </div>

                                    </div>
                                </div>
                            </>
                       
                        {isMobile &&
                          <div className="wallet-head-action">
                            {wallet.data?.user_wallet?.currency_type == "forex" ? (
                                <>
                                    {wallet.data?.user_wallet?.is_swap_allowed == 1 && (
                                        <Button
                                            className="action-btn primary"
                                            onClick={() => {
                                                setSingleWallet(wallet.data.user_wallet)
                                                setSwapModalNew("forex")
                                            }}
                                            disabled={
                                                wallet.data.user_wallet?.remaining_original ==
                                                0
                                            }
                                        > Swap
                                        </Button>)}
                                    {wallet.data?.user_wallet?.is_withdraw_allowed == 1 && (
                                        <Button
                                            className="action-btn secondary"
                                            onClick={() => {
                                                setSingleWallet(wallet.data.user_wallet)
                                                setModalShow(Number(wallet.data.user_wallet?.remaining_original))
                                            }}
                                            disabled={
                                                wallet.data.user_wallet?.remaining_original == 0 ||
                                                profile.data?.kyc_verified == 0
                                            }
                                        > Withdraw
                                        </Button>)}
                                    {wallet.data.user_wallet?.is_deposit_allowed == 1 &&
                                        <Button
                                            className="action-btn overlay-primary"
                                            disabled={profile.data.kyc_verified == 0}
                                            onClick={() => {
                                                setSingleWallet(wallet.data.user_wallet)
                                                setTokenType(
                                                    wallet.data.user_wallet?.currency_code
                                                );
                                                setDepositFiatModal(wallet.data.user_wallet);
                                            }}
                                        >
                                            Deposit
                                        </Button>}
                                </>) : (
                                <>
                                    {wallet.data?.user_wallet?.is_swap_allowed == 1 && (
                                        <Button
                                            onClick={() => {
                                                setSingleWallet(wallet.data?.user_wallet)
                                                setSwapModalNew("crypto")
                                            }}
                                            disabled={
                                                wallet.data?.user_wallet?.remaining_original ==
                                                0
                                            }
                                            className="action-btn primary"
                                        >
                                            Swap

                                            </Button>)}

                                        {wallet.data?.user_wallet?.is_withdraw_allowed == 1 && (
                                            <Button
                                                className="action-btn secondary"
                                                onClick={() => {
                                                    setSingleWallet(wallet.data.user_wallet)
                                                    setCryptoModalShow(
                                                        wallet.data?.user_wallet?.remaining_original
                                                    )
                                                }}
                                                disabled={
                                                    wallet.data?.user_wallet?.remaining_original == 0 ||
                                                    profile.data.kyc_verified == 0
                                                }
                                            >
                                                Withdraw
                                            </Button>)}
                                        {wallet.data?.user_wallet?.is_deposit_allowed == 1 &&
                                            <Button
                                                className="action-btn overlay-primary"
                                                disabled={profile.data.kyc_verified == 0}
                                                onClick={() => {
                                                    setSingleWallet(wallet.data?.user_wallet)
                                                    setTokenType(
                                                        wallet.data?.user_wallet?.currency_code
                                                    );
                                                    setDepositCryptoModal(wallet.data?.user_wallet);
                                                }}

                                            >
                                                Deposit
                                            </Button>}
                                    </>)}
                            </div>
                        }

                    </div>
                     ) :
                     <div className="wallet-detail-nodata-found"> 
                     <NoDataFound /></div>}
                    <div className="wallet-token-titles">
                        <h2>Transactions</h2>
                    </div>
                    <div className="wallet-token-tabs">
                        <Tab.Container id="wallet-token-tabs" defaultActiveKey="transactions">
                            <Nav variant="pills" className="wallet-token-nav" onSelect={(e) => { setEventKey(e) }} >
                                <Nav.Item>
                                    <Nav.Link eventKey="transactions">Transactions History</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="withdraw">Withdraw History</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="transactions">
                                    <div className="wallet-token-conent">
                                        <div className="wallet-token-field"></div>
                                        <div className="wallet-token-table">
                                            {Object.keys(wallet.data).length > 0 && (
                                                <TokenTransactions
                                                    eventKey={eventKey}
                                                    user_wallet_id={wallet.data.user_wallet.id}
                                                />)}
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="withdraw">
                                    <>
                                        <div className="wallet_table_head item_line_between   out_space_my_1_5">
                                            {(filter.status !== "" ||
                                                filter.search_key !== "" ||
                                                filter.from_date !== "" ||
                                                filter.to_date !== "" ||
                                                (Object.keys(withDrawals.data).length > 0 &&
                                                    withDrawals.data.total_user_withdrawals > 0 &&
                                                    filter.status == "" &&
                                                    filter.search_key == "" &&
                                                    filter.from_date == "" &&
                                                    filter.to_date == "")) && (
                                                    <>
                                                        <div className="table_head_titles">
                                                            <h3>Withdraw History</h3>
                                                            <h5 className="wallet_count item_center">
                                                                {withDrawals.data.total_user_withdrawals}
                                                            </h5>
                                                        </div>
                                                        <div className="table_head_action">
                                                            <Formik
                                                                initialValues={{
                                                                    search_key: searchParams.get("search_key")
                                                                        ? searchParams.get("search_key")
                                                                        : "",
                                                                    sort_by: searchParams.get("sort_by")
                                                                        ? searchParams.get("sort_by")
                                                                        : filter.sort_by,
                                                                    from_date: "",
                                                                    to_date: "",
                                                                }}
                                                                validationSchema={validationSchema}
                                                                onSubmit={(val) => {
                                                                    setFilter({
                                                                        ...filter,
                                                                        search_key: val.search_key,
                                                                        from_date: dateValues.from_date,
                                                                        to_date: dateValues.to_date,
                                                                    });

                                                                    navigate(
                                                                        searchParams.get("sort_by")
                                                                            ? `/wallet/${params.user_wallet_id}?search_key=${val.search_key
                                                                            }&sort_by=${searchParams.get("sort_by")}`
                                                                            : `/wallet/${params.user_wallet_id}?search_key=${val.search_key}`,
                                                                        { state: { value: value } }
                                                                    );
                                                                }}
                                                                innerRef={formRef}
                                                            >
                                                                {({ values, setFieldValue, resetForm, submitForm }) => (
                                                                    <FORM>
                                                                        <div className="wallet_sort_form ">
                                                                            <div className="wallet_sort_search">
                                                                                <InputGroup className="mb-0">
                                                                                    <Field
                                                                                        name="search_key"
                                                                                        onChange={(e) => {
                                                                                            {
                                                                                                e.target.value == "" && submitForm();
                                                                                                formRef.current.setFieldValue(
                                                                                                    "search_key",
                                                                                                    e.target.value
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                        placeholder={t("search")}
                                                                                        className="form-control"
                                                                                        onKeyPress={(event) => {
                                                                                            if (event.key === "Enter") {
                                                                                                event.preventDefault();
                                                                                                formRef.current.handleSubmit();
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                    <InputGroup.Text id="basic-addon2">
                                                                                        {searchParams.get("search_key") ? (
                                                                                            <Button
                                                                                                type="button"
                                                                                                className="action-btn link-btn"
                                                                                                onClick={() => {
                                                                                                    setFieldValue("search_key", "");
                                                                                                    setFilter({
                                                                                                        ...filter,
                                                                                                        search_key: "",
                                                                                                        from_date: dateValues.from_date,
                                                                                                        to_date: dateValues.to_date,
                                                                                                    });
                                                                                                    navigate(
                                                                                                        searchParams.get("sort_by")
                                                                                                            ? `/wallet/${params.user_wallet_id}?sort_by=${searchParams.get(
                                                                                                                "sort_by"
                                                                                                            )}`
                                                                                                            : `/wallet/${params.user_wallet_id}`
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#111" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-x"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
                                                                                            </Button>
                                                                                        ) : (
                                                                                            <Button
                                                                                                type="submit"
                                                                                                className="action-btn link-btn"
                                                                                            >
                                                                                                <svg
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width="20"
                                                                                                    height="20"
                                                                                                    fill="none"
                                                                                                    viewBox="0 0 24 24"
                                                                                                >
                                                                                                    <path
                                                                                                        fill="#171717"
                                                                                                        d="M11.5 21.75c-5.65 0-10.25-4.6-10.25-10.25S5.85 1.25 11.5 1.25s10.25 4.6 10.25 10.25-4.6 10.25-10.25 10.25zm0-19c-4.83 0-8.75 3.93-8.75 8.75s3.92 8.75 8.75 8.75 8.75-3.93 8.75-8.75-3.92-8.75-8.75-8.75zM22 22.75c-.19 0-.38-.07-.53-.22l-2-2a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l2 2c.29.29.29.77 0 1.06-.15.15-.34.22-.53.22z"
                                                                                                    ></path>
                                                                                                </svg>
                                                                                            </Button>
                                                                                        )}
                                                                                    </InputGroup.Text>
                                                                                </InputGroup>
                                                                            </div>
                                                                            <div className="wallet_sort_date">
                                                                                <Form.Group>
                                                                                    <InputGroup className="mb-0">
                                                                                        <DatePicker
                                                                                            format="DD/MM/YYYY"
                                                                                            value={value}
                                                                                            style={{ padding: "1em" }}
                                                                                            placeholder="Select Date Range"
                                                                                            onChange={(date) => {
                                                                                                handleDateChange(date);
                                                                                            }}
                                                                                            range
                                                                                            numberOfMonths={isMobile ? 1 : 2}
                                                                                            ref={calenderRefs}
                                                                                            maxDate={
                                                                                                new Date(new Date().setHours(23, 59, 59, 999))
                                                                                            }
                                                                                            editable={false}
                                                                                            onOpenPickNewDate={false}
                                                                                            render={!close && <InputIcon placeholder="Select Date Range" />}
                                                                                        >
                                                                                            <div
                                                                                                className="d-flex justify-content-end"
                                                                                                style={{ padding: "1em", gap: "0.5em" }}
                                                                                            >
                                                                                                <Button
                                                                                                    className="action-btn overlay"
                                                                                                    onClick={() => {
                                                                                                        calenderRefs.current.closeCalendar();
                                                                                                        setValue([null, null]);
                                                                                                    }}
                                                                                                >
                                                                                                    {t("close")}
                                                                                                </Button>
                                                                                                <Button
                                                                                                    className="action-btn overlay"
                                                                                                    onClick={() => {
                                                                                                        if (values.from_date || values.to_date) {
                                                                                                            setFilter({
                                                                                                                ...filter,
                                                                                                                skip: 12 * (page - 1),
                                                                                                                take: 12,
                                                                                                                from_date: dateValues.from_date,
                                                                                                                to_date: dateValues.to_date,
                                                                                                            });
                                                                                                            calenderRefs.current.closeCalendar();
                                                                                                            setClose(true);
                                                                                                        } else
                                                                                                            getErrorNotificationMessage(
                                                                                                                t("please_select_date")
                                                                                                            );
                                                                                                    }}
                                                                                                >
                                                                                                    {t("apply")}
                                                                                                </Button>
                                                                                            </div>
                                                                                        </DatePicker>
                                                                                        {close && (
                                                                                            <>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className=" date-cancel"
                                                                                                    onClick={() => {
                                                                                                        setDateValues({
                                                                                                            from_date: "",
                                                                                                            to_date: "",
                                                                                                        });
                                                                                                        setValue([null, null]);
                                                                                                        setClose(false);
                                                                                                        setFilter({
                                                                                                            ...filter,
                                                                                                            from_date: "",
                                                                                                            to_date: "",
                                                                                                        });
                                                                                                    }}
                                                                                                >
                                                                                                    <svg
                                                                                                        height="20"
                                                                                                        width="20"
                                                                                                        viewBox="0 0 20 20"
                                                                                                        aria-hidden="true"
                                                                                                        focusable="false"
                                                                                                        class="css-tj5bde-Svg"
                                                                                                    >
                                                                                                        <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                                                                                    </svg>
                                                                                                </button>
                                                                                            </>
                                                                                        )}
                                                                                    </InputGroup>
                                                                                </Form.Group>
                                                                            </div>
                                                                            <div className="wallet_sort_filter">
                                                                                <Select
                                                                                    className="fillert-drop"
                                                                                    options={options}
                                                                                    isClearable
                                                                                    styles={customStyles}
                                                                                    isSearchable={false}
                                                                                    value={filterStatus || null}
                                                                                    onChange={(selectedOption) => {
                                                                                        if (selectedOption) {
                                                                                            setFilter({
                                                                                                ...filter,
                                                                                                status: selectedOption.value,
                                                                                            });
                                                                                            setFilterStatus(selectedOption);
                                                                                            navigate(
                                                                                                filter.search_key
                                                                                                    ? `/wallet/${params.user_wallet_id}?search_key=${filter.search_key}&status=${selectedOption.value}`
                                                                                                    : `/wallet/${params.user_wallet_id}?status=${selectedOption.value}`
                                                                                            );
                                                                                        } else {
                                                                                            const newFilter = { ...filter };
                                                                                            delete newFilter.status;
                                                                                            setFilter(newFilter);

                                                                                            navigate(
                                                                                                filter.search_key
                                                                                                    ? `/wallet/${params.user_wallet_id}?search_key=${filter.search_key}`
                                                                                                    : `/wallet/${params.user_wallet_id}`
                                                                                            );
                                                                                            setFilterStatus(null);
                                                                                        }
                                                                                    }}
                                                                                    placeholder={
                                                                                        <div className="placeholder-flex">
                                                                                            Status
                                                                                        </div>
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div className="wallet_sort_export">
                                                                                <Button
                                                                                    type="submit"
                                                                                    className="action-btn gradient"
                                                                                    onClick={handleExport}
                                                                                    disabled={
                                                                                        userWithdrawalsExport.buttonDisable ||
                                                                                        withDrawals.loading ||
                                                                                        !Object.keys(withDrawals.data).length > 0 ||
                                                                                        !withDrawals.data.user_withdrawals.length > 0
                                                                                    }
                                                                                >
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="24"
                                                                                        height="24"
                                                                                        fill="none"
                                                                                        viewBox="0 0 24 24"
                                                                                    >
                                                                                        <path
                                                                                            fill="#171717"
                                                                                            d="M11.88 14.99c-.19 0-.38-.07-.53-.22l-2.56-2.56a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l2.03 2.03 2.03-2.03c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-2.56 2.56c-.15.15-.34.22-.53.22z"
                                                                                        ></path>
                                                                                        <path
                                                                                            fill="#171717"
                                                                                            d="M11.88 14.92c-.41 0-.75-.34-.75-.75V4c0-.41.34-.75.75-.75s.75.34.75.75v10.17c0 .41-.34.75-.75.75z"
                                                                                        ></path>
                                                                                        <path
                                                                                            fill="#171717"
                                                                                            d="M12 20.93c-5.15 0-8.75-3.6-8.75-8.75 0-.41.34-.75.75-.75s.75.34.75.75c0 4.27 2.98 7.25 7.25 7.25s7.25-2.98 7.25-7.25c0-.41.34-.75.75-.75s.75.34.75.75c0 5.15-3.6 8.75-8.75 8.75z"
                                                                                        ></path>
                                                                                    </svg>
                                                                                    <span>{t("buy_transaction.export")}</span>
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    </FORM>
                                                                )}
                                                            </Formik>
                                                        </div>
                                                    </>
                                                )}
                                        </div>

                                        <div className="wallet-token-conent">
                                            <div className="wallet-token-field"></div>
                                            <div className="wallet-token-table">
                                                <Table responsive>
                                                    {withDrawals.loading ?
                                                        [...Array(5)].map(() => (
                                                            <Skeleton height={60} />)) :
                                                        Object.keys(withDrawals.data).length > 0 &&
                                                            Object.keys(withDrawals.data.user_withdrawals).length > 0 ? (
                                                            <>
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            {props.currency_type == "crypto"
                                                                                ? t("wallet_table.transaction_hash")
                                                                                : t("wallet_table.transaction_id")}
                                                                        </th>
                                                                        <th>{t("wallet_table.date")}</th>
                                                                        <th>{t("wallet_table.amount")}</th>
                                                                        <th>
                                                                            {props.currency_type == "crypto"
                                                                                ? t("withdraw_table.wallet_address")
                                                                                : t("withdraw_table.billing_account")}
                                                                        </th>
                                                                        <th>{t("wallet_table.status")}</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {withDrawals.data.user_withdrawals.map((withdrawals, key) => (
                                                                        <tr>
                                                                            <td>
                                                                                <div className="td-copy-token">
                                                                                    <span>{withdrawals.unique_id}</span>
                                                                                    <CopyToClipboard
                                                                                        text={withdrawals?.unique_id}
                                                                                        onCopy={() =>
                                                                                            getSuccessNotificationMessage(
                                                                                                props.currency_type == "crypto"
                                                                                                    ? t(
                                                                                                        "withdraw_table.transaction_hash_copied_to_clipboard"
                                                                                                    )
                                                                                                    : t(
                                                                                                        "withdraw_table.transaction_id_copied_to_clipboard"
                                                                                                    )
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <Button
                                                                                            className="copy-token-btn"
                                                                                            data-tooltip-id="my-tooltip"
                                                                                            data-tooltip-content="Copy"
                                                                                            data-tooltip-place="top"
                                                                                        >
                                                                                            <svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                xmlSpace="preserve"
                                                                                                width="24"
                                                                                                height="24"
                                                                                                viewBox="0 0 24 24"
                                                                                            >
                                                                                                <path
                                                                                                    fill="#298bff"
                                                                                                    d="M18.375 6.25h-8.75A3.044 3.044 0 0 0 6.25 9.625v8.75a3.044 3.044 0 0 0 3.375 3.375h8.75a3.044 3.044 0 0 0 3.375-3.375v-8.75a3.044 3.044 0 0 0-3.375-3.375m1.875 12.125c0 1.332-.543 1.875-1.875 1.875h-8.75c-1.332 0-1.875-.543-1.875-1.875v-8.75c0-1.332.543-1.875 1.875-1.875h8.75c1.332 0 1.875.543 1.875 1.875zM3.75 5.62v8.76c0 1.2.483 1.493.642 1.59a.751.751 0 0 1-.784 1.28 3.1 3.1 0 0 1-1.358-2.87V5.62a3.068 3.068 0 0 1 3.37-3.37h8.76a3.1 3.1 0 0 1 2.87 1.358.75.75 0 1 1-1.279.784c-.1-.159-.393-.642-1.591-.642H5.62c-1.328 0-1.87.542-1.87 1.87"
                                                                                                    data-original="#000000"
                                                                                                ></path>
                                                                                            </svg>
                                                                                        </Button>
                                                                                    </CopyToClipboard>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="token-date">
                                                                                    {withdrawals.created_at}
                                                                                </div>
                                                                            </td>
                                                                            <td>

                                                                                {withdrawals.user_amount_formatted}
                                                                            </td>
                                                                            <td>
                                                                                {withdrawals?.wallet_address ||
                                                                                    withdrawals?.account_number ? (
                                                                                    <h5 className="table_code">
                                                                                        <span>
                                                                                            {props.currency_type == "crypto" &&
                                                                                                withdrawals?.wallet_address
                                                                                                ? withdrawals?.wallet_address.slice(0, 5) +
                                                                                                "......" +
                                                                                                withdrawals?.wallet_address.slice(-5)
                                                                                                : withdrawals?.account_number &&
                                                                                                withdrawals?.account_number.slice(0, 5) +
                                                                                                "......" +
                                                                                                withdrawals?.account_number.slice(-5)}
                                                                                        </span>
                                                                                        <CopyToClipboard
                                                                                            text={
                                                                                                props.currency_type == "crypto"
                                                                                                    ? withdrawals?.wallet_address
                                                                                                    : withdrawals?.account_number
                                                                                            }
                                                                                            onCopy={() =>
                                                                                                getSuccessNotificationMessage(
                                                                                                    props.currency_type == "crypto"
                                                                                                        ? "Wallet address copied"
                                                                                                        : "Account number copied"
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <Button className="action-btn copy-btn item_center">
                                                                                                <svg
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width="20"
                                                                                                    height="20"
                                                                                                    viewBox="0 0 24 24"
                                                                                                    fill="none"
                                                                                                    stroke="#298BFF"
                                                                                                    stroke-width="2"
                                                                                                    stroke-linecap="round"
                                                                                                    stroke-linejoin="round"
                                                                                                    class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                                                                                >
                                                                                                    <path
                                                                                                        stroke="none"
                                                                                                        d="M0 0h24v24H0z"
                                                                                                        fill="none"
                                                                                                    ></path>
                                                                                                    <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                                                                                    <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                                                                                                </svg>
                                                                                            </Button>
                                                                                        </CopyToClipboard>
                                                                                    </h5>
                                                                                ) : (
                                                                                    t("n_a")
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                <div
                                                                                    className={`token-status-badges ${(withdrawals.status == 3 || withdrawals.status == 5)
                                                                                        ? "fail"
                                                                                        : (withdrawals.status == 4 || withdrawals.status == 2) ? "pending" : "success"
                                                                                        }`}
                                                                                >
                                                                                    {withdrawals.status_formatted}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="item_center_column">
                                                                                    <Button
                                                                                        className="view-btn"
                                                                                        onClick={() => {
                                                                                            setWithdrawModal(true);
                                                                                            setTransactionId(withdrawals.id);
                                                                                        }}
                                                                                        disabled={withdrawalStatus.buttonDisable}
                                                                                    >
                                                                                        <span>View</span>
                                                                                    </Button>
                                                                                    {[0].includes(withdrawals.status) && (
                                                                                        <Button
                                                                                            className="view-btn"
                                                                                            onClick={() => handleRefresh(withdrawals)}
                                                                                            disabled={withdrawalStatus.buttonDisable}
                                                                                        >
                                                                                            {withdrawalStatus.buttonDisable &&
                                                                                                withdrawals?.id == withdrawalId
                                                                                                ? "Loading"
                                                                                                : "Refresh"}
                                                                                        </Button>
                                                                                    )}
                                                                                </div>
                                                                            </td>
                                                                        </tr>))}
                                                                </tbody> </>) : <NoTransactionData />}
                                                </Table>
                                            </div>
                                        </div>

                                        {/* {modalShow && (
                                            <CancelWithdrawalModal
                                                withdrawalId={withdrawalId}
                                                show={modalShow}
                                                onHide={() => setModalShow(false)}
                                            />
                                        )} */}
                                        {withdrawModalShow ? (
                                            <WithdrawTransactionView
                                                placement="end" name="end"
                                                show={withdrawModalShow}
                                                transactionId={transactionId}
                                                onHide={() => setWithdrawModal(false)}
                                            />
                                        ) : null}
                                    </>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
            {modalShow && (
                <WalletWithdrawModal
                    show={modalShow}
                    user_wallet_id={singleWallet.id}
                    currency={singleWallet.currency_code}
                    amount={singleWallet.remaining}
                    currencyCode={singleWallet.currency_code}
                    wallet={wallet.data}                       ////need to pass single wallet data ask backend
                    onHide={() => setModalShow(false)}
                    currency_type={singleWallet.currency_type}
                />
            )}
            {depositFiatModal && (
                <DepositFiatModal
                    show={depositFiatModal}
                    token={singleWallet?.currency_code}
                    tokenType={tokenType}
                    user_wallet_id={singleWallet?.id}
                    onHide={() => setDepositFiatModal(false)}
                />
            )}
            {depositCryptoModal && (
                <DepositCryptoModal
                    show={depositCryptoModal}
                    token={singleWallet?.currency_code}
                    tokenType={tokenType}
                    user_wallet_id={singleWallet?.id}
                    onHide={() => setDepositCryptoModal(false)}
                />
            )}
            {cryptoModalShow && (
                <WithdrawCryptoModal
                    show={cryptoModalShow}
                    user_wallet_id={singleWallet.id}
                    amount={singleWallet.remaining}
                    currencyCode={singleWallet.currency_code}
                    onHide={() => setCryptoModalShow(false)}
                    currency_type={singleWallet.currency_type}
                />
            )}
            {swapModalNew == "crypto" && (
                <SwapCryptoModal
                    show={swapModalNew}
                    onHide={() => setSwapModalNew(false)}
                    user_wallet_id={singleWallet.id}
                />
            )}
            {swapModalNew == "forex" && (
                <SwapFiatModal
                    show={swapModalNew}
                    onHide={() => setSwapModalNew(false)}
                    user_wallet_id={singleWallet.id}
                />
            )}
            <Tooltip id="my-tooltip" />
        </>
    );
};

export default WalletDetails;
