import React, { useEffect, useState, useRef } from "react";
import {
  Image,
  Form,
  InputGroup,
  Button,
  Row,
  Container,
  FormControl,
  Col,
  Tab,
  Nav,
  Navbar,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { loginStart, loginFailure } from "../../store/actions/UserAction";
import configuration from "react-global-configuration";
import { useTranslation } from "react-multi-lang";
import Authenticator from "./Authenticator";
import OtpVerification from "./OtpVerification";
import { useDispatch, useSelector } from "react-redux";
import RegisterIndex from "./RegisterIndex";
import { ButtonLoader } from "../helper/Loader";
import ForgotPasswordModal from "./ForgotPasswordModal";
import "../Auth/Auth.css"

const LoginIndex = (props) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const login = useSelector((state) => state.user.login);
  const emailVerify = useSelector((state) => state.user.emailVerify);
  const t = useTranslation("login");
  const [skipRender, setSkipRender] = useState(true);
  const [eventKey, setEventKey] = useState("");
  const [forgotmodalShow, setForgotModalShow] = useState(false);
  const [loginPasswordVisible, setLoginPasswordVisible] = useState(false);
  const [showOTPVerifyModal, setShowOTPVerifyModal] = useState(false);
  const [authenticatorModal, setAuthenticatorModal] = useState(false);

  const navigate = useNavigate();

  const loginSchema = Yup.object().shape({
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    password: Yup.string()
      .required(t("password.required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("password.invalid")),
  });

  const handleLogin = (values) => {
    setEventKey("login")
    props.dispatch(loginStart(values));
  };

  useEffect(() => {
    if (
      (localStorage.getItem("userId") && localStorage.getItem("token")) ||
      (sessionStorage.getItem("userId") && sessionStorage.getItem("token"))
    ) {
      navigate("/");
    }
  }, []);

  const closeOtpVerifyModal = () => {
    setShowOTPVerifyModal(false);
  };

  useEffect(() => {
    if (!skipRender && !login.loading && Object.keys(login.data).length > 0) {
      if (login.data.user.email_status == 0) {
        setShowOTPVerifyModal({
          email: formRef.current?.values.email,
          type: "email",
        });
      } else if (login.data.user["2fa_setup"] == 0) {
        setAuthenticatorModal({
          email: formRef.current?.values.email,
          password: formRef.current?.values.password,
        });
      } else if (login.data.user.is_2fa_enabled == 1) {
        setShowOTPVerifyModal({
          email: formRef.current?.values.email,
          password: formRef.current?.values.password,
          type: "2fa",
        });
      } else navigate("/");
    }
  }, [login]);

  useEffect(() => {
    if (
      !skipRender &&
      eventKey == "login" &&
      !emailVerify.loading &&
      Object.keys(emailVerify.data).length > 0
    ) {
      if (emailVerify.data.google2fa_secret) {
        setShowOTPVerifyModal(false);
        setAuthenticatorModal({
          email: formRef.current?.values.email,
          password: formRef.current?.values.password,
        });
        dispatch(loginFailure());
      } else {
        navigate("/");
      }
    }
    setSkipRender(false);
  }, [emailVerify]);

  return (
    <>
      <div className="auth-login-avater">
        <div className="auth-login-avater-card over_space_2">
          <div className="auth-login-avater-logo">
            <Link to="/">
              <Image
                className="logo"
                src={window.location.origin + "/img/HeraldEX-black.png"}
                type="image/png"
              />
            </Link>
          </div>
          <div className="auth-login-avater-info">
            <h2>{t("effortlessly_exchange_currency")}</h2>
            < p >{t("content")}.</p>
          </div>
        </div>
        <div className="auth-login-avater-frame over_space_2">
          <Image
            className="avater-frame-auth"
            src={window.location.origin + "/img/phase_1/auth/auth-avater.png"}
            type="image/png"
          />
        </div>
      </div>
      <div className="auth-login-form-frame">
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          innerRef={formRef}
          validationSchema={loginSchema}
          onSubmit={(values) => handleLogin(values)}
        >
          {({ touched, errors }) => (
            <FORM>
              <div className="form_card over_space_4">
                <div className="auth-login-avater-logo website-hide">
                  <Link to="/">
                    <Image
                      className="logo"
                      src={window.location.origin + "/img/HeraldEX-black.png"}
                      type="image/png"
                    />
                  </Link>
                </div>
                <div className="form_card_titles out_space_mx_1_5">
                  <h2>{t("heading")}!</h2>
                </div>
                <div className="form_card_form out_space_mx_1_5">
                  <Form.Group
                    controlId="formBasicEmail"
                    className="mb-3"
                  >
                    <Form.Label>
                      {t("email.label")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="email"
                      name="email"
                      placeholder={t("email.placeholder")}
                      className="form-control"
                      maxLength={128}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="email"
                      className="text-danger text-right"
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="formBasicEmail"
                  // className="mb-3"
                  >
                    <Form.Label>
                      {t("password.label")} <span>*</span>
                    </Form.Label>
                    <InputGroup>
                      <Field
                        type={
                          loginPasswordVisible
                            ? "text"
                            : "password"
                        }
                        name="password"
                        placeholder={t("password.placeholder")}
                        className="form-control"
                      />
                      <InputGroup.Text
                        id="basic-addon2"
                        onClick={() =>
                          setLoginPasswordVisible(
                            !loginPasswordVisible
                          )
                        }
                      >
                        {loginPasswordVisible ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#DDE1E6"
                              d="M12 16.33c-2.39 0-4.33-1.94-4.33-4.33S9.61 7.67 12 7.67s4.33 1.94 4.33 4.33-1.94 4.33-4.33 4.33zm0-7.16c-1.56 0-2.83 1.27-2.83 2.83s1.27 2.83 2.83 2.83 2.83-1.27 2.83-2.83S13.56 9.17 12 9.17z"
                            ></path>
                            <path
                              fill="#DDE1E6"
                              d="M12 21.02c-3.76 0-7.31-2.2-9.75-6.02-1.06-1.65-1.06-4.34 0-6 2.45-3.82 6-6.02 9.75-6.02s7.3 2.2 9.74 6.02c1.06 1.65 1.06 4.34 0 6-2.44 3.82-5.99 6.02-9.74 6.02zm0-16.54c-3.23 0-6.32 1.94-8.48 5.33-.75 1.17-.75 3.21 0 4.38 2.16 3.39 5.25 5.33 8.48 5.33 3.23 0 6.32-1.94 8.48-5.33.75-1.17.75-3.21 0-4.38-2.16-3.39-5.25-5.33-8.48-5.33z"
                            ></path>
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#DDE1E6"
                              d="M9.47 15.28c-.19 0-.38-.07-.53-.22A4.305 4.305 0 017.67 12c0-2.39 1.94-4.33 4.33-4.33 1.15 0 2.24.45 3.06 1.27a.75.75 0 010 1.06L10 15.06c-.15.15-.34.22-.53.22zM12 9.17a2.834 2.834 0 00-2.46 4.23l3.86-3.86c-.42-.24-.9-.37-1.4-.37z"
                            ></path>
                            <path
                              fill="#DDE1E6"
                              d="M5.6 18.51c-.17 0-.35-.06-.49-.18-1.07-.91-2.03-2.03-2.85-3.33-1.06-1.65-1.06-4.34 0-6C4.7 5.18 8.25 2.98 12 2.98c2.2 0 4.37.76 6.27 2.19a.75.75 0 01-.9 1.2c-1.64-1.24-3.5-1.89-5.37-1.89-3.23 0-6.32 1.94-8.48 5.33-.75 1.17-.75 3.21 0 4.38s1.61 2.18 2.56 3c.31.27.35.74.08 1.06-.14.17-.35.26-.56.26zM12 21.02c-1.33 0-2.63-.27-3.88-.8a.75.75 0 01-.4-.98c.16-.38.6-.56.98-.4 1.06.45 2.17.68 3.29.68 3.23 0 6.32-1.94 8.48-5.33.75-1.17.75-3.21 0-4.38-.31-.49-.65-.96-1.01-1.4a.76.76 0 01.11-1.06.75.75 0 011.06.11c.39.48.77 1 1.11 1.54 1.06 1.65 1.06 4.34 0 6-2.44 3.82-5.99 6.02-9.74 6.02z"
                            ></path>
                            <path
                              fill="#DDE1E6"
                              d="M12.69 16.27c-.35 0-.67-.25-.74-.61-.08-.41.19-.8.6-.87 1.1-.2 2.02-1.12 2.22-2.22.08-.41.47-.67.88-.6.41.08.68.47.6.88-.32 1.73-1.7 3.1-3.42 3.42-.05-.01-.09 0-.14 0zM2 22.75c-.19 0-.38-.07-.53-.22a.754.754 0 010-1.06L8.94 14c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-7.47 7.47c-.15.15-.34.22-.53.22zM14.53 10.22c-.19 0-.38-.07-.53-.22a.754.754 0 010-1.06l7.47-7.47c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06L15.06 10c-.15.15-.34.22-.53.22z"
                            ></path>
                          </svg>
                        )}
                      </InputGroup.Text>
                    </InputGroup>
                    <ErrorMessage
                      component={"div"}
                      name="password"
                      className="text-danger text-right"
                    />
                    <Form.Text className="d-flex align-items-center justify-content-end">

                      <Link
                        to="#"
                        className="auth_link"
                        onClick={() => setForgotModalShow(true)}
                      >
                        {t("forgot_password")}?
                      </Link>
                    </Form.Text>
                  </Form.Group>
                </div>
                <div className=" form_card_action out_space_mx_1_5">
                  <Button
                    className=" action-btn primary w-100"
                    type="submit"
                    disabled={login.buttonDisable}
                  >
                    {login.buttonDisable ? (
                      <ButtonLoader />
                    ) : (
                      t("login_btn.text")
                    )}
                  </Button>
                </div>
                <div className="form_card_others">
                  {/* <p className="out_space_mx_1_5">
                        By continuing you accept our <Link to="/page/terms--conditions" className="auth_link">
                          Terms of Services
                        </Link> and <Link to="/page/privacy-agreement" className="auth_link">
                          Privacy Policy
                        </Link>
                      </p> */}
                  <p> <span>{t("dont_have_account")} </span>
                    <Link to="/register" className="auth_link">{t("register")}</Link></p>
                </div>
              </div>
            </FORM>
          )}
        </Formik>
      </div>
      {showOTPVerifyModal ? (
        <OtpVerification
          showOtpVerify={showOTPVerifyModal}
          closeOtpVerifyModal={closeOtpVerifyModal}
          password={formRef.current?.values.password}
        />
      ) : null}
      {authenticatorModal ? (
        <Authenticator
          authenticatorModal={authenticatorModal}
          authentication={
            emailVerify.data?.google2fa_secret ? emailVerify : login
          }
          login={1}
          closeAuthenticatorModal={() => setAuthenticatorModal(false)}
        />
      ) : null}
      {forgotmodalShow && (
        <ForgotPasswordModal
          show={forgotmodalShow}
          onHide={() => setForgotModalShow(false)}
        />
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  register: state.user.register,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(LoginIndex);
