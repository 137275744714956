import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { updateBuyData, updateSwapData } from '../../store/actions/UserAction';
import LogoutModal from '../Modals/LogoutModal';

const SideBar = () => {
    const location = useLocation()
    const dispatch = useDispatch();
    const buyData = useSelector((state) => state.user.buyData);
    const swapData = useSelector((state) => state.user.swapData);
    const setBuyData = (data) => dispatch(updateBuyData(data));
    const setSwapData = (data) => dispatch(updateSwapData(data));
    const [logoutShow, setLogoutShow] = useState(false);

    const handleLogout = () => {
        setLogoutShow(true);
        setBuyData({
            ...buyData,
            step: 1,
        });
        setSwapData({
            ...swapData,
            step: 1,
        });
    };

    return (
        <div className="sidebar-wrapped">
            <ul className="list-unstyled">
                <li className={`nav-item ${(location.pathname === "/wallet-currency-list" || location.pathname.split("/")[1] == "wallet") ? "active" : ""}`}>
                    <Link to="/wallet-currency-list" className="nav-items">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Layer_1"
                            width="24"
                            height="24"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                            fill="#878E96"
                        >
                            <path d="M21 6H5a3.01 3.01 0 0 1-2.235-.999A3 3 0 0 1 5 4h18a1 1 0 1 0 0-2H5a5 5 0 0 0-5 5v10a5 5 0 0 0 5 5h16a3 3 0 0 0 3-3V9a3 3 0 0 0-3-3m1 13c0 .551-.448 1-1 1H5c-1.654 0-3-1.346-3-3V6.998A5 5 0 0 0 5 8h16c.552 0 1 .449 1 1zm-2-5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"></path>
                        </svg>
                        <span>
                            Wallet
                        </span>
                    </Link>
                </li>
                <li className={`nav-item ${["/recipient-list", "/create-personal-recipient"].includes(location.pathname) ? "active" : ""}`}>
                    <Link to="/recipient-list" className="nav-items">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Layer_1"
                            width="24"
                            height="24"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                            fill="#878E96"
                        >
                            <path d="M0 22h24v2H0zM24 7.909V10h-3v8h2v2H1v-2h2v-8H0V7.909a3 3 0 0 1 1.563-2.634l9-4.909a3 3 0 0 1 2.874 0l9 4.909A3 3 0 0 1 24 7.909M5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zm3-2.091a1 1 0 0 0-.521-.878l-9-4.909a1 1 0 0 0-.958 0l-9 4.909A1 1 0 0 0 2 7.909V8h20z"></path>
                        </svg>
                        <span>
                            Bank Accounts
                        </span>
                    </Link>
                </li>
                <li className={`nav-item ${location.pathname === "/transactions" ? "active" : ""}`}>
                    <Link to="/transactions" className="nav-items">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Outline"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="#878E96"
                        >
                            <path d="m23.421 16.583-3.291-3.291a1 1 0 1 0-1.413 1.414L21.007 17a9.33 9.33 0 0 1-6.686-2.8.98.98 0 0 0-1.408.08l-.013.02a1 1 0 0 0 .075 1.382A11.18 11.18 0 0 0 21.01 19l-2.293 2.293A1 1 0 1 0 20.13 22.7l3.291-3.291a2 2 0 0 0 0-2.826"></path>
                            <path d="m21.007 7-2.29 2.29a1 1 0 0 0-.054.082.992.992 0 0 0 1.467 1.332L21.836 9l1.586-1.585a2 2 0 0 0 .457-2.1 2 2 0 0 0-.458-.728L20.13 1.3a1 1 0 1 0-1.413 1.413l2.293 2.292c-4.933.012-7.637 2.674-10.089 5.474C8.669 7.937 6 5.4 1.487 5.046L1.006 5a1 1 0 0 0-1 1 1.02 1.02 0 0 0 1 1c.072 0 .287.033.287.033C5.189 7.328 7.425 9.522 9.6 12c-2.162 2.466-4.383 4.7-8.247 4.96l-.4.021a.994.994 0 1 0 .124 1.985c.156-.007.41-.013.535-.023 5.02-.387 7.743-3.6 10.171-6.409C14.235 9.7 16.551 7.018 21.007 7"></path>
                        </svg>
                        <span>
                            Transactions
                        </span>
                    </Link>
                </li>
                <li className={`nav-item ${location.pathname === "/profile" ? "active" : ""}`}>
                    <Link to="/profile" className="nav-items">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Outline"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="#878E96"
                        >
                            <path d="M12 12a6 6 0 1 0-6-6 6.006 6.006 0 0 0 6 6m0-10a4 4 0 1 1-4 4 4 4 0 0 1 4-4M12 14a9.01 9.01 0 0 0-9 9 1 1 0 0 0 2 0 7 7 0 0 1 14 0 1 1 0 0 0 2 0 9.01 9.01 0 0 0-9-9"></path>
                        </svg>
                        <span>
                            Profile
                        </span>
                    </Link>
                </li>
                <li className={`nav-item`}>
                    <Link to="#" onClick={handleLogout} className="nav-items">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="#878E96"
                        >
                            <g id="_01_align_center" data-name="01 align center">
                                <path d="M2 21V3a1 1 0 0 1 1-1h5V0H3a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h5v-2H3a1 1 0 0 1-1-1"></path>
                                <path d="m23.123 9.879-4.586-4.586-1.414 1.414 4.264 4.264L5 11v2l16.443-.029-4.322 4.322 1.414 1.414 4.586-4.586a3 3 0 0 0 .002-4.242"></path>
                            </g>
                        </svg>
                        <span>
                            Logout
                        </span>
                    </Link>
                </li>
            </ul>
            {logoutShow ? (
                <LogoutModal show={logoutShow} onHide={() => setLogoutShow(false)} />
            ) : null}
        </div>

    )
}

export default SideBar;