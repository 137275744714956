import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import SwapField from "./SwapField";
import { useTranslation } from "react-multi-lang";
import CustomLazyLoad from "../helper/CustomLazyLoad";


const HomeBanner = () => {

  const t = useTranslation("home_banner")

  return (
    <>
      <div className="home-banner-sec">
        <Container fluid>
          <Row className="align-items-center vertical-center-1 ">
            <Col xl={6} lg={12} md={12} className="order-change">
              <div className="banner-new-content">
                <h5>{t("revolutionizing_your_crypto_journey")}</h5>
                <h1>
                  {t("empower_your_digital")}
                  {/* <span> {t("assets_with")} {apiConstants.site_name} </span> */}
                </h1>
                {/* <p>
                   {t("where_advanced_technology_para")}
                  </p> */}
                {/* <div className="home-banner-btn-box">
                    <div className="banner-new-btn-sec">
                      <Link to="/buy" className="new-default-secondary-btn">
                      
                        Buy Crypto
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="#171717"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="1.5"
                            d="M14.43 5.93L20.5 12l-6.07 6.07M11.01 12h9.32M3.5 12h3.47"
                          ></path>
                        </svg>
                      </Link>
                    </div>
                    <div className="banner-new-btn-sec">
                      <Link to="/sell" className="new-default-secondary-btn" >
                
                        Sell Crypto
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="#171717"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="1.5"
                            d="M14.43 5.93L20.5 12l-6.07 6.07M11.01 12h9.32M3.5 12h3.47"
                          ></path>
                        </svg>
                      </Link>
                    </div>
                    <div className="banner-new-btn-sec">
                      <Link to="/swap" className="new-default-secondary-btn" >
                
                        Exchange Currencie
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="#171717"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="1.5"
                            d="M14.43 5.93L20.5 12l-6.07 6.07M11.01 12h9.32M3.5 12h3.47"
                          ></path>
                        </svg>
                      </Link>
                    </div>
                    <div className="banner-new-btn-sec">
                      <Link to="/trading" className="new-default-secondary-btn" >
                       
                        Trade Crypto
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="#171717"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="1.5"
                            d="M14.43 5.93L20.5 12l-6.07 6.07M11.01 12h9.32M3.5 12h3.47"
                          ></path>
                        </svg>
                      </Link>
                    </div>
                  </div> */}
                <div className="banner-batch-frame">
                  <div className="banner-crypto-link">
                    <div className="banner-crypto-link-info">
                      <h3>{t("buy_crypto")}</h3>
                      <p> {t("real_money_in_ew_easy_steps")}</p>
                    </div>
                    <div className="banner-crypto-link-action">
                      <Link className="crypto-link" to="/buy">  {t("buy")}  </Link>
                      <CustomLazyLoad
                        className="banner-crypto-icons"
                        src={window.location.origin + "/img/phase_1/icons/buy-crypto.svg"}
                        alt="buy crypto"
                      />
                    </div>
                  </div>
                  <div className="banner-crypto-link">
                    <div className="banner-crypto-link-info">
                      <h3>{t("sell_crypto")}</h3>
                      <p>{t("cash_anytime")}</p>
                    </div>
                    <div className="banner-crypto-link-action">
                      <Link className="crypto-link" to="/sell">  {t("sell")}  </Link>
                      <CustomLazyLoad
                        className="banner-crypto-icons"
                        src={window.location.origin + "/img/phase_1/icons/sell-crypto.svg"}
                        alt="buy crypto"
                      />
                    </div>
                  </div>
                  <div className="banner-crypto-link">
                    <div className="banner-crypto-link-info">
                      <h3>{t("exchange_crypto")}</h3>
                      < p >
                        {t("instantly_no_hassle")}</p>
                    </div>
                    <div className="banner-crypto-link-action">
                      <Link className="crypto-link " to="/exchange">{t("exchange")}</Link>
                      <CustomLazyLoad
                        className="banner-crypto-icons"
                        src={window.location.origin + "/img/phase_1/icons/swap.svg"}
                        alt="buy crypto"
                      />
                    </div>
                  </div>
                  <div className="banner-crypto-link">
                    <div className="banner-crypto-link-info">
                      <h3>{t("trade")}</h3>
                      <p>{t("real_time_data_and_advanced_tools")}</p>
                    </div>
                    <div className="banner-crypto-link-action">
                      <Link className="crypto-link" to="/trading">{t("trade")}</Link>
                      <CustomLazyLoad
                        className="banner-crypto-icons"
                        src={window.location.origin + "/img/phase_1/icons/trade.svg"}
                        alt="buy crypto"
                      />
                    </div>

                  </div>
                </div>
              </div>
            </Col>
            <Col xl={6} lg={12} md={12}>
              <SwapField />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HomeBanner;
